/* tslint:disable */
/* eslint-disable */
/**
 * ReportPro API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Drilling } from './Drilling';
import {
    DrillingFromJSON,
    DrillingFromJSONTyped,
    DrillingToJSON,
} from './Drilling';
import type { SPO } from './SPO';
import {
    SPOFromJSON,
    SPOFromJSONTyped,
    SPOToJSON,
} from './SPO';
import type { ReverseElaboration } from './ReverseElaboration';
import {
    ReverseElaborationFromJSON,
    ReverseElaborationFromJSONTyped,
    ReverseElaborationToJSON,
} from './ReverseElaboration';
import type { FrictionCoefficient } from './FrictionCoefficient';
import {
    FrictionCoefficientFromJSON,
    FrictionCoefficientFromJSONTyped,
    FrictionCoefficientToJSON,
} from './FrictionCoefficient';
import type { Liquor } from './Liquor';
import {
    LiquorFromJSON,
    LiquorFromJSONTyped,
    LiquorToJSON,
} from './Liquor';

/**
 * 
 * @export
 * @interface GraphMomentsAndWeightsRequest
 */
export interface GraphMomentsAndWeightsRequest {
    /**
     * 
     * @type {string}
     * @memberof GraphMomentsAndWeightsRequest
     */
    constructionType: GraphMomentsAndWeightsRequestConstructionTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof GraphMomentsAndWeightsRequest
     */
    trajectoryGroupId: number;
    /**
     * 
     * @type {number}
     * @memberof GraphMomentsAndWeightsRequest
     */
    knbkGroupId: number;
    /**
     * 
     * @type {number}
     * @memberof GraphMomentsAndWeightsRequest
     */
    descentStatementId?: number;
    /**
     * 
     * @type {boolean}
     * @memberof GraphMomentsAndWeightsRequest
     */
    isDrilling: boolean;
    /**
     * 
     * @type {number}
     * @memberof GraphMomentsAndWeightsRequest
     */
    depth: number;
    /**
     * 
     * @type {Liquor}
     * @memberof GraphMomentsAndWeightsRequest
     */
    liquor: Liquor;
    /**
     * 
     * @type {Array<FrictionCoefficient>}
     * @memberof GraphMomentsAndWeightsRequest
     */
    frictionCoefficients: Array<FrictionCoefficient>;
    /**
     * 
     * @type {Drilling}
     * @memberof GraphMomentsAndWeightsRequest
     */
    drilling: Drilling;
    /**
     * 
     * @type {ReverseElaboration}
     * @memberof GraphMomentsAndWeightsRequest
     */
    reverseElaboration: ReverseElaboration;
    /**
     * 
     * @type {SPO}
     * @memberof GraphMomentsAndWeightsRequest
     */
    spo: SPO;
    /**
     * 
     * @type {number}
     * @memberof GraphMomentsAndWeightsRequest
     */
    blockWeight: number;
}


/**
 * @export
 */
export const GraphMomentsAndWeightsRequestConstructionTypeEnum = {
    Plan: 'PLAN',
    Fact: 'FACT'
} as const;
export type GraphMomentsAndWeightsRequestConstructionTypeEnum = typeof GraphMomentsAndWeightsRequestConstructionTypeEnum[keyof typeof GraphMomentsAndWeightsRequestConstructionTypeEnum];


/**
 * Check if a given object implements the GraphMomentsAndWeightsRequest interface.
 */
export function instanceOfGraphMomentsAndWeightsRequest(value: object): value is GraphMomentsAndWeightsRequest {
    if (!('constructionType' in value) || value['constructionType'] === undefined) return false;
    if (!('trajectoryGroupId' in value) || value['trajectoryGroupId'] === undefined) return false;
    if (!('knbkGroupId' in value) || value['knbkGroupId'] === undefined) return false;
    if (!('isDrilling' in value) || value['isDrilling'] === undefined) return false;
    if (!('depth' in value) || value['depth'] === undefined) return false;
    if (!('liquor' in value) || value['liquor'] === undefined) return false;
    if (!('frictionCoefficients' in value) || value['frictionCoefficients'] === undefined) return false;
    if (!('drilling' in value) || value['drilling'] === undefined) return false;
    if (!('reverseElaboration' in value) || value['reverseElaboration'] === undefined) return false;
    if (!('spo' in value) || value['spo'] === undefined) return false;
    if (!('blockWeight' in value) || value['blockWeight'] === undefined) return false;
    return true;
}

export function GraphMomentsAndWeightsRequestFromJSON(json: any): GraphMomentsAndWeightsRequest {
    return GraphMomentsAndWeightsRequestFromJSONTyped(json, false);
}

export function GraphMomentsAndWeightsRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): GraphMomentsAndWeightsRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'constructionType': json['constructionType'],
        'trajectoryGroupId': json['trajectoryGroupId'],
        'knbkGroupId': json['knbkGroupId'],
        'descentStatementId': json['descentStatementId'] == null ? undefined : json['descentStatementId'],
        'isDrilling': json['isDrilling'],
        'depth': json['depth'],
        'liquor': LiquorFromJSON(json['liquor']),
        'frictionCoefficients': ((json['frictionCoefficients'] as Array<any>).map(FrictionCoefficientFromJSON)),
        'drilling': DrillingFromJSON(json['drilling']),
        'reverseElaboration': ReverseElaborationFromJSON(json['reverseElaboration']),
        'spo': SPOFromJSON(json['spo']),
        'blockWeight': json['blockWeight'],
    };
}

export function GraphMomentsAndWeightsRequestToJSON(value?: GraphMomentsAndWeightsRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'constructionType': value['constructionType'],
        'trajectoryGroupId': value['trajectoryGroupId'],
        'knbkGroupId': value['knbkGroupId'],
        'descentStatementId': value['descentStatementId'],
        'isDrilling': value['isDrilling'],
        'depth': value['depth'],
        'liquor': LiquorToJSON(value['liquor']),
        'frictionCoefficients': ((value['frictionCoefficients'] as Array<any>).map(FrictionCoefficientToJSON)),
        'drilling': DrillingToJSON(value['drilling']),
        'reverseElaboration': ReverseElaborationToJSON(value['reverseElaboration']),
        'spo': SPOToJSON(value['spo']),
        'blockWeight': value['blockWeight'],
    };
}

