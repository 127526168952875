/* tslint:disable */
/* eslint-disable */
/**
 * ReportPro API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import { AntiCrashRotationDrmOperationIntervalFromJSONTyped } from './AntiCrashRotationDrmOperationInterval';
import { LoadCapacityDrmOperationIntervalFromJSONTyped } from './LoadCapacityDrmOperationInterval';
import { PositioningOffTheBottomDrmOperationIntervalFromJSONTyped } from './PositioningOffTheBottomDrmOperationInterval';
import { ReamingRotorDrmOperationIntervalFromJSONTyped } from './ReamingRotorDrmOperationInterval';
import { ReamingSlideDrmOperationIntervalFromJSONTyped } from './ReamingSlideDrmOperationInterval';
import { RecordingStaticMeasurementDrmOperationIntervalFromJSONTyped } from './RecordingStaticMeasurementDrmOperationInterval';
import { RestrictionDrmOperationIntervalFromJSONTyped } from './RestrictionDrmOperationInterval';
import { RotorDrillingDrmOperationIntervalFromJSONTyped } from './RotorDrillingDrmOperationInterval';
import { SlideDrillingDrmOperationIntervalFromJSONTyped } from './SlideDrillingDrmOperationInterval';
import { SpoDrmOperationIntervalFromJSONTyped } from './SpoDrmOperationInterval';
import { StaticMeasurementOutputDrmOperationIntervalFromJSONTyped } from './StaticMeasurementOutputDrmOperationInterval';
import { SwitchingOffThePumpDrmOperationIntervalFromJSONTyped } from './SwitchingOffThePumpDrmOperationInterval';
import { SwitchingToTheModeDrmOperationIntervalFromJSONTyped } from './SwitchingToTheModeDrmOperationInterval';
import { TfOrientationDrmOperationIntervalFromJSONTyped } from './TfOrientationDrmOperationInterval';
import { WorkingOutDownDrmOperationIntervalFromJSONTyped } from './WorkingOutDownDrmOperationInterval';
import { WorkingOutUpDrmOperationIntervalFromJSONTyped } from './WorkingOutUpDrmOperationInterval';
/**
 * 
 * @export
 * @interface DrmOperationInterval
 */
export interface DrmOperationInterval {
    /**
     * 
     * @type {string}
     * @memberof DrmOperationInterval
     */
    createdBy?: string;
    /**
     * 
     * @type {Date}
     * @memberof DrmOperationInterval
     */
    createdDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof DrmOperationInterval
     */
    lastModifiedBy?: string;
    /**
     * 
     * @type {Date}
     * @memberof DrmOperationInterval
     */
    lastModifiedDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof DrmOperationInterval
     */
    id?: string;
    /**
     * 
     * @type {number}
     * @memberof DrmOperationInterval
     */
    depthIntervalStart: number;
    /**
     * 
     * @type {number}
     * @memberof DrmOperationInterval
     */
    depthIntervalEnd: number;
    /**
     * 
     * @type {string}
     * @memberof DrmOperationInterval
     */
    type?: string;
}

/**
 * Check if a given object implements the DrmOperationInterval interface.
 */
export function instanceOfDrmOperationInterval(value: object): value is DrmOperationInterval {
    if (!('depthIntervalStart' in value) || value['depthIntervalStart'] === undefined) return false;
    if (!('depthIntervalEnd' in value) || value['depthIntervalEnd'] === undefined) return false;
    return true;
}

export function DrmOperationIntervalFromJSON(json: any): DrmOperationInterval {
    return DrmOperationIntervalFromJSONTyped(json, false);
}

export function DrmOperationIntervalFromJSONTyped(json: any, ignoreDiscriminator: boolean): DrmOperationInterval {
    if (json == null) {
        return json;
    }
    if (!ignoreDiscriminator) {
        if (json['type'] === 'AntiCrashRotationDrmOperationInterval') {
            return AntiCrashRotationDrmOperationIntervalFromJSONTyped(json, true);
        }
        if (json['type'] === 'LoadCapacityDrmOperationInterval') {
            return LoadCapacityDrmOperationIntervalFromJSONTyped(json, true);
        }
        if (json['type'] === 'PositioningOffTheBottomDrmOperationInterval') {
            return PositioningOffTheBottomDrmOperationIntervalFromJSONTyped(json, true);
        }
        if (json['type'] === 'ReamingRotorDrmOperationInterval') {
            return ReamingRotorDrmOperationIntervalFromJSONTyped(json, true);
        }
        if (json['type'] === 'ReamingSlideDrmOperationInterval') {
            return ReamingSlideDrmOperationIntervalFromJSONTyped(json, true);
        }
        if (json['type'] === 'RecordingStaticMeasurementDrmOperationInterval') {
            return RecordingStaticMeasurementDrmOperationIntervalFromJSONTyped(json, true);
        }
        if (json['type'] === 'RestrictionDrmOperationInterval') {
            return RestrictionDrmOperationIntervalFromJSONTyped(json, true);
        }
        if (json['type'] === 'RotorDrillingDrmOperationInterval') {
            return RotorDrillingDrmOperationIntervalFromJSONTyped(json, true);
        }
        if (json['type'] === 'SlideDrillingDrmOperationInterval') {
            return SlideDrillingDrmOperationIntervalFromJSONTyped(json, true);
        }
        if (json['type'] === 'SpoDrmOperationInterval') {
            return SpoDrmOperationIntervalFromJSONTyped(json, true);
        }
        if (json['type'] === 'StaticMeasurementOutputDrmOperationInterval') {
            return StaticMeasurementOutputDrmOperationIntervalFromJSONTyped(json, true);
        }
        if (json['type'] === 'SwitchingOffThePumpDrmOperationInterval') {
            return SwitchingOffThePumpDrmOperationIntervalFromJSONTyped(json, true);
        }
        if (json['type'] === 'SwitchingToTheModeDrmOperationInterval') {
            return SwitchingToTheModeDrmOperationIntervalFromJSONTyped(json, true);
        }
        if (json['type'] === 'TfOrientationDrmOperationInterval') {
            return TfOrientationDrmOperationIntervalFromJSONTyped(json, true);
        }
        if (json['type'] === 'WorkingOutDownDrmOperationInterval') {
            return WorkingOutDownDrmOperationIntervalFromJSONTyped(json, true);
        }
        if (json['type'] === 'WorkingOutUpDrmOperationInterval') {
            return WorkingOutUpDrmOperationIntervalFromJSONTyped(json, true);
        }
    }
    return {
        
        'createdBy': json['createdBy'] == null ? undefined : json['createdBy'],
        'createdDate': json['createdDate'] == null ? undefined : (new Date(json['createdDate'])),
        'lastModifiedBy': json['lastModifiedBy'] == null ? undefined : json['lastModifiedBy'],
        'lastModifiedDate': json['lastModifiedDate'] == null ? undefined : (new Date(json['lastModifiedDate'])),
        'id': json['id'] == null ? undefined : json['id'],
        'depthIntervalStart': json['depthIntervalStart'],
        'depthIntervalEnd': json['depthIntervalEnd'],
        'type': json['type'] == null ? undefined : json['type'],
    };
}

export function DrmOperationIntervalToJSON(value?: DrmOperationInterval | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'createdBy': value['createdBy'],
        'createdDate': value['createdDate'] == null ? undefined : ((value['createdDate']).toISOString()),
        'lastModifiedBy': value['lastModifiedBy'],
        'lastModifiedDate': value['lastModifiedDate'] == null ? undefined : ((value['lastModifiedDate']).toISOString()),
        'id': value['id'],
        'depthIntervalStart': value['depthIntervalStart'],
        'depthIntervalEnd': value['depthIntervalEnd'],
        'type': value['type'],
    };
}

