/* tslint:disable */
/* eslint-disable */
/**
 * ReportPro API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { SchedulerEventContent } from './SchedulerEventContent';
import {
    SchedulerEventContentFromJSON,
    SchedulerEventContentFromJSONTyped,
    SchedulerEventContentToJSON,
} from './SchedulerEventContent';

/**
 * 
 * @export
 * @interface PadDrillingRigDisassemblingEventContent
 */
export interface PadDrillingRigDisassemblingEventContent extends SchedulerEventContent {
    /**
     * 
     * @type {string}
     * @memberof PadDrillingRigDisassemblingEventContent
     */
    title: string;
    /**
     * 
     * @type {Date}
     * @memberof PadDrillingRigDisassemblingEventContent
     */
    dateFrom: Date;
    /**
     * 
     * @type {Date}
     * @memberof PadDrillingRigDisassemblingEventContent
     */
    dateTo: Date;
}

/**
 * Check if a given object implements the PadDrillingRigDisassemblingEventContent interface.
 */
export function instanceOfPadDrillingRigDisassemblingEventContent(value: object): value is PadDrillingRigDisassemblingEventContent {
    if (!('title' in value) || value['title'] === undefined) return false;
    if (!('dateFrom' in value) || value['dateFrom'] === undefined) return false;
    if (!('dateTo' in value) || value['dateTo'] === undefined) return false;
    return true;
}

export function PadDrillingRigDisassemblingEventContentFromJSON(json: any): PadDrillingRigDisassemblingEventContent {
    return PadDrillingRigDisassemblingEventContentFromJSONTyped(json, false);
}

export function PadDrillingRigDisassemblingEventContentFromJSONTyped(json: any, ignoreDiscriminator: boolean): PadDrillingRigDisassemblingEventContent {
    if (json == null) {
        return json;
    }
    return {
        ...SchedulerEventContentFromJSONTyped(json, ignoreDiscriminator),
        'title': json['title'],
        'dateFrom': (new Date(json['dateFrom'])),
        'dateTo': (new Date(json['dateTo'])),
    };
}

export function PadDrillingRigDisassemblingEventContentToJSON(value?: PadDrillingRigDisassemblingEventContent | null): any {
    if (value == null) {
        return value;
    }
    return {
        ...SchedulerEventContentToJSON(value),
        'title': value['title'],
        'dateFrom': ((value['dateFrom']).toISOString()),
        'dateTo': ((value['dateTo']).toISOString()),
    };
}

