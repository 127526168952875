/* tslint:disable */
/* eslint-disable */
/**
 * ReportPro API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DrillingBrigadeEfficiencyReportRequest
 */
export interface DrillingBrigadeEfficiencyReportRequest {
    /**
     * 
     * @type {number}
     * @memberof DrillingBrigadeEfficiencyReportRequest
     */
    wellId: number;
    /**
     * 
     * @type {string}
     * @memberof DrillingBrigadeEfficiencyReportRequest
     */
    wellboreId: string;
    /**
     * 
     * @type {Date}
     * @memberof DrillingBrigadeEfficiencyReportRequest
     */
    dateStart: Date;
    /**
     * 
     * @type {Date}
     * @memberof DrillingBrigadeEfficiencyReportRequest
     */
    dateEnd?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof DrillingBrigadeEfficiencyReportRequest
     */
    isDetailedOn?: boolean;
}

/**
 * Check if a given object implements the DrillingBrigadeEfficiencyReportRequest interface.
 */
export function instanceOfDrillingBrigadeEfficiencyReportRequest(value: object): value is DrillingBrigadeEfficiencyReportRequest {
    if (!('wellId' in value) || value['wellId'] === undefined) return false;
    if (!('wellboreId' in value) || value['wellboreId'] === undefined) return false;
    if (!('dateStart' in value) || value['dateStart'] === undefined) return false;
    return true;
}

export function DrillingBrigadeEfficiencyReportRequestFromJSON(json: any): DrillingBrigadeEfficiencyReportRequest {
    return DrillingBrigadeEfficiencyReportRequestFromJSONTyped(json, false);
}

export function DrillingBrigadeEfficiencyReportRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): DrillingBrigadeEfficiencyReportRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'wellId': json['wellId'],
        'wellboreId': json['wellboreId'],
        'dateStart': (new Date(json['dateStart'])),
        'dateEnd': json['dateEnd'] == null ? undefined : (new Date(json['dateEnd'])),
        'isDetailedOn': json['isDetailedOn'] == null ? undefined : json['isDetailedOn'],
    };
}

export function DrillingBrigadeEfficiencyReportRequestToJSON(value?: DrillingBrigadeEfficiencyReportRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'wellId': value['wellId'],
        'wellboreId': value['wellboreId'],
        'dateStart': ((value['dateStart']).toISOString().substring(0,10)),
        'dateEnd': value['dateEnd'] == null ? undefined : ((value['dateEnd']).toISOString().substring(0,10)),
        'isDetailedOn': value['isDetailedOn'],
    };
}

