/* tslint:disable */
/* eslint-disable */
/**
 * ReportPro API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AutoDrillingFunctionView
 */
export interface AutoDrillingFunctionView {
    /**
     * 
     * @type {string}
     * @memberof AutoDrillingFunctionView
     */
    key?: AutoDrillingFunctionViewKeyEnum;
    /**
     * 
     * @type {string}
     * @memberof AutoDrillingFunctionView
     */
    value?: string;
}


/**
 * @export
 */
export const AutoDrillingFunctionViewKeyEnum = {
    AntiCrashRotation: 'ANTI_CRASH_ROTATION',
    UpdatingNoloadParameters: 'UPDATING_NOLOAD_PARAMETERS',
    AdaptiveDrilling: 'ADAPTIVE_DRILLING',
    Oscillation: 'OSCILLATION',
    AutoHoldTf: 'AUTO_HOLD_TF',
    StaticMeasurementOutput: 'STATIC_MEASUREMENT_OUTPUT',
    DrillTest: 'DRILL_TEST',
    ShockTest: 'SHOCK_TEST',
    Weighing: 'WEIGHING',
    Damper: 'DAMPER'
} as const;
export type AutoDrillingFunctionViewKeyEnum = typeof AutoDrillingFunctionViewKeyEnum[keyof typeof AutoDrillingFunctionViewKeyEnum];


/**
 * Check if a given object implements the AutoDrillingFunctionView interface.
 */
export function instanceOfAutoDrillingFunctionView(value: object): value is AutoDrillingFunctionView {
    return true;
}

export function AutoDrillingFunctionViewFromJSON(json: any): AutoDrillingFunctionView {
    return AutoDrillingFunctionViewFromJSONTyped(json, false);
}

export function AutoDrillingFunctionViewFromJSONTyped(json: any, ignoreDiscriminator: boolean): AutoDrillingFunctionView {
    if (json == null) {
        return json;
    }
    return {
        
        'key': json['key'] == null ? undefined : json['key'],
        'value': json['value'] == null ? undefined : json['value'],
    };
}

export function AutoDrillingFunctionViewToJSON(value?: AutoDrillingFunctionView | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'key': value['key'],
        'value': value['value'],
    };
}

