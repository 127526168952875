/* tslint:disable */
/* eslint-disable */
/**
 * ReportPro API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ReportGenerationInfo
 */
export interface ReportGenerationInfo {
    /**
     * 
     * @type {string}
     * @memberof ReportGenerationInfo
     */
    id?: string;
    /**
     * 
     * @type {Set<string>}
     * @memberof ReportGenerationInfo
     */
    warnings?: Set<string>;
}

/**
 * Check if a given object implements the ReportGenerationInfo interface.
 */
export function instanceOfReportGenerationInfo(value: object): value is ReportGenerationInfo {
    return true;
}

export function ReportGenerationInfoFromJSON(json: any): ReportGenerationInfo {
    return ReportGenerationInfoFromJSONTyped(json, false);
}

export function ReportGenerationInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReportGenerationInfo {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'warnings': json['warnings'] == null ? undefined : json['warnings'],
    };
}

export function ReportGenerationInfoToJSON(value?: ReportGenerationInfo | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'warnings': value['warnings'] == null ? undefined : Array.from(value['warnings'] as Set<any>),
    };
}

