/* tslint:disable */
/* eslint-disable */
/**
 * ReportPro API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { SchedulerItemContent } from './SchedulerItemContent';
import {
    SchedulerItemContentFromJSON,
    SchedulerItemContentFromJSONTyped,
    SchedulerItemContentToJSON,
} from './SchedulerItemContent';
import type { SchedulerWellService } from './SchedulerWellService';
import {
    SchedulerWellServiceFromJSON,
    SchedulerWellServiceFromJSONTyped,
    SchedulerWellServiceToJSON,
} from './SchedulerWellService';

/**
 * 
 * @export
 * @interface SchedulerWellContent
 */
export interface SchedulerWellContent extends SchedulerItemContent {
    /**
     * 
     * @type {number}
     * @memberof SchedulerWellContent
     */
    wellId: number;
    /**
     * 
     * @type {string}
     * @memberof SchedulerWellContent
     */
    contractor?: string;
    /**
     * 
     * @type {Date}
     * @memberof SchedulerWellContent
     */
    dateFrom?: Date;
    /**
     * 
     * @type {Date}
     * @memberof SchedulerWellContent
     */
    dateTo?: Date;
    /**
     * 
     * @type {Array<SchedulerWellService>}
     * @memberof SchedulerWellContent
     */
    services: Array<SchedulerWellService>;
}

/**
 * Check if a given object implements the SchedulerWellContent interface.
 */
export function instanceOfSchedulerWellContent(value: object): value is SchedulerWellContent {
    if (!('wellId' in value) || value['wellId'] === undefined) return false;
    if (!('services' in value) || value['services'] === undefined) return false;
    return true;
}

export function SchedulerWellContentFromJSON(json: any): SchedulerWellContent {
    return SchedulerWellContentFromJSONTyped(json, false);
}

export function SchedulerWellContentFromJSONTyped(json: any, ignoreDiscriminator: boolean): SchedulerWellContent {
    if (json == null) {
        return json;
    }
    return {
        ...SchedulerItemContentFromJSONTyped(json, ignoreDiscriminator),
        'wellId': json['wellId'],
        'contractor': json['contractor'] == null ? undefined : json['contractor'],
        'dateFrom': json['dateFrom'] == null ? undefined : (new Date(json['dateFrom'])),
        'dateTo': json['dateTo'] == null ? undefined : (new Date(json['dateTo'])),
        'services': ((json['services'] as Array<any>).map(SchedulerWellServiceFromJSON)),
    };
}

export function SchedulerWellContentToJSON(value?: SchedulerWellContent | null): any {
    if (value == null) {
        return value;
    }
    return {
        ...SchedulerItemContentToJSON(value),
        'wellId': value['wellId'],
        'contractor': value['contractor'],
        'dateFrom': value['dateFrom'] == null ? undefined : ((value['dateFrom']).toISOString().substring(0,10)),
        'dateTo': value['dateTo'] == null ? undefined : ((value['dateTo']).toISOString().substring(0,10)),
        'services': ((value['services'] as Array<any>).map(SchedulerWellServiceToJSON)),
    };
}

