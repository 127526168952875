/* tslint:disable */
/* eslint-disable */
/**
 * ReportPro API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  DrillingMachinesEfficiencyRequest,
  ErrorResponse,
  OperationDTO,
  OperationPeriod,
  OperationTimeDurationByShiftResponse,
  SettingsDTO,
  ValidationErrorResponse,
} from '../models/index';
import {
    DrillingMachinesEfficiencyRequestFromJSON,
    DrillingMachinesEfficiencyRequestToJSON,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    OperationDTOFromJSON,
    OperationDTOToJSON,
    OperationPeriodFromJSON,
    OperationPeriodToJSON,
    OperationTimeDurationByShiftResponseFromJSON,
    OperationTimeDurationByShiftResponseToJSON,
    SettingsDTOFromJSON,
    SettingsDTOToJSON,
    ValidationErrorResponseFromJSON,
    ValidationErrorResponseToJSON,
} from '../models/index';

export interface FindDrillingDataRequest {
    drillingMachinesEfficiencyRequest: DrillingMachinesEfficiencyRequest;
}

export interface FindTimeAllocationRequest {
    drillingMachinesEfficiencyRequest: DrillingMachinesEfficiencyRequest;
}

export interface SaveSettingsRequest {
    settingsDTO: SettingsDTO;
}

/**
 * 
 */
export class DrillingMachinesEfficiencyApi extends runtime.BaseAPI {

    /**
     */
    async findDrillingDataRaw(requestParameters: FindDrillingDataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<{ [key: string]: Array<OperationPeriod>; }>> {
        if (requestParameters['drillingMachinesEfficiencyRequest'] == null) {
            throw new runtime.RequiredError(
                'drillingMachinesEfficiencyRequest',
                'Required parameter "drillingMachinesEfficiencyRequest" was null or undefined when calling findDrillingData().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer Authentication", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/monitoring/efficiency/operation/duration`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DrillingMachinesEfficiencyRequestToJSON(requestParameters['drillingMachinesEfficiencyRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async findDrillingData(requestParameters: FindDrillingDataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<{ [key: string]: Array<OperationPeriod>; }> {
        const response = await this.findDrillingDataRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async findOperationNamesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<OperationDTO>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer Authentication", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/monitoring/operation`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OperationDTOFromJSON));
    }

    /**
     */
    async findOperationNames(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<OperationDTO>> {
        const response = await this.findOperationNamesRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async findSettingsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SettingsDTO>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer Authentication", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/monitoring/efficiency/settings`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SettingsDTOFromJSON(jsonValue));
    }

    /**
     */
    async findSettings(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SettingsDTO> {
        const response = await this.findSettingsRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async findTimeAllocationRaw(requestParameters: FindTimeAllocationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OperationTimeDurationByShiftResponse>> {
        if (requestParameters['drillingMachinesEfficiencyRequest'] == null) {
            throw new runtime.RequiredError(
                'drillingMachinesEfficiencyRequest',
                'Required parameter "drillingMachinesEfficiencyRequest" was null or undefined when calling findTimeAllocation().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer Authentication", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/monitoring/efficiency/time/duration`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DrillingMachinesEfficiencyRequestToJSON(requestParameters['drillingMachinesEfficiencyRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OperationTimeDurationByShiftResponseFromJSON(jsonValue));
    }

    /**
     */
    async findTimeAllocation(requestParameters: FindTimeAllocationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OperationTimeDurationByShiftResponse> {
        const response = await this.findTimeAllocationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async saveSettingsRaw(requestParameters: SaveSettingsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SettingsDTO>> {
        if (requestParameters['settingsDTO'] == null) {
            throw new runtime.RequiredError(
                'settingsDTO',
                'Required parameter "settingsDTO" was null or undefined when calling saveSettings().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer Authentication", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/monitoring/efficiency/settings`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SettingsDTOToJSON(requestParameters['settingsDTO']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SettingsDTOFromJSON(jsonValue));
    }

    /**
     */
    async saveSettings(requestParameters: SaveSettingsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SettingsDTO> {
        const response = await this.saveSettingsRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
