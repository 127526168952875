/* tslint:disable */
/* eslint-disable */
/**
 * ReportPro API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { DrmOperationInterval } from './DrmOperationInterval';
import {
    DrmOperationIntervalFromJSON,
    DrmOperationIntervalFromJSONTyped,
    DrmOperationIntervalToJSON,
} from './DrmOperationInterval';

/**
 * 
 * @export
 * @interface SpoDrmOperationInterval
 */
export interface SpoDrmOperationInterval extends DrmOperationInterval {
    /**
     * 
     * @type {number}
     * @memberof SpoDrmOperationInterval
     */
    weightWithCirculationUp: number;
    /**
     * 
     * @type {number}
     * @memberof SpoDrmOperationInterval
     */
    weightWithCirculationDown: number;
    /**
     * 
     * @type {number}
     * @memberof SpoDrmOperationInterval
     */
    weightWithoutCirculationAndRotationDownKt01: number;
    /**
     * 
     * @type {number}
     * @memberof SpoDrmOperationInterval
     */
    weightWithoutCirculationAndRotationDownKt02: number;
    /**
     * 
     * @type {number}
     * @memberof SpoDrmOperationInterval
     */
    weightWithoutCirculationAndRotationDownKt03: number;
    /**
     * 
     * @type {number}
     * @memberof SpoDrmOperationInterval
     */
    weightWithoutCirculationAndRotationDownKt04: number;
    /**
     * 
     * @type {number}
     * @memberof SpoDrmOperationInterval
     */
    weightWithoutCirculationAndRotationDownKt05: number;
    /**
     * 
     * @type {number}
     * @memberof SpoDrmOperationInterval
     */
    weightRotatingAboveBottomWithCirculation: number;
    /**
     * 
     * @type {number}
     * @memberof SpoDrmOperationInterval
     */
    weightRotatingAboveBottomWithoutCirculation: number;
    /**
     * 
     * @type {number}
     * @memberof SpoDrmOperationInterval
     */
    weightWithoutCirculationAndRotationUpKt01: number;
    /**
     * 
     * @type {number}
     * @memberof SpoDrmOperationInterval
     */
    weightWithoutCirculationAndRotationUpKt02: number;
    /**
     * 
     * @type {number}
     * @memberof SpoDrmOperationInterval
     */
    weightWithoutCirculationAndRotationUpKt03: number;
    /**
     * 
     * @type {number}
     * @memberof SpoDrmOperationInterval
     */
    weightWithoutCirculationAndRotationUpKt04: number;
    /**
     * 
     * @type {number}
     * @memberof SpoDrmOperationInterval
     */
    weightWithoutCirculationAndRotationUpKt05: number;
}

/**
 * Check if a given object implements the SpoDrmOperationInterval interface.
 */
export function instanceOfSpoDrmOperationInterval(value: object): value is SpoDrmOperationInterval {
    if (!('weightWithCirculationUp' in value) || value['weightWithCirculationUp'] === undefined) return false;
    if (!('weightWithCirculationDown' in value) || value['weightWithCirculationDown'] === undefined) return false;
    if (!('weightWithoutCirculationAndRotationDownKt01' in value) || value['weightWithoutCirculationAndRotationDownKt01'] === undefined) return false;
    if (!('weightWithoutCirculationAndRotationDownKt02' in value) || value['weightWithoutCirculationAndRotationDownKt02'] === undefined) return false;
    if (!('weightWithoutCirculationAndRotationDownKt03' in value) || value['weightWithoutCirculationAndRotationDownKt03'] === undefined) return false;
    if (!('weightWithoutCirculationAndRotationDownKt04' in value) || value['weightWithoutCirculationAndRotationDownKt04'] === undefined) return false;
    if (!('weightWithoutCirculationAndRotationDownKt05' in value) || value['weightWithoutCirculationAndRotationDownKt05'] === undefined) return false;
    if (!('weightRotatingAboveBottomWithCirculation' in value) || value['weightRotatingAboveBottomWithCirculation'] === undefined) return false;
    if (!('weightRotatingAboveBottomWithoutCirculation' in value) || value['weightRotatingAboveBottomWithoutCirculation'] === undefined) return false;
    if (!('weightWithoutCirculationAndRotationUpKt01' in value) || value['weightWithoutCirculationAndRotationUpKt01'] === undefined) return false;
    if (!('weightWithoutCirculationAndRotationUpKt02' in value) || value['weightWithoutCirculationAndRotationUpKt02'] === undefined) return false;
    if (!('weightWithoutCirculationAndRotationUpKt03' in value) || value['weightWithoutCirculationAndRotationUpKt03'] === undefined) return false;
    if (!('weightWithoutCirculationAndRotationUpKt04' in value) || value['weightWithoutCirculationAndRotationUpKt04'] === undefined) return false;
    if (!('weightWithoutCirculationAndRotationUpKt05' in value) || value['weightWithoutCirculationAndRotationUpKt05'] === undefined) return false;
    return true;
}

export function SpoDrmOperationIntervalFromJSON(json: any): SpoDrmOperationInterval {
    return SpoDrmOperationIntervalFromJSONTyped(json, false);
}

export function SpoDrmOperationIntervalFromJSONTyped(json: any, ignoreDiscriminator: boolean): SpoDrmOperationInterval {
    if (json == null) {
        return json;
    }
    return {
        ...DrmOperationIntervalFromJSONTyped(json, ignoreDiscriminator),
        'weightWithCirculationUp': json['weightWithCirculationUp'],
        'weightWithCirculationDown': json['weightWithCirculationDown'],
        'weightWithoutCirculationAndRotationDownKt01': json['weightWithoutCirculationAndRotationDownKt0_1'],
        'weightWithoutCirculationAndRotationDownKt02': json['weightWithoutCirculationAndRotationDownKt0_2'],
        'weightWithoutCirculationAndRotationDownKt03': json['weightWithoutCirculationAndRotationDownKt0_3'],
        'weightWithoutCirculationAndRotationDownKt04': json['weightWithoutCirculationAndRotationDownKt0_4'],
        'weightWithoutCirculationAndRotationDownKt05': json['weightWithoutCirculationAndRotationDownKt0_5'],
        'weightRotatingAboveBottomWithCirculation': json['weightRotatingAboveBottomWithCirculation'],
        'weightRotatingAboveBottomWithoutCirculation': json['weightRotatingAboveBottomWithoutCirculation'],
        'weightWithoutCirculationAndRotationUpKt01': json['weightWithoutCirculationAndRotationUpKt0_1'],
        'weightWithoutCirculationAndRotationUpKt02': json['weightWithoutCirculationAndRotationUpKt0_2'],
        'weightWithoutCirculationAndRotationUpKt03': json['weightWithoutCirculationAndRotationUpKt0_3'],
        'weightWithoutCirculationAndRotationUpKt04': json['weightWithoutCirculationAndRotationUpKt0_4'],
        'weightWithoutCirculationAndRotationUpKt05': json['weightWithoutCirculationAndRotationUpKt0_5'],
    };
}

export function SpoDrmOperationIntervalToJSON(value?: SpoDrmOperationInterval | null): any {
    if (value == null) {
        return value;
    }
    return {
        ...DrmOperationIntervalToJSON(value),
        'weightWithCirculationUp': value['weightWithCirculationUp'],
        'weightWithCirculationDown': value['weightWithCirculationDown'],
        'weightWithoutCirculationAndRotationDownKt0_1': value['weightWithoutCirculationAndRotationDownKt01'],
        'weightWithoutCirculationAndRotationDownKt0_2': value['weightWithoutCirculationAndRotationDownKt02'],
        'weightWithoutCirculationAndRotationDownKt0_3': value['weightWithoutCirculationAndRotationDownKt03'],
        'weightWithoutCirculationAndRotationDownKt0_4': value['weightWithoutCirculationAndRotationDownKt04'],
        'weightWithoutCirculationAndRotationDownKt0_5': value['weightWithoutCirculationAndRotationDownKt05'],
        'weightRotatingAboveBottomWithCirculation': value['weightRotatingAboveBottomWithCirculation'],
        'weightRotatingAboveBottomWithoutCirculation': value['weightRotatingAboveBottomWithoutCirculation'],
        'weightWithoutCirculationAndRotationUpKt0_1': value['weightWithoutCirculationAndRotationUpKt01'],
        'weightWithoutCirculationAndRotationUpKt0_2': value['weightWithoutCirculationAndRotationUpKt02'],
        'weightWithoutCirculationAndRotationUpKt0_3': value['weightWithoutCirculationAndRotationUpKt03'],
        'weightWithoutCirculationAndRotationUpKt0_4': value['weightWithoutCirculationAndRotationUpKt04'],
        'weightWithoutCirculationAndRotationUpKt0_5': value['weightWithoutCirculationAndRotationUpKt05'],
    };
}

