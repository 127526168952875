/* tslint:disable */
/* eslint-disable */
/**
 * ReportPro API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TemplateFormatEntity
 */
export interface TemplateFormatEntity {
    /**
     * 
     * @type {string}
     * @memberof TemplateFormatEntity
     */
    formatId?: string;
    /**
     * 
     * @type {string}
     * @memberof TemplateFormatEntity
     */
    formatName?: string;
}

/**
 * Check if a given object implements the TemplateFormatEntity interface.
 */
export function instanceOfTemplateFormatEntity(value: object): value is TemplateFormatEntity {
    return true;
}

export function TemplateFormatEntityFromJSON(json: any): TemplateFormatEntity {
    return TemplateFormatEntityFromJSONTyped(json, false);
}

export function TemplateFormatEntityFromJSONTyped(json: any, ignoreDiscriminator: boolean): TemplateFormatEntity {
    if (json == null) {
        return json;
    }
    return {
        
        'formatId': json['formatId'] == null ? undefined : json['formatId'],
        'formatName': json['formatName'] == null ? undefined : json['formatName'],
    };
}

export function TemplateFormatEntityToJSON(value?: TemplateFormatEntity | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'formatId': value['formatId'],
        'formatName': value['formatName'],
    };
}

