/* tslint:disable */
/* eslint-disable */
/**
 * ReportPro API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GraphByDots
 */
export interface GraphByDots {
    /**
     * 
     * @type {string}
     * @memberof GraphByDots
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof GraphByDots
     */
    type?: string;
    /**
     * 
     * @type {Array<Array<number>>}
     * @memberof GraphByDots
     */
    dots?: Array<Array<number>>;
    /**
     * 
     * @type {boolean}
     * @memberof GraphByDots
     */
    graph?: boolean;
}

/**
 * Check if a given object implements the GraphByDots interface.
 */
export function instanceOfGraphByDots(value: object): value is GraphByDots {
    return true;
}

export function GraphByDotsFromJSON(json: any): GraphByDots {
    return GraphByDotsFromJSONTyped(json, false);
}

export function GraphByDotsFromJSONTyped(json: any, ignoreDiscriminator: boolean): GraphByDots {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'] == null ? undefined : json['name'],
        'type': json['type'] == null ? undefined : json['type'],
        'dots': json['dots'] == null ? undefined : json['dots'],
        'graph': json['graph'] == null ? undefined : json['graph'],
    };
}

export function GraphByDotsToJSON(value?: GraphByDots | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'type': value['type'],
        'dots': value['dots'],
        'graph': value['graph'],
    };
}

