/* tslint:disable */
/* eslint-disable */
/**
 * ReportPro API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OperationDTO
 */
export interface OperationDTO {
    /**
     * 
     * @type {string}
     * @memberof OperationDTO
     */
    operationCode?: string;
    /**
     * 
     * @type {string}
     * @memberof OperationDTO
     */
    operationName?: string;
}

/**
 * Check if a given object implements the OperationDTO interface.
 */
export function instanceOfOperationDTO(value: object): value is OperationDTO {
    return true;
}

export function OperationDTOFromJSON(json: any): OperationDTO {
    return OperationDTOFromJSONTyped(json, false);
}

export function OperationDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): OperationDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'operationCode': json['operationCode'] == null ? undefined : json['operationCode'],
        'operationName': json['operationName'] == null ? undefined : json['operationName'],
    };
}

export function OperationDTOToJSON(value?: OperationDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'operationCode': value['operationCode'],
        'operationName': value['operationName'],
    };
}

