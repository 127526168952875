/* tslint:disable */
/* eslint-disable */
/**
 * ReportPro API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { AntiCrashRotationDrmOperationInterval } from './AntiCrashRotationDrmOperationInterval';
import {
    instanceOfAntiCrashRotationDrmOperationInterval,
    AntiCrashRotationDrmOperationIntervalFromJSON,
    AntiCrashRotationDrmOperationIntervalFromJSONTyped,
    AntiCrashRotationDrmOperationIntervalToJSON,
} from './AntiCrashRotationDrmOperationInterval';
import type { LoadCapacityDrmOperationInterval } from './LoadCapacityDrmOperationInterval';
import {
    instanceOfLoadCapacityDrmOperationInterval,
    LoadCapacityDrmOperationIntervalFromJSON,
    LoadCapacityDrmOperationIntervalFromJSONTyped,
    LoadCapacityDrmOperationIntervalToJSON,
} from './LoadCapacityDrmOperationInterval';
import type { PositioningOffTheBottomDrmOperationInterval } from './PositioningOffTheBottomDrmOperationInterval';
import {
    instanceOfPositioningOffTheBottomDrmOperationInterval,
    PositioningOffTheBottomDrmOperationIntervalFromJSON,
    PositioningOffTheBottomDrmOperationIntervalFromJSONTyped,
    PositioningOffTheBottomDrmOperationIntervalToJSON,
} from './PositioningOffTheBottomDrmOperationInterval';
import type { ReamingRotorDrmOperationInterval } from './ReamingRotorDrmOperationInterval';
import {
    instanceOfReamingRotorDrmOperationInterval,
    ReamingRotorDrmOperationIntervalFromJSON,
    ReamingRotorDrmOperationIntervalFromJSONTyped,
    ReamingRotorDrmOperationIntervalToJSON,
} from './ReamingRotorDrmOperationInterval';
import type { ReamingSlideDrmOperationInterval } from './ReamingSlideDrmOperationInterval';
import {
    instanceOfReamingSlideDrmOperationInterval,
    ReamingSlideDrmOperationIntervalFromJSON,
    ReamingSlideDrmOperationIntervalFromJSONTyped,
    ReamingSlideDrmOperationIntervalToJSON,
} from './ReamingSlideDrmOperationInterval';
import type { RecordingStaticMeasurementDrmOperationInterval } from './RecordingStaticMeasurementDrmOperationInterval';
import {
    instanceOfRecordingStaticMeasurementDrmOperationInterval,
    RecordingStaticMeasurementDrmOperationIntervalFromJSON,
    RecordingStaticMeasurementDrmOperationIntervalFromJSONTyped,
    RecordingStaticMeasurementDrmOperationIntervalToJSON,
} from './RecordingStaticMeasurementDrmOperationInterval';
import type { RestrictionDrmOperationInterval } from './RestrictionDrmOperationInterval';
import {
    instanceOfRestrictionDrmOperationInterval,
    RestrictionDrmOperationIntervalFromJSON,
    RestrictionDrmOperationIntervalFromJSONTyped,
    RestrictionDrmOperationIntervalToJSON,
} from './RestrictionDrmOperationInterval';
import type { RotorDrillingDrmOperationInterval } from './RotorDrillingDrmOperationInterval';
import {
    instanceOfRotorDrillingDrmOperationInterval,
    RotorDrillingDrmOperationIntervalFromJSON,
    RotorDrillingDrmOperationIntervalFromJSONTyped,
    RotorDrillingDrmOperationIntervalToJSON,
} from './RotorDrillingDrmOperationInterval';
import type { SlideDrillingDrmOperationInterval } from './SlideDrillingDrmOperationInterval';
import {
    instanceOfSlideDrillingDrmOperationInterval,
    SlideDrillingDrmOperationIntervalFromJSON,
    SlideDrillingDrmOperationIntervalFromJSONTyped,
    SlideDrillingDrmOperationIntervalToJSON,
} from './SlideDrillingDrmOperationInterval';
import type { SpoDrmOperationInterval } from './SpoDrmOperationInterval';
import {
    instanceOfSpoDrmOperationInterval,
    SpoDrmOperationIntervalFromJSON,
    SpoDrmOperationIntervalFromJSONTyped,
    SpoDrmOperationIntervalToJSON,
} from './SpoDrmOperationInterval';
import type { StaticMeasurementOutputDrmOperationInterval } from './StaticMeasurementOutputDrmOperationInterval';
import {
    instanceOfStaticMeasurementOutputDrmOperationInterval,
    StaticMeasurementOutputDrmOperationIntervalFromJSON,
    StaticMeasurementOutputDrmOperationIntervalFromJSONTyped,
    StaticMeasurementOutputDrmOperationIntervalToJSON,
} from './StaticMeasurementOutputDrmOperationInterval';
import type { SwitchingOffThePumpDrmOperationInterval } from './SwitchingOffThePumpDrmOperationInterval';
import {
    instanceOfSwitchingOffThePumpDrmOperationInterval,
    SwitchingOffThePumpDrmOperationIntervalFromJSON,
    SwitchingOffThePumpDrmOperationIntervalFromJSONTyped,
    SwitchingOffThePumpDrmOperationIntervalToJSON,
} from './SwitchingOffThePumpDrmOperationInterval';
import type { SwitchingToTheModeDrmOperationInterval } from './SwitchingToTheModeDrmOperationInterval';
import {
    instanceOfSwitchingToTheModeDrmOperationInterval,
    SwitchingToTheModeDrmOperationIntervalFromJSON,
    SwitchingToTheModeDrmOperationIntervalFromJSONTyped,
    SwitchingToTheModeDrmOperationIntervalToJSON,
} from './SwitchingToTheModeDrmOperationInterval';
import type { TfOrientationDrmOperationInterval } from './TfOrientationDrmOperationInterval';
import {
    instanceOfTfOrientationDrmOperationInterval,
    TfOrientationDrmOperationIntervalFromJSON,
    TfOrientationDrmOperationIntervalFromJSONTyped,
    TfOrientationDrmOperationIntervalToJSON,
} from './TfOrientationDrmOperationInterval';
import type { WorkingOutDownDrmOperationInterval } from './WorkingOutDownDrmOperationInterval';
import {
    instanceOfWorkingOutDownDrmOperationInterval,
    WorkingOutDownDrmOperationIntervalFromJSON,
    WorkingOutDownDrmOperationIntervalFromJSONTyped,
    WorkingOutDownDrmOperationIntervalToJSON,
} from './WorkingOutDownDrmOperationInterval';
import type { WorkingOutUpDrmOperationInterval } from './WorkingOutUpDrmOperationInterval';
import {
    instanceOfWorkingOutUpDrmOperationInterval,
    WorkingOutUpDrmOperationIntervalFromJSON,
    WorkingOutUpDrmOperationIntervalFromJSONTyped,
    WorkingOutUpDrmOperationIntervalToJSON,
} from './WorkingOutUpDrmOperationInterval';

/**
 * @type UpdateIntervalInOperationDrmTableRequest
 * 
 * @export
 */
export type UpdateIntervalInOperationDrmTableRequest = AntiCrashRotationDrmOperationInterval | LoadCapacityDrmOperationInterval | PositioningOffTheBottomDrmOperationInterval | ReamingRotorDrmOperationInterval | ReamingSlideDrmOperationInterval | RecordingStaticMeasurementDrmOperationInterval | RestrictionDrmOperationInterval | RotorDrillingDrmOperationInterval | SlideDrillingDrmOperationInterval | SpoDrmOperationInterval | StaticMeasurementOutputDrmOperationInterval | SwitchingOffThePumpDrmOperationInterval | SwitchingToTheModeDrmOperationInterval | TfOrientationDrmOperationInterval | WorkingOutDownDrmOperationInterval | WorkingOutUpDrmOperationInterval;

export function UpdateIntervalInOperationDrmTableRequestFromJSON(json: any): UpdateIntervalInOperationDrmTableRequest {
    return UpdateIntervalInOperationDrmTableRequestFromJSONTyped(json, false);
}

export function UpdateIntervalInOperationDrmTableRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateIntervalInOperationDrmTableRequest {
    if (json == null) {
        return json;
    }
    if (instanceOfAntiCrashRotationDrmOperationInterval(json)) {
        return AntiCrashRotationDrmOperationIntervalFromJSONTyped(json, true);
    }
    if (instanceOfLoadCapacityDrmOperationInterval(json)) {
        return LoadCapacityDrmOperationIntervalFromJSONTyped(json, true);
    }
    if (instanceOfPositioningOffTheBottomDrmOperationInterval(json)) {
        return PositioningOffTheBottomDrmOperationIntervalFromJSONTyped(json, true);
    }
    if (instanceOfReamingRotorDrmOperationInterval(json)) {
        return ReamingRotorDrmOperationIntervalFromJSONTyped(json, true);
    }
    if (instanceOfReamingSlideDrmOperationInterval(json)) {
        return ReamingSlideDrmOperationIntervalFromJSONTyped(json, true);
    }
    if (instanceOfRecordingStaticMeasurementDrmOperationInterval(json)) {
        return RecordingStaticMeasurementDrmOperationIntervalFromJSONTyped(json, true);
    }
    if (instanceOfRestrictionDrmOperationInterval(json)) {
        return RestrictionDrmOperationIntervalFromJSONTyped(json, true);
    }
    if (instanceOfRotorDrillingDrmOperationInterval(json)) {
        return RotorDrillingDrmOperationIntervalFromJSONTyped(json, true);
    }
    if (instanceOfSlideDrillingDrmOperationInterval(json)) {
        return SlideDrillingDrmOperationIntervalFromJSONTyped(json, true);
    }
    if (instanceOfSpoDrmOperationInterval(json)) {
        return SpoDrmOperationIntervalFromJSONTyped(json, true);
    }
    if (instanceOfStaticMeasurementOutputDrmOperationInterval(json)) {
        return StaticMeasurementOutputDrmOperationIntervalFromJSONTyped(json, true);
    }
    if (instanceOfSwitchingOffThePumpDrmOperationInterval(json)) {
        return SwitchingOffThePumpDrmOperationIntervalFromJSONTyped(json, true);
    }
    if (instanceOfSwitchingToTheModeDrmOperationInterval(json)) {
        return SwitchingToTheModeDrmOperationIntervalFromJSONTyped(json, true);
    }
    if (instanceOfTfOrientationDrmOperationInterval(json)) {
        return TfOrientationDrmOperationIntervalFromJSONTyped(json, true);
    }
    if (instanceOfWorkingOutDownDrmOperationInterval(json)) {
        return WorkingOutDownDrmOperationIntervalFromJSONTyped(json, true);
    }
    if (instanceOfWorkingOutUpDrmOperationInterval(json)) {
        return WorkingOutUpDrmOperationIntervalFromJSONTyped(json, true);
    }

    return {} as any;
}

export function UpdateIntervalInOperationDrmTableRequestToJSON(value?: UpdateIntervalInOperationDrmTableRequest | null): any {
    if (value == null) {
        return value;
    }

    if (instanceOfAntiCrashRotationDrmOperationInterval(value)) {
        return AntiCrashRotationDrmOperationIntervalToJSON(value as AntiCrashRotationDrmOperationInterval);
    }
    if (instanceOfLoadCapacityDrmOperationInterval(value)) {
        return LoadCapacityDrmOperationIntervalToJSON(value as LoadCapacityDrmOperationInterval);
    }
    if (instanceOfPositioningOffTheBottomDrmOperationInterval(value)) {
        return PositioningOffTheBottomDrmOperationIntervalToJSON(value as PositioningOffTheBottomDrmOperationInterval);
    }
    if (instanceOfReamingRotorDrmOperationInterval(value)) {
        return ReamingRotorDrmOperationIntervalToJSON(value as ReamingRotorDrmOperationInterval);
    }
    if (instanceOfReamingSlideDrmOperationInterval(value)) {
        return ReamingSlideDrmOperationIntervalToJSON(value as ReamingSlideDrmOperationInterval);
    }
    if (instanceOfRecordingStaticMeasurementDrmOperationInterval(value)) {
        return RecordingStaticMeasurementDrmOperationIntervalToJSON(value as RecordingStaticMeasurementDrmOperationInterval);
    }
    if (instanceOfRestrictionDrmOperationInterval(value)) {
        return RestrictionDrmOperationIntervalToJSON(value as RestrictionDrmOperationInterval);
    }
    if (instanceOfRotorDrillingDrmOperationInterval(value)) {
        return RotorDrillingDrmOperationIntervalToJSON(value as RotorDrillingDrmOperationInterval);
    }
    if (instanceOfSlideDrillingDrmOperationInterval(value)) {
        return SlideDrillingDrmOperationIntervalToJSON(value as SlideDrillingDrmOperationInterval);
    }
    if (instanceOfSpoDrmOperationInterval(value)) {
        return SpoDrmOperationIntervalToJSON(value as SpoDrmOperationInterval);
    }
    if (instanceOfStaticMeasurementOutputDrmOperationInterval(value)) {
        return StaticMeasurementOutputDrmOperationIntervalToJSON(value as StaticMeasurementOutputDrmOperationInterval);
    }
    if (instanceOfSwitchingOffThePumpDrmOperationInterval(value)) {
        return SwitchingOffThePumpDrmOperationIntervalToJSON(value as SwitchingOffThePumpDrmOperationInterval);
    }
    if (instanceOfSwitchingToTheModeDrmOperationInterval(value)) {
        return SwitchingToTheModeDrmOperationIntervalToJSON(value as SwitchingToTheModeDrmOperationInterval);
    }
    if (instanceOfTfOrientationDrmOperationInterval(value)) {
        return TfOrientationDrmOperationIntervalToJSON(value as TfOrientationDrmOperationInterval);
    }
    if (instanceOfWorkingOutDownDrmOperationInterval(value)) {
        return WorkingOutDownDrmOperationIntervalToJSON(value as WorkingOutDownDrmOperationInterval);
    }
    if (instanceOfWorkingOutUpDrmOperationInterval(value)) {
        return WorkingOutUpDrmOperationIntervalToJSON(value as WorkingOutUpDrmOperationInterval);
    }

    return {};
}

