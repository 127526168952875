/* tslint:disable */
/* eslint-disable */
/**
 * ReportPro API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Param } from './Param';
import {
    ParamFromJSON,
    ParamFromJSONTyped,
    ParamToJSON,
} from './Param';

/**
 * 
 * @export
 * @interface AutoDrillingOperationParamsSchema
 */
export interface AutoDrillingOperationParamsSchema {
    /**
     * 
     * @type {string}
     * @memberof AutoDrillingOperationParamsSchema
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof AutoDrillingOperationParamsSchema
     */
    stageType: AutoDrillingOperationParamsSchemaStageTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof AutoDrillingOperationParamsSchema
     */
    operationType: AutoDrillingOperationParamsSchemaOperationTypeEnum;
    /**
     * 
     * @type {Array<Param>}
     * @memberof AutoDrillingOperationParamsSchema
     */
    params: Array<Param>;
}


/**
 * @export
 */
export const AutoDrillingOperationParamsSchemaStageTypeEnum = {
    PumpStart: 'PUMP_START',
    OscillationAngles: 'OSCILLATION_ANGLES',
    Rotor: 'ROTOR',
    ReamingRotor: 'REAMING_ROTOR',
    PositioningOffTheBottom: 'POSITIONING_OFF_THE_BOTTOM',
    Slide: 'SLIDE',
    ReamingSlide: 'REAMING_SLIDE',
    RecordingStaticMeasurement: 'RECORDING_STATIC_MEASUREMENT',
    SwitchingOffThePump: 'SWITCHING_OFF_THE_PUMP'
} as const;
export type AutoDrillingOperationParamsSchemaStageTypeEnum = typeof AutoDrillingOperationParamsSchemaStageTypeEnum[keyof typeof AutoDrillingOperationParamsSchemaStageTypeEnum];

/**
 * @export
 */
export const AutoDrillingOperationParamsSchemaOperationTypeEnum = {
    PumpStart: 'PUMP_START',
    SwitchingToTheMode: 'SWITCHING_TO_THE_MODE',
    OscillationAngles: 'OSCILLATION_ANGLES',
    RotorRpmAcceleration: 'ROTOR_RPM_ACCELERATION',
    RotorLoweringBit: 'ROTOR_LOWERING_BIT',
    Rotor: 'ROTOR',
    LoadCapacity: 'LOAD_CAPACITY',
    ReamingRotor: 'REAMING_ROTOR',
    PositioningOffTheBottom: 'POSITIONING_OFF_THE_BOTTOM',
    TfOrientation: 'TF_ORIENTATION',
    SlideLoweringBit: 'SLIDE_LOWERING_BIT',
    Slide: 'SLIDE',
    ReamingSlide: 'REAMING_SLIDE',
    RecordingStaticMeasurement: 'RECORDING_STATIC_MEASUREMENT',
    SwitchingOffThePump: 'SWITCHING_OFF_THE_PUMP'
} as const;
export type AutoDrillingOperationParamsSchemaOperationTypeEnum = typeof AutoDrillingOperationParamsSchemaOperationTypeEnum[keyof typeof AutoDrillingOperationParamsSchemaOperationTypeEnum];


/**
 * Check if a given object implements the AutoDrillingOperationParamsSchema interface.
 */
export function instanceOfAutoDrillingOperationParamsSchema(value: object): value is AutoDrillingOperationParamsSchema {
    if (!('title' in value) || value['title'] === undefined) return false;
    if (!('stageType' in value) || value['stageType'] === undefined) return false;
    if (!('operationType' in value) || value['operationType'] === undefined) return false;
    if (!('params' in value) || value['params'] === undefined) return false;
    return true;
}

export function AutoDrillingOperationParamsSchemaFromJSON(json: any): AutoDrillingOperationParamsSchema {
    return AutoDrillingOperationParamsSchemaFromJSONTyped(json, false);
}

export function AutoDrillingOperationParamsSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): AutoDrillingOperationParamsSchema {
    if (json == null) {
        return json;
    }
    return {
        
        'title': json['title'],
        'stageType': json['stageType'],
        'operationType': json['operationType'],
        'params': ((json['params'] as Array<any>).map(ParamFromJSON)),
    };
}

export function AutoDrillingOperationParamsSchemaToJSON(value?: AutoDrillingOperationParamsSchema | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'title': value['title'],
        'stageType': value['stageType'],
        'operationType': value['operationType'],
        'params': ((value['params'] as Array<any>).map(ParamToJSON)),
    };
}

