/* tslint:disable */
/* eslint-disable */
/**
 * ReportPro API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { SchedulerEventContent } from './SchedulerEventContent';
import {
    SchedulerEventContentFromJSON,
    SchedulerEventContentFromJSONTyped,
    SchedulerEventContentToJSON,
} from './SchedulerEventContent';

/**
 * 
 * @export
 * @interface SchedulerEquipmentTechnicalWorkContent
 */
export interface SchedulerEquipmentTechnicalWorkContent extends SchedulerEventContent {
    /**
     * 
     * @type {string}
     * @memberof SchedulerEquipmentTechnicalWorkContent
     */
    workId?: string;
    /**
     * 
     * @type {string}
     * @memberof SchedulerEquipmentTechnicalWorkContent
     */
    technicalMaintenanceId: string;
    /**
     * 
     * @type {Date}
     * @memberof SchedulerEquipmentTechnicalWorkContent
     */
    datePlan: Date;
    /**
     * 
     * @type {Date}
     * @memberof SchedulerEquipmentTechnicalWorkContent
     */
    dateFact?: Date;
    /**
     * 
     * @type {string}
     * @memberof SchedulerEquipmentTechnicalWorkContent
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof SchedulerEquipmentTechnicalWorkContent
     */
    status?: SchedulerEquipmentTechnicalWorkContentStatusEnum;
}


/**
 * @export
 */
export const SchedulerEquipmentTechnicalWorkContentStatusEnum = {
    Overdue: 'OVERDUE',
    Soon: 'SOON',
    NotSoon: 'NOT_SOON',
    Done: 'DONE'
} as const;
export type SchedulerEquipmentTechnicalWorkContentStatusEnum = typeof SchedulerEquipmentTechnicalWorkContentStatusEnum[keyof typeof SchedulerEquipmentTechnicalWorkContentStatusEnum];


/**
 * Check if a given object implements the SchedulerEquipmentTechnicalWorkContent interface.
 */
export function instanceOfSchedulerEquipmentTechnicalWorkContent(value: object): value is SchedulerEquipmentTechnicalWorkContent {
    if (!('technicalMaintenanceId' in value) || value['technicalMaintenanceId'] === undefined) return false;
    if (!('datePlan' in value) || value['datePlan'] === undefined) return false;
    if (!('title' in value) || value['title'] === undefined) return false;
    return true;
}

export function SchedulerEquipmentTechnicalWorkContentFromJSON(json: any): SchedulerEquipmentTechnicalWorkContent {
    return SchedulerEquipmentTechnicalWorkContentFromJSONTyped(json, false);
}

export function SchedulerEquipmentTechnicalWorkContentFromJSONTyped(json: any, ignoreDiscriminator: boolean): SchedulerEquipmentTechnicalWorkContent {
    if (json == null) {
        return json;
    }
    return {
        ...SchedulerEventContentFromJSONTyped(json, ignoreDiscriminator),
        'workId': json['workId'] == null ? undefined : json['workId'],
        'technicalMaintenanceId': json['technicalMaintenanceId'],
        'datePlan': (new Date(json['datePlan'])),
        'dateFact': json['dateFact'] == null ? undefined : (new Date(json['dateFact'])),
        'title': json['title'],
        'status': json['status'] == null ? undefined : json['status'],
    };
}

export function SchedulerEquipmentTechnicalWorkContentToJSON(value?: SchedulerEquipmentTechnicalWorkContent | null): any {
    if (value == null) {
        return value;
    }
    return {
        ...SchedulerEventContentToJSON(value),
        'workId': value['workId'],
        'technicalMaintenanceId': value['technicalMaintenanceId'],
        'datePlan': ((value['datePlan']).toISOString()),
        'dateFact': value['dateFact'] == null ? undefined : ((value['dateFact']).toISOString()),
        'title': value['title'],
        'status': value['status'],
    };
}

