/* tslint:disable */
/* eslint-disable */
/**
 * ReportPro API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ReverseElaboration
 */
export interface ReverseElaboration {
    /**
     * 
     * @type {number}
     * @memberof ReverseElaboration
     */
    rpm: number;
    /**
     * 
     * @type {number}
     * @memberof ReverseElaboration
     */
    maxTightening: number;
    /**
     * 
     * @type {number}
     * @memberof ReverseElaboration
     */
    valueT: number;
    /**
     * 
     * @type {number}
     * @memberof ReverseElaboration
     */
    liftingSpeed: number;
}

/**
 * Check if a given object implements the ReverseElaboration interface.
 */
export function instanceOfReverseElaboration(value: object): value is ReverseElaboration {
    if (!('rpm' in value) || value['rpm'] === undefined) return false;
    if (!('maxTightening' in value) || value['maxTightening'] === undefined) return false;
    if (!('valueT' in value) || value['valueT'] === undefined) return false;
    if (!('liftingSpeed' in value) || value['liftingSpeed'] === undefined) return false;
    return true;
}

export function ReverseElaborationFromJSON(json: any): ReverseElaboration {
    return ReverseElaborationFromJSONTyped(json, false);
}

export function ReverseElaborationFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReverseElaboration {
    if (json == null) {
        return json;
    }
    return {
        
        'rpm': json['rpm'],
        'maxTightening': json['maxTightening'],
        'valueT': json['valueT'],
        'liftingSpeed': json['liftingSpeed'],
    };
}

export function ReverseElaborationToJSON(value?: ReverseElaboration | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'rpm': value['rpm'],
        'maxTightening': value['maxTightening'],
        'valueT': value['valueT'],
        'liftingSpeed': value['liftingSpeed'],
    };
}

