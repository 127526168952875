/* tslint:disable */
/* eslint-disable */
/**
 * ReportPro API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CurveRequest
 */
export interface CurveRequest {
    /**
     * 
     * @type {string}
     * @memberof CurveRequest
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof CurveRequest
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CurveRequest
     */
    mnemonicId?: string;
    /**
     * 
     * @type {string}
     * @memberof CurveRequest
     */
    unitId?: string;
    /**
     * 
     * @type {string}
     * @memberof CurveRequest
     */
    lineTypeId?: string;
    /**
     * 
     * @type {string}
     * @memberof CurveRequest
     */
    lineColor?: string;
    /**
     * 
     * @type {number}
     * @memberof CurveRequest
     */
    lineThickness?: number;
    /**
     * 
     * @type {number}
     * @memberof CurveRequest
     */
    limitMin?: number;
    /**
     * 
     * @type {number}
     * @memberof CurveRequest
     */
    limitMax?: number;
    /**
     * 
     * @type {number}
     * @memberof CurveRequest
     */
    curveLimitMin?: number;
    /**
     * 
     * @type {number}
     * @memberof CurveRequest
     */
    curveLimitMax?: number;
    /**
     * 
     * @type {string}
     * @memberof CurveRequest
     */
    curveLimitColor?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CurveRequest
     */
    limitMinAuto?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CurveRequest
     */
    limitMaxAuto?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CurveRequest
     */
    extLimit?: boolean;
}

/**
 * Check if a given object implements the CurveRequest interface.
 */
export function instanceOfCurveRequest(value: object): value is CurveRequest {
    if (!('name' in value) || value['name'] === undefined) return false;
    return true;
}

export function CurveRequestFromJSON(json: any): CurveRequest {
    return CurveRequestFromJSONTyped(json, false);
}

export function CurveRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CurveRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'name': json['name'],
        'mnemonicId': json['mnemonicId'] == null ? undefined : json['mnemonicId'],
        'unitId': json['unitId'] == null ? undefined : json['unitId'],
        'lineTypeId': json['lineTypeId'] == null ? undefined : json['lineTypeId'],
        'lineColor': json['lineColor'] == null ? undefined : json['lineColor'],
        'lineThickness': json['lineThickness'] == null ? undefined : json['lineThickness'],
        'limitMin': json['limitMin'] == null ? undefined : json['limitMin'],
        'limitMax': json['limitMax'] == null ? undefined : json['limitMax'],
        'curveLimitMin': json['curveLimitMin'] == null ? undefined : json['curveLimitMin'],
        'curveLimitMax': json['curveLimitMax'] == null ? undefined : json['curveLimitMax'],
        'curveLimitColor': json['curveLimitColor'] == null ? undefined : json['curveLimitColor'],
        'limitMinAuto': json['limitMinAuto'] == null ? undefined : json['limitMinAuto'],
        'limitMaxAuto': json['limitMaxAuto'] == null ? undefined : json['limitMaxAuto'],
        'extLimit': json['extLimit'] == null ? undefined : json['extLimit'],
    };
}

export function CurveRequestToJSON(value?: CurveRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'name': value['name'],
        'mnemonicId': value['mnemonicId'],
        'unitId': value['unitId'],
        'lineTypeId': value['lineTypeId'],
        'lineColor': value['lineColor'],
        'lineThickness': value['lineThickness'],
        'limitMin': value['limitMin'],
        'limitMax': value['limitMax'],
        'curveLimitMin': value['curveLimitMin'],
        'curveLimitMax': value['curveLimitMax'],
        'curveLimitColor': value['curveLimitColor'],
        'limitMinAuto': value['limitMinAuto'],
        'limitMaxAuto': value['limitMaxAuto'],
        'extLimit': value['extLimit'],
    };
}

