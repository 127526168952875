/* tslint:disable */
/* eslint-disable */
/**
 * ReportPro API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { TemplateFormatEntity } from './TemplateFormatEntity';
import {
    TemplateFormatEntityFromJSON,
    TemplateFormatEntityFromJSONTyped,
    TemplateFormatEntityToJSON,
} from './TemplateFormatEntity';

/**
 * 
 * @export
 * @interface TemplateScaleEntity
 */
export interface TemplateScaleEntity {
    /**
     * 
     * @type {string}
     * @memberof TemplateScaleEntity
     */
    id?: string;
    /**
     * 
     * @type {TemplateFormatEntity}
     * @memberof TemplateScaleEntity
     */
    templateFormat?: TemplateFormatEntity;
    /**
     * 
     * @type {string}
     * @memberof TemplateScaleEntity
     */
    type?: string;
    /**
     * 
     * @type {number}
     * @memberof TemplateScaleEntity
     */
    interval?: number;
    /**
     * 
     * @type {number}
     * @memberof TemplateScaleEntity
     */
    step?: number;
    /**
     * 
     * @type {number}
     * @memberof TemplateScaleEntity
     */
    partsNumber?: number;
}

/**
 * Check if a given object implements the TemplateScaleEntity interface.
 */
export function instanceOfTemplateScaleEntity(value: object): value is TemplateScaleEntity {
    return true;
}

export function TemplateScaleEntityFromJSON(json: any): TemplateScaleEntity {
    return TemplateScaleEntityFromJSONTyped(json, false);
}

export function TemplateScaleEntityFromJSONTyped(json: any, ignoreDiscriminator: boolean): TemplateScaleEntity {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'templateFormat': json['templateFormat'] == null ? undefined : TemplateFormatEntityFromJSON(json['templateFormat']),
        'type': json['type'] == null ? undefined : json['type'],
        'interval': json['interval'] == null ? undefined : json['interval'],
        'step': json['step'] == null ? undefined : json['step'],
        'partsNumber': json['partsNumber'] == null ? undefined : json['partsNumber'],
    };
}

export function TemplateScaleEntityToJSON(value?: TemplateScaleEntity | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'templateFormat': TemplateFormatEntityToJSON(value['templateFormat']),
        'type': value['type'],
        'interval': value['interval'],
        'step': value['step'],
        'partsNumber': value['partsNumber'],
    };
}

