/* tslint:disable */
/* eslint-disable */
/**
 * ReportPro API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { DrillingRigContent } from './DrillingRigContent';
import {
    instanceOfDrillingRigContent,
    DrillingRigContentFromJSON,
    DrillingRigContentFromJSONTyped,
    DrillingRigContentToJSON,
} from './DrillingRigContent';
import type { SchedulerDeviceContent } from './SchedulerDeviceContent';
import {
    instanceOfSchedulerDeviceContent,
    SchedulerDeviceContentFromJSON,
    SchedulerDeviceContentFromJSONTyped,
    SchedulerDeviceContentToJSON,
} from './SchedulerDeviceContent';
import type { SchedulerFieldContent } from './SchedulerFieldContent';
import {
    instanceOfSchedulerFieldContent,
    SchedulerFieldContentFromJSON,
    SchedulerFieldContentFromJSONTyped,
    SchedulerFieldContentToJSON,
} from './SchedulerFieldContent';
import type { SchedulerPadContent } from './SchedulerPadContent';
import {
    instanceOfSchedulerPadContent,
    SchedulerPadContentFromJSON,
    SchedulerPadContentFromJSONTyped,
    SchedulerPadContentToJSON,
} from './SchedulerPadContent';
import type { SchedulerSimpleDateContent } from './SchedulerSimpleDateContent';
import {
    instanceOfSchedulerSimpleDateContent,
    SchedulerSimpleDateContentFromJSON,
    SchedulerSimpleDateContentFromJSONTyped,
    SchedulerSimpleDateContentToJSON,
} from './SchedulerSimpleDateContent';
import type { SchedulerTechnicalMaintenanceContent } from './SchedulerTechnicalMaintenanceContent';
import {
    instanceOfSchedulerTechnicalMaintenanceContent,
    SchedulerTechnicalMaintenanceContentFromJSON,
    SchedulerTechnicalMaintenanceContentFromJSONTyped,
    SchedulerTechnicalMaintenanceContentToJSON,
} from './SchedulerTechnicalMaintenanceContent';
import type { SchedulerWellContent } from './SchedulerWellContent';
import {
    instanceOfSchedulerWellContent,
    SchedulerWellContentFromJSON,
    SchedulerWellContentFromJSONTyped,
    SchedulerWellContentToJSON,
} from './SchedulerWellContent';

/**
 * @type SchedulerItemData
 * 
 * @export
 */
export type SchedulerItemData = DrillingRigContent | SchedulerDeviceContent | SchedulerFieldContent | SchedulerPadContent | SchedulerSimpleDateContent | SchedulerTechnicalMaintenanceContent | SchedulerWellContent;

export function SchedulerItemDataFromJSON(json: any): SchedulerItemData {
    return SchedulerItemDataFromJSONTyped(json, false);
}

export function SchedulerItemDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): SchedulerItemData {
    if (json == null) {
        return json;
    }
    if (instanceOfDrillingRigContent(json)) {
        return DrillingRigContentFromJSONTyped(json, true);
    }
    if (instanceOfSchedulerDeviceContent(json)) {
        return SchedulerDeviceContentFromJSONTyped(json, true);
    }
    if (instanceOfSchedulerFieldContent(json)) {
        return SchedulerFieldContentFromJSONTyped(json, true);
    }
    if (instanceOfSchedulerPadContent(json)) {
        return SchedulerPadContentFromJSONTyped(json, true);
    }
    if (instanceOfSchedulerSimpleDateContent(json)) {
        return SchedulerSimpleDateContentFromJSONTyped(json, true);
    }
    if (instanceOfSchedulerTechnicalMaintenanceContent(json)) {
        return SchedulerTechnicalMaintenanceContentFromJSONTyped(json, true);
    }
    if (instanceOfSchedulerWellContent(json)) {
        return SchedulerWellContentFromJSONTyped(json, true);
    }

    return {} as any;
}

export function SchedulerItemDataToJSON(value?: SchedulerItemData | null): any {
    if (value == null) {
        return value;
    }

    if (instanceOfDrillingRigContent(value)) {
        return DrillingRigContentToJSON(value as DrillingRigContent);
    }
    if (instanceOfSchedulerDeviceContent(value)) {
        return SchedulerDeviceContentToJSON(value as SchedulerDeviceContent);
    }
    if (instanceOfSchedulerFieldContent(value)) {
        return SchedulerFieldContentToJSON(value as SchedulerFieldContent);
    }
    if (instanceOfSchedulerPadContent(value)) {
        return SchedulerPadContentToJSON(value as SchedulerPadContent);
    }
    if (instanceOfSchedulerSimpleDateContent(value)) {
        return SchedulerSimpleDateContentToJSON(value as SchedulerSimpleDateContent);
    }
    if (instanceOfSchedulerTechnicalMaintenanceContent(value)) {
        return SchedulerTechnicalMaintenanceContentToJSON(value as SchedulerTechnicalMaintenanceContent);
    }
    if (instanceOfSchedulerWellContent(value)) {
        return SchedulerWellContentToJSON(value as SchedulerWellContent);
    }

    return {};
}

