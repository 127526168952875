/* tslint:disable */
/* eslint-disable */
/**
 * ReportPro API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { TechnicalMaintenanceAct } from './TechnicalMaintenanceAct';
import {
    TechnicalMaintenanceActFromJSON,
    TechnicalMaintenanceActFromJSONTyped,
    TechnicalMaintenanceActToJSON,
} from './TechnicalMaintenanceAct';

/**
 * 
 * @export
 * @interface TechnicalMaintenanceWork
 */
export interface TechnicalMaintenanceWork {
    /**
     * 
     * @type {string}
     * @memberof TechnicalMaintenanceWork
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof TechnicalMaintenanceWork
     */
    durationHours: number;
    /**
     * 
     * @type {Date}
     * @memberof TechnicalMaintenanceWork
     */
    dateFact?: Date;
    /**
     * 
     * @type {Date}
     * @memberof TechnicalMaintenanceWork
     */
    datePlan?: Date;
    /**
     * 
     * @type {Date}
     * @memberof TechnicalMaintenanceWork
     */
    dateMonitoring?: Date;
    /**
     * 
     * @type {string}
     * @memberof TechnicalMaintenanceWork
     */
    status: TechnicalMaintenanceWorkStatusEnum;
    /**
     * 
     * @type {Array<TechnicalMaintenanceAct>}
     * @memberof TechnicalMaintenanceWork
     */
    acts: Array<TechnicalMaintenanceAct>;
    /**
     * 
     * @type {string}
     * @memberof TechnicalMaintenanceWork
     */
    timeNormativeId: string;
}


/**
 * @export
 */
export const TechnicalMaintenanceWorkStatusEnum = {
    Plan: 'PLAN',
    Done: 'DONE'
} as const;
export type TechnicalMaintenanceWorkStatusEnum = typeof TechnicalMaintenanceWorkStatusEnum[keyof typeof TechnicalMaintenanceWorkStatusEnum];


/**
 * Check if a given object implements the TechnicalMaintenanceWork interface.
 */
export function instanceOfTechnicalMaintenanceWork(value: object): value is TechnicalMaintenanceWork {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('durationHours' in value) || value['durationHours'] === undefined) return false;
    if (!('status' in value) || value['status'] === undefined) return false;
    if (!('acts' in value) || value['acts'] === undefined) return false;
    if (!('timeNormativeId' in value) || value['timeNormativeId'] === undefined) return false;
    return true;
}

export function TechnicalMaintenanceWorkFromJSON(json: any): TechnicalMaintenanceWork {
    return TechnicalMaintenanceWorkFromJSONTyped(json, false);
}

export function TechnicalMaintenanceWorkFromJSONTyped(json: any, ignoreDiscriminator: boolean): TechnicalMaintenanceWork {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'durationHours': json['durationHours'],
        'dateFact': json['dateFact'] == null ? undefined : (new Date(json['dateFact'])),
        'datePlan': json['datePlan'] == null ? undefined : (new Date(json['datePlan'])),
        'dateMonitoring': json['dateMonitoring'] == null ? undefined : (new Date(json['dateMonitoring'])),
        'status': json['status'],
        'acts': ((json['acts'] as Array<any>).map(TechnicalMaintenanceActFromJSON)),
        'timeNormativeId': json['timeNormativeId'],
    };
}

export function TechnicalMaintenanceWorkToJSON(value?: TechnicalMaintenanceWork | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'durationHours': value['durationHours'],
        'dateFact': value['dateFact'] == null ? undefined : ((value['dateFact']).toISOString()),
        'datePlan': value['datePlan'] == null ? undefined : ((value['datePlan']).toISOString()),
        'dateMonitoring': value['dateMonitoring'] == null ? undefined : ((value['dateMonitoring']).toISOString()),
        'status': value['status'],
        'acts': ((value['acts'] as Array<any>).map(TechnicalMaintenanceActToJSON)),
        'timeNormativeId': value['timeNormativeId'],
    };
}

