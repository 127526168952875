/* tslint:disable */
/* eslint-disable */
/**
 * ReportPro API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { AutoDrillingProgramFunction } from './AutoDrillingProgramFunction';
import {
    AutoDrillingProgramFunctionFromJSON,
    AutoDrillingProgramFunctionFromJSONTyped,
    AutoDrillingProgramFunctionToJSON,
} from './AutoDrillingProgramFunction';

/**
 * 
 * @export
 * @interface AutoDrillingProgramOperation
 */
export interface AutoDrillingProgramOperation {
    /**
     * 
     * @type {string}
     * @memberof AutoDrillingProgramOperation
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof AutoDrillingProgramOperation
     */
    type: AutoDrillingProgramOperationTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof AutoDrillingProgramOperation
     */
    title?: string;
    /**
     * 
     * @type {number}
     * @memberof AutoDrillingProgramOperation
     */
    itemOrder: number;
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof AutoDrillingProgramOperation
     */
    params: { [key: string]: number; };
    /**
     * 
     * @type {boolean}
     * @memberof AutoDrillingProgramOperation
     */
    withoutFunctions?: boolean;
    /**
     * 
     * @type {Array<AutoDrillingProgramFunction>}
     * @memberof AutoDrillingProgramOperation
     */
    functions: Array<AutoDrillingProgramFunction>;
}


/**
 * @export
 */
export const AutoDrillingProgramOperationTypeEnum = {
    PumpStart: 'PUMP_START',
    SwitchingToTheMode: 'SWITCHING_TO_THE_MODE',
    OscillationAngles: 'OSCILLATION_ANGLES',
    RotorRpmAcceleration: 'ROTOR_RPM_ACCELERATION',
    RotorLoweringBit: 'ROTOR_LOWERING_BIT',
    Rotor: 'ROTOR',
    LoadCapacity: 'LOAD_CAPACITY',
    ReamingRotor: 'REAMING_ROTOR',
    PositioningOffTheBottom: 'POSITIONING_OFF_THE_BOTTOM',
    TfOrientation: 'TF_ORIENTATION',
    SlideLoweringBit: 'SLIDE_LOWERING_BIT',
    Slide: 'SLIDE',
    ReamingSlide: 'REAMING_SLIDE',
    RecordingStaticMeasurement: 'RECORDING_STATIC_MEASUREMENT',
    SwitchingOffThePump: 'SWITCHING_OFF_THE_PUMP'
} as const;
export type AutoDrillingProgramOperationTypeEnum = typeof AutoDrillingProgramOperationTypeEnum[keyof typeof AutoDrillingProgramOperationTypeEnum];


/**
 * Check if a given object implements the AutoDrillingProgramOperation interface.
 */
export function instanceOfAutoDrillingProgramOperation(value: object): value is AutoDrillingProgramOperation {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('type' in value) || value['type'] === undefined) return false;
    if (!('itemOrder' in value) || value['itemOrder'] === undefined) return false;
    if (!('params' in value) || value['params'] === undefined) return false;
    if (!('functions' in value) || value['functions'] === undefined) return false;
    return true;
}

export function AutoDrillingProgramOperationFromJSON(json: any): AutoDrillingProgramOperation {
    return AutoDrillingProgramOperationFromJSONTyped(json, false);
}

export function AutoDrillingProgramOperationFromJSONTyped(json: any, ignoreDiscriminator: boolean): AutoDrillingProgramOperation {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'type': json['type'],
        'title': json['title'] == null ? undefined : json['title'],
        'itemOrder': json['itemOrder'],
        'params': json['params'],
        'withoutFunctions': json['withoutFunctions'] == null ? undefined : json['withoutFunctions'],
        'functions': ((json['functions'] as Array<any>).map(AutoDrillingProgramFunctionFromJSON)),
    };
}

export function AutoDrillingProgramOperationToJSON(value?: AutoDrillingProgramOperation | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'type': value['type'],
        'title': value['title'],
        'itemOrder': value['itemOrder'],
        'params': value['params'],
        'withoutFunctions': value['withoutFunctions'],
        'functions': ((value['functions'] as Array<any>).map(AutoDrillingProgramFunctionToJSON)),
    };
}

