/* tslint:disable */
/* eslint-disable */
/**
 * ReportPro API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TitleDto
 */
export interface TitleDto {
    /**
     * 
     * @type {string}
     * @memberof TitleDto
     */
    title: string;
}

/**
 * Check if a given object implements the TitleDto interface.
 */
export function instanceOfTitleDto(value: object): value is TitleDto {
    if (!('title' in value) || value['title'] === undefined) return false;
    return true;
}

export function TitleDtoFromJSON(json: any): TitleDto {
    return TitleDtoFromJSONTyped(json, false);
}

export function TitleDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): TitleDto {
    if (json == null) {
        return json;
    }
    return {
        
        'title': json['title'],
    };
}

export function TitleDtoToJSON(value?: TitleDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'title': value['title'],
    };
}

