/* tslint:disable */
/* eslint-disable */
/**
 * ReportPro API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AutoDrillingStageView
 */
export interface AutoDrillingStageView {
    /**
     * 
     * @type {string}
     * @memberof AutoDrillingStageView
     */
    key?: AutoDrillingStageViewKeyEnum;
    /**
     * 
     * @type {string}
     * @memberof AutoDrillingStageView
     */
    value?: string;
}


/**
 * @export
 */
export const AutoDrillingStageViewKeyEnum = {
    PumpStart: 'PUMP_START',
    OscillationAngles: 'OSCILLATION_ANGLES',
    Rotor: 'ROTOR',
    ReamingRotor: 'REAMING_ROTOR',
    PositioningOffTheBottom: 'POSITIONING_OFF_THE_BOTTOM',
    Slide: 'SLIDE',
    ReamingSlide: 'REAMING_SLIDE',
    RecordingStaticMeasurement: 'RECORDING_STATIC_MEASUREMENT',
    SwitchingOffThePump: 'SWITCHING_OFF_THE_PUMP'
} as const;
export type AutoDrillingStageViewKeyEnum = typeof AutoDrillingStageViewKeyEnum[keyof typeof AutoDrillingStageViewKeyEnum];


/**
 * Check if a given object implements the AutoDrillingStageView interface.
 */
export function instanceOfAutoDrillingStageView(value: object): value is AutoDrillingStageView {
    return true;
}

export function AutoDrillingStageViewFromJSON(json: any): AutoDrillingStageView {
    return AutoDrillingStageViewFromJSONTyped(json, false);
}

export function AutoDrillingStageViewFromJSONTyped(json: any, ignoreDiscriminator: boolean): AutoDrillingStageView {
    if (json == null) {
        return json;
    }
    return {
        
        'key': json['key'] == null ? undefined : json['key'],
        'value': json['value'] == null ? undefined : json['value'],
    };
}

export function AutoDrillingStageViewToJSON(value?: AutoDrillingStageView | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'key': value['key'],
        'value': value['value'],
    };
}

