/* tslint:disable */
/* eslint-disable */
/**
 * ReportPro API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CreateCurveRequest } from './CreateCurveRequest';
import {
    CreateCurveRequestFromJSON,
    CreateCurveRequestFromJSONTyped,
    CreateCurveRequestToJSON,
} from './CreateCurveRequest';

/**
 * 
 * @export
 * @interface CreateTrackRequest
 */
export interface CreateTrackRequest {
    /**
     * 
     * @type {Array<CreateCurveRequest>}
     * @memberof CreateTrackRequest
     */
    curves: Array<CreateCurveRequest>;
}

/**
 * Check if a given object implements the CreateTrackRequest interface.
 */
export function instanceOfCreateTrackRequest(value: object): value is CreateTrackRequest {
    if (!('curves' in value) || value['curves'] === undefined) return false;
    return true;
}

export function CreateTrackRequestFromJSON(json: any): CreateTrackRequest {
    return CreateTrackRequestFromJSONTyped(json, false);
}

export function CreateTrackRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateTrackRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'curves': ((json['curves'] as Array<any>).map(CreateCurveRequestFromJSON)),
    };
}

export function CreateTrackRequestToJSON(value?: CreateTrackRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'curves': ((value['curves'] as Array<any>).map(CreateCurveRequestToJSON)),
    };
}

