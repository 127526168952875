/* tslint:disable */
/* eslint-disable */
/**
 * ReportPro API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AutoDrillingProgramFunction
 */
export interface AutoDrillingProgramFunction {
    /**
     * 
     * @type {string}
     * @memberof AutoDrillingProgramFunction
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof AutoDrillingProgramFunction
     */
    type: AutoDrillingProgramFunctionTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof AutoDrillingProgramFunction
     */
    title?: string;
    /**
     * 
     * @type {number}
     * @memberof AutoDrillingProgramFunction
     */
    itemOrder: number;
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof AutoDrillingProgramFunction
     */
    params: { [key: string]: number; };
}


/**
 * @export
 */
export const AutoDrillingProgramFunctionTypeEnum = {
    AntiCrashRotation: 'ANTI_CRASH_ROTATION',
    UpdatingNoloadParameters: 'UPDATING_NOLOAD_PARAMETERS',
    AdaptiveDrilling: 'ADAPTIVE_DRILLING',
    Oscillation: 'OSCILLATION',
    AutoHoldTf: 'AUTO_HOLD_TF',
    StaticMeasurementOutput: 'STATIC_MEASUREMENT_OUTPUT',
    DrillTest: 'DRILL_TEST',
    ShockTest: 'SHOCK_TEST',
    Weighing: 'WEIGHING',
    Damper: 'DAMPER'
} as const;
export type AutoDrillingProgramFunctionTypeEnum = typeof AutoDrillingProgramFunctionTypeEnum[keyof typeof AutoDrillingProgramFunctionTypeEnum];


/**
 * Check if a given object implements the AutoDrillingProgramFunction interface.
 */
export function instanceOfAutoDrillingProgramFunction(value: object): value is AutoDrillingProgramFunction {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('type' in value) || value['type'] === undefined) return false;
    if (!('itemOrder' in value) || value['itemOrder'] === undefined) return false;
    if (!('params' in value) || value['params'] === undefined) return false;
    return true;
}

export function AutoDrillingProgramFunctionFromJSON(json: any): AutoDrillingProgramFunction {
    return AutoDrillingProgramFunctionFromJSONTyped(json, false);
}

export function AutoDrillingProgramFunctionFromJSONTyped(json: any, ignoreDiscriminator: boolean): AutoDrillingProgramFunction {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'type': json['type'],
        'title': json['title'] == null ? undefined : json['title'],
        'itemOrder': json['itemOrder'],
        'params': json['params'],
    };
}

export function AutoDrillingProgramFunctionToJSON(value?: AutoDrillingProgramFunction | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'type': value['type'],
        'title': value['title'],
        'itemOrder': value['itemOrder'],
        'params': value['params'],
    };
}

