/* tslint:disable */
/* eslint-disable */
/**
 * ReportPro API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { AutoDrillingProgramStageInterval } from './AutoDrillingProgramStageInterval';
import {
    AutoDrillingProgramStageIntervalFromJSON,
    AutoDrillingProgramStageIntervalFromJSONTyped,
    AutoDrillingProgramStageIntervalToJSON,
} from './AutoDrillingProgramStageInterval';

/**
 * 
 * @export
 * @interface AutoDrillingProgramToUpdate
 */
export interface AutoDrillingProgramToUpdate {
    /**
     * 
     * @type {string}
     * @memberof AutoDrillingProgramToUpdate
     */
    wellConstruction?: string;
    /**
     * 
     * @type {number}
     * @memberof AutoDrillingProgramToUpdate
     */
    depthFrom?: number;
    /**
     * 
     * @type {number}
     * @memberof AutoDrillingProgramToUpdate
     */
    depthTo?: number;
    /**
     * 
     * @type {Array<AutoDrillingProgramStageInterval>}
     * @memberof AutoDrillingProgramToUpdate
     */
    stageIntervalsToUpdate?: Array<AutoDrillingProgramStageInterval>;
}

/**
 * Check if a given object implements the AutoDrillingProgramToUpdate interface.
 */
export function instanceOfAutoDrillingProgramToUpdate(value: object): value is AutoDrillingProgramToUpdate {
    return true;
}

export function AutoDrillingProgramToUpdateFromJSON(json: any): AutoDrillingProgramToUpdate {
    return AutoDrillingProgramToUpdateFromJSONTyped(json, false);
}

export function AutoDrillingProgramToUpdateFromJSONTyped(json: any, ignoreDiscriminator: boolean): AutoDrillingProgramToUpdate {
    if (json == null) {
        return json;
    }
    return {
        
        'wellConstruction': json['wellConstruction'] == null ? undefined : json['wellConstruction'],
        'depthFrom': json['depthFrom'] == null ? undefined : json['depthFrom'],
        'depthTo': json['depthTo'] == null ? undefined : json['depthTo'],
        'stageIntervalsToUpdate': json['stageIntervalsToUpdate'] == null ? undefined : ((json['stageIntervalsToUpdate'] as Array<any>).map(AutoDrillingProgramStageIntervalFromJSON)),
    };
}

export function AutoDrillingProgramToUpdateToJSON(value?: AutoDrillingProgramToUpdate | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'wellConstruction': value['wellConstruction'],
        'depthFrom': value['depthFrom'],
        'depthTo': value['depthTo'],
        'stageIntervalsToUpdate': value['stageIntervalsToUpdate'] == null ? undefined : ((value['stageIntervalsToUpdate'] as Array<any>).map(AutoDrillingProgramStageIntervalToJSON)),
    };
}

