/* tslint:disable */
/* eslint-disable */
/**
 * ReportPro API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MnemonicAggregateData
 */
export interface MnemonicAggregateData {
    /**
     * 
     * @type {string}
     * @memberof MnemonicAggregateData
     */
    mnemonic?: string;
    /**
     * 
     * @type {number}
     * @memberof MnemonicAggregateData
     */
    minValue?: number;
    /**
     * 
     * @type {number}
     * @memberof MnemonicAggregateData
     */
    maxValue?: number;
    /**
     * 
     * @type {number}
     * @memberof MnemonicAggregateData
     */
    averageValue?: number;
}

/**
 * Check if a given object implements the MnemonicAggregateData interface.
 */
export function instanceOfMnemonicAggregateData(value: object): value is MnemonicAggregateData {
    return true;
}

export function MnemonicAggregateDataFromJSON(json: any): MnemonicAggregateData {
    return MnemonicAggregateDataFromJSONTyped(json, false);
}

export function MnemonicAggregateDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): MnemonicAggregateData {
    if (json == null) {
        return json;
    }
    return {
        
        'mnemonic': json['mnemonic'] == null ? undefined : json['mnemonic'],
        'minValue': json['minValue'] == null ? undefined : json['minValue'],
        'maxValue': json['maxValue'] == null ? undefined : json['maxValue'],
        'averageValue': json['averageValue'] == null ? undefined : json['averageValue'],
    };
}

export function MnemonicAggregateDataToJSON(value?: MnemonicAggregateData | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'mnemonic': value['mnemonic'],
        'minValue': value['minValue'],
        'maxValue': value['maxValue'],
        'averageValue': value['averageValue'],
    };
}

