/* tslint:disable */
/* eslint-disable */
/**
 * ReportPro API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CurveMnemonicUnitResponse } from './CurveMnemonicUnitResponse';
import {
    CurveMnemonicUnitResponseFromJSON,
    CurveMnemonicUnitResponseFromJSONTyped,
    CurveMnemonicUnitResponseToJSON,
} from './CurveMnemonicUnitResponse';

/**
 * 
 * @export
 * @interface CurveMnemonicResponse
 */
export interface CurveMnemonicResponse {
    /**
     * 
     * @type {string}
     * @memberof CurveMnemonicResponse
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof CurveMnemonicResponse
     */
    mnemonicLong?: string;
    /**
     * 
     * @type {string}
     * @memberof CurveMnemonicResponse
     */
    mnemonicShort?: string;
    /**
     * 
     * @type {string}
     * @memberof CurveMnemonicResponse
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof CurveMnemonicResponse
     */
    description?: string;
    /**
     * 
     * @type {CurveMnemonicUnitResponse}
     * @memberof CurveMnemonicResponse
     */
    defaultUnit?: CurveMnemonicUnitResponse;
    /**
     * 
     * @type {string}
     * @memberof CurveMnemonicResponse
     */
    baseUnitId?: string;
}

/**
 * Check if a given object implements the CurveMnemonicResponse interface.
 */
export function instanceOfCurveMnemonicResponse(value: object): value is CurveMnemonicResponse {
    return true;
}

export function CurveMnemonicResponseFromJSON(json: any): CurveMnemonicResponse {
    return CurveMnemonicResponseFromJSONTyped(json, false);
}

export function CurveMnemonicResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CurveMnemonicResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'mnemonicLong': json['mnemonicLong'] == null ? undefined : json['mnemonicLong'],
        'mnemonicShort': json['mnemonicShort'] == null ? undefined : json['mnemonicShort'],
        'name': json['name'] == null ? undefined : json['name'],
        'description': json['description'] == null ? undefined : json['description'],
        'defaultUnit': json['defaultUnit'] == null ? undefined : CurveMnemonicUnitResponseFromJSON(json['defaultUnit']),
        'baseUnitId': json['baseUnitId'] == null ? undefined : json['baseUnitId'],
    };
}

export function CurveMnemonicResponseToJSON(value?: CurveMnemonicResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'mnemonicLong': value['mnemonicLong'],
        'mnemonicShort': value['mnemonicShort'],
        'name': value['name'],
        'description': value['description'],
        'defaultUnit': CurveMnemonicUnitResponseToJSON(value['defaultUnit']),
        'baseUnitId': value['baseUnitId'],
    };
}

