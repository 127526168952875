/* tslint:disable */
/* eslint-disable */
/**
 * ReportPro API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DataBorderRequest
 */
export interface DataBorderRequest {
    /**
     * 
     * @type {string}
     * @memberof DataBorderRequest
     */
    wellboreId?: string;
    /**
     * 
     * @type {string}
     * @memberof DataBorderRequest
     */
    logFormatId?: string;
    /**
     * 
     * @type {string}
     * @memberof DataBorderRequest
     */
    zoneId: string;
}

/**
 * Check if a given object implements the DataBorderRequest interface.
 */
export function instanceOfDataBorderRequest(value: object): value is DataBorderRequest {
    if (!('zoneId' in value) || value['zoneId'] === undefined) return false;
    return true;
}

export function DataBorderRequestFromJSON(json: any): DataBorderRequest {
    return DataBorderRequestFromJSONTyped(json, false);
}

export function DataBorderRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): DataBorderRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'wellboreId': json['wellboreId'] == null ? undefined : json['wellboreId'],
        'logFormatId': json['logFormatId'] == null ? undefined : json['logFormatId'],
        'zoneId': json['zoneId'],
    };
}

export function DataBorderRequestToJSON(value?: DataBorderRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'wellboreId': value['wellboreId'],
        'logFormatId': value['logFormatId'],
        'zoneId': value['zoneId'],
    };
}

