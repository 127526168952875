/* tslint:disable */
/* eslint-disable */
/**
 * ReportPro API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LasFileDTO
 */
export interface LasFileDTO {
    /**
     * 
     * @type {string}
     * @memberof LasFileDTO
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof LasFileDTO
     */
    fileName: string;
    /**
     * 
     * @type {number}
     * @memberof LasFileDTO
     */
    wellId: number;
    /**
     * 
     * @type {string}
     * @memberof LasFileDTO
     */
    lasFileType: LasFileDTOLasFileTypeEnum;
}


/**
 * @export
 */
export const LasFileDTOLasFileTypeEnum = {
    Depth: 'DEPTH',
    Time: 'TIME'
} as const;
export type LasFileDTOLasFileTypeEnum = typeof LasFileDTOLasFileTypeEnum[keyof typeof LasFileDTOLasFileTypeEnum];


/**
 * Check if a given object implements the LasFileDTO interface.
 */
export function instanceOfLasFileDTO(value: object): value is LasFileDTO {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('fileName' in value) || value['fileName'] === undefined) return false;
    if (!('wellId' in value) || value['wellId'] === undefined) return false;
    if (!('lasFileType' in value) || value['lasFileType'] === undefined) return false;
    return true;
}

export function LasFileDTOFromJSON(json: any): LasFileDTO {
    return LasFileDTOFromJSONTyped(json, false);
}

export function LasFileDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): LasFileDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'fileName': json['fileName'],
        'wellId': json['wellId'],
        'lasFileType': json['lasFileType'],
    };
}

export function LasFileDTOToJSON(value?: LasFileDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'fileName': value['fileName'],
        'wellId': value['wellId'],
        'lasFileType': value['lasFileType'],
    };
}

