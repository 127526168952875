/* tslint:disable */
/* eslint-disable */
/**
 * ReportPro API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DrillingRigEquipment
 */
export interface DrillingRigEquipment {
    /**
     * 
     * @type {number}
     * @memberof DrillingRigEquipment
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof DrillingRigEquipment
     */
    type: string;
    /**
     * 
     * @type {string}
     * @memberof DrillingRigEquipment
     */
    model: string;
    /**
     * 
     * @type {string}
     * @memberof DrillingRigEquipment
     */
    factoryNumber: string;
    /**
     * 
     * @type {string}
     * @memberof DrillingRigEquipment
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof DrillingRigEquipment
     */
    manufacturer?: string;
    /**
     * 
     * @type {number}
     * @memberof DrillingRigEquipment
     */
    creationYear?: number;
    /**
     * 
     * @type {Date}
     * @memberof DrillingRigEquipment
     */
    plannedRepair?: Date;
    /**
     * 
     * @type {number}
     * @memberof DrillingRigEquipment
     */
    permittedServiceLife?: number;
    /**
     * 
     * @type {number}
     * @memberof DrillingRigEquipment
     */
    drillingRigId?: number;
}

/**
 * Check if a given object implements the DrillingRigEquipment interface.
 */
export function instanceOfDrillingRigEquipment(value: object): value is DrillingRigEquipment {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('type' in value) || value['type'] === undefined) return false;
    if (!('model' in value) || value['model'] === undefined) return false;
    if (!('factoryNumber' in value) || value['factoryNumber'] === undefined) return false;
    return true;
}

export function DrillingRigEquipmentFromJSON(json: any): DrillingRigEquipment {
    return DrillingRigEquipmentFromJSONTyped(json, false);
}

export function DrillingRigEquipmentFromJSONTyped(json: any, ignoreDiscriminator: boolean): DrillingRigEquipment {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'type': json['type'],
        'model': json['model'],
        'factoryNumber': json['factoryNumber'],
        'title': json['title'] == null ? undefined : json['title'],
        'manufacturer': json['manufacturer'] == null ? undefined : json['manufacturer'],
        'creationYear': json['creationYear'] == null ? undefined : json['creationYear'],
        'plannedRepair': json['plannedRepair'] == null ? undefined : (new Date(json['plannedRepair'])),
        'permittedServiceLife': json['permittedServiceLife'] == null ? undefined : json['permittedServiceLife'],
        'drillingRigId': json['drillingRigId'] == null ? undefined : json['drillingRigId'],
    };
}

export function DrillingRigEquipmentToJSON(value?: DrillingRigEquipment | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'type': value['type'],
        'model': value['model'],
        'factoryNumber': value['factoryNumber'],
        'title': value['title'],
        'manufacturer': value['manufacturer'],
        'creationYear': value['creationYear'],
        'plannedRepair': value['plannedRepair'] == null ? undefined : ((value['plannedRepair']).toISOString().substring(0,10)),
        'permittedServiceLife': value['permittedServiceLife'],
        'drillingRigId': value['drillingRigId'],
    };
}

