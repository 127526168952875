/* tslint:disable */
/* eslint-disable */
/**
 * ReportPro API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  DrillingRigKnbkInfoResponse,
  DrillingRigOperationInfoResponse,
  DrillingRigV2WithWellInfoResponse,
  ErrorResponse,
  ValidationErrorResponse,
} from '../models/index';
import {
    DrillingRigKnbkInfoResponseFromJSON,
    DrillingRigKnbkInfoResponseToJSON,
    DrillingRigOperationInfoResponseFromJSON,
    DrillingRigOperationInfoResponseToJSON,
    DrillingRigV2WithWellInfoResponseFromJSON,
    DrillingRigV2WithWellInfoResponseToJSON,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    ValidationErrorResponseFromJSON,
    ValidationErrorResponseToJSON,
} from '../models/index';

export interface GetDrillingRigByWellIdRequest {
    wellId: number;
}

export interface GetDrillingRigKnbkInfoByWellIdRequest {
    wellId: number;
}

export interface GetDrillingRigOperationInfoByWellIdRequest {
    wellId: number;
}

/**
 * 
 */
export class DrillingRigByWellControllerApi extends runtime.BaseAPI {

    /**
     */
    async getDrillingRigByWellIdRaw(requestParameters: GetDrillingRigByWellIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DrillingRigV2WithWellInfoResponse>> {
        if (requestParameters['wellId'] == null) {
            throw new runtime.RequiredError(
                'wellId',
                'Required parameter "wellId" was null or undefined when calling getDrillingRigByWellId().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer Authentication", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/wells/{wellId}/drilling-rig`.replace(`{${"wellId"}}`, encodeURIComponent(String(requestParameters['wellId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DrillingRigV2WithWellInfoResponseFromJSON(jsonValue));
    }

    /**
     */
    async getDrillingRigByWellId(requestParameters: GetDrillingRigByWellIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DrillingRigV2WithWellInfoResponse> {
        const response = await this.getDrillingRigByWellIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * This endpoint finds a current KNBK data by well id
     * Get drilling rig current knbk status
     */
    async getDrillingRigKnbkInfoByWellIdRaw(requestParameters: GetDrillingRigKnbkInfoByWellIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DrillingRigKnbkInfoResponse>> {
        if (requestParameters['wellId'] == null) {
            throw new runtime.RequiredError(
                'wellId',
                'Required parameter "wellId" was null or undefined when calling getDrillingRigKnbkInfoByWellId().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer Authentication", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/wells/{wellId}/drilling-rig/knbk-status`.replace(`{${"wellId"}}`, encodeURIComponent(String(requestParameters['wellId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DrillingRigKnbkInfoResponseFromJSON(jsonValue));
    }

    /**
     * This endpoint finds a current KNBK data by well id
     * Get drilling rig current knbk status
     */
    async getDrillingRigKnbkInfoByWellId(requestParameters: GetDrillingRigKnbkInfoByWellIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DrillingRigKnbkInfoResponse | null | undefined > {
        const response = await this.getDrillingRigKnbkInfoByWellIdRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * This endpoint finds a current operation data by well id
     * Get drilling rig current operation status
     */
    async getDrillingRigOperationInfoByWellIdRaw(requestParameters: GetDrillingRigOperationInfoByWellIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DrillingRigOperationInfoResponse>> {
        if (requestParameters['wellId'] == null) {
            throw new runtime.RequiredError(
                'wellId',
                'Required parameter "wellId" was null or undefined when calling getDrillingRigOperationInfoByWellId().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer Authentication", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/wells/{wellId}/drilling-rig/status`.replace(`{${"wellId"}}`, encodeURIComponent(String(requestParameters['wellId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DrillingRigOperationInfoResponseFromJSON(jsonValue));
    }

    /**
     * This endpoint finds a current operation data by well id
     * Get drilling rig current operation status
     */
    async getDrillingRigOperationInfoByWellId(requestParameters: GetDrillingRigOperationInfoByWellIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DrillingRigOperationInfoResponse | null | undefined > {
        const response = await this.getDrillingRigOperationInfoByWellIdRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

}
