/* tslint:disable */
/* eslint-disable */
/**
 * ReportPro API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CurveMnemonicResponse } from './CurveMnemonicResponse';
import {
    CurveMnemonicResponseFromJSON,
    CurveMnemonicResponseFromJSONTyped,
    CurveMnemonicResponseToJSON,
} from './CurveMnemonicResponse';
import type { CurveMnemonicUnitResponse } from './CurveMnemonicUnitResponse';
import {
    CurveMnemonicUnitResponseFromJSON,
    CurveMnemonicUnitResponseFromJSONTyped,
    CurveMnemonicUnitResponseToJSON,
} from './CurveMnemonicUnitResponse';
import type { CurveTypeResponse } from './CurveTypeResponse';
import {
    CurveTypeResponseFromJSON,
    CurveTypeResponseFromJSONTyped,
    CurveTypeResponseToJSON,
} from './CurveTypeResponse';

/**
 * 
 * @export
 * @interface CurveResponse
 */
export interface CurveResponse {
    /**
     * 
     * @type {string}
     * @memberof CurveResponse
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof CurveResponse
     */
    name?: string;
    /**
     * 
     * @type {CurveMnemonicResponse}
     * @memberof CurveResponse
     */
    mnemonic?: CurveMnemonicResponse;
    /**
     * 
     * @type {CurveMnemonicUnitResponse}
     * @memberof CurveResponse
     */
    unit?: CurveMnemonicUnitResponse;
    /**
     * 
     * @type {string}
     * @memberof CurveResponse
     */
    lineColor?: string;
    /**
     * 
     * @type {number}
     * @memberof CurveResponse
     */
    lineThickness?: number;
    /**
     * 
     * @type {number}
     * @memberof CurveResponse
     */
    limitMin?: number;
    /**
     * 
     * @type {number}
     * @memberof CurveResponse
     */
    limitMax?: number;
    /**
     * 
     * @type {number}
     * @memberof CurveResponse
     */
    curveLimitMin?: number;
    /**
     * 
     * @type {number}
     * @memberof CurveResponse
     */
    curveLimitMax?: number;
    /**
     * 
     * @type {string}
     * @memberof CurveResponse
     */
    curveLimitColor?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CurveResponse
     */
    limitMinAuto?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CurveResponse
     */
    limitMaxAuto?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CurveResponse
     */
    extLimit?: boolean;
    /**
     * 
     * @type {CurveTypeResponse}
     * @memberof CurveResponse
     */
    lineType?: CurveTypeResponse;
}

/**
 * Check if a given object implements the CurveResponse interface.
 */
export function instanceOfCurveResponse(value: object): value is CurveResponse {
    return true;
}

export function CurveResponseFromJSON(json: any): CurveResponse {
    return CurveResponseFromJSONTyped(json, false);
}

export function CurveResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CurveResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'name': json['name'] == null ? undefined : json['name'],
        'mnemonic': json['mnemonic'] == null ? undefined : CurveMnemonicResponseFromJSON(json['mnemonic']),
        'unit': json['unit'] == null ? undefined : CurveMnemonicUnitResponseFromJSON(json['unit']),
        'lineColor': json['lineColor'] == null ? undefined : json['lineColor'],
        'lineThickness': json['lineThickness'] == null ? undefined : json['lineThickness'],
        'limitMin': json['limitMin'] == null ? undefined : json['limitMin'],
        'limitMax': json['limitMax'] == null ? undefined : json['limitMax'],
        'curveLimitMin': json['curveLimitMin'] == null ? undefined : json['curveLimitMin'],
        'curveLimitMax': json['curveLimitMax'] == null ? undefined : json['curveLimitMax'],
        'curveLimitColor': json['curveLimitColor'] == null ? undefined : json['curveLimitColor'],
        'limitMinAuto': json['limitMinAuto'] == null ? undefined : json['limitMinAuto'],
        'limitMaxAuto': json['limitMaxAuto'] == null ? undefined : json['limitMaxAuto'],
        'extLimit': json['extLimit'] == null ? undefined : json['extLimit'],
        'lineType': json['lineType'] == null ? undefined : CurveTypeResponseFromJSON(json['lineType']),
    };
}

export function CurveResponseToJSON(value?: CurveResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'name': value['name'],
        'mnemonic': CurveMnemonicResponseToJSON(value['mnemonic']),
        'unit': CurveMnemonicUnitResponseToJSON(value['unit']),
        'lineColor': value['lineColor'],
        'lineThickness': value['lineThickness'],
        'limitMin': value['limitMin'],
        'limitMax': value['limitMax'],
        'curveLimitMin': value['curveLimitMin'],
        'curveLimitMax': value['curveLimitMax'],
        'curveLimitColor': value['curveLimitColor'],
        'limitMinAuto': value['limitMinAuto'],
        'limitMaxAuto': value['limitMaxAuto'],
        'extLimit': value['extLimit'],
        'lineType': CurveTypeResponseToJSON(value['lineType']),
    };
}

