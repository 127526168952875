/* tslint:disable */
/* eslint-disable */
/**
 * ReportPro API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AsbConnectionResponse,
  AsbWellMapDto,
  AsbWellboreDTO,
  ErrorResponse,
  MonitoringConnectionResponse,
  ValidationErrorResponse,
  WitsmlConnectionDto,
  WitsmlConnectionResponse,
  WitsmlServerSettings,
  WitsmlTypeDto,
  WitsmlWellboreDto,
  WitsmlWellboreMappingDto,
} from '../models/index';
import {
    AsbConnectionResponseFromJSON,
    AsbConnectionResponseToJSON,
    AsbWellMapDtoFromJSON,
    AsbWellMapDtoToJSON,
    AsbWellboreDTOFromJSON,
    AsbWellboreDTOToJSON,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    MonitoringConnectionResponseFromJSON,
    MonitoringConnectionResponseToJSON,
    ValidationErrorResponseFromJSON,
    ValidationErrorResponseToJSON,
    WitsmlConnectionDtoFromJSON,
    WitsmlConnectionDtoToJSON,
    WitsmlConnectionResponseFromJSON,
    WitsmlConnectionResponseToJSON,
    WitsmlServerSettingsFromJSON,
    WitsmlServerSettingsToJSON,
    WitsmlTypeDtoFromJSON,
    WitsmlTypeDtoToJSON,
    WitsmlWellboreDtoFromJSON,
    WitsmlWellboreDtoToJSON,
    WitsmlWellboreMappingDtoFromJSON,
    WitsmlWellboreMappingDtoToJSON,
} from '../models/index';

export interface DeleteConnectionRequest {
    id: string;
}

export interface FindActiveConnectionRequest {
    wellId: number;
}

export interface FindAkbConnectionInfoRequest {
    id: string;
}

export interface FindWellBoresForAsbConnectionDataRequest {
    asbWellMapDto: AsbWellMapDto;
}

export interface FindWellBoresForExistingConnectionRequest {
    connectionId: string;
}

export interface FindWellBoresForWitsmlConnectionDataRequest {
    witsmlServerSettings: WitsmlServerSettings;
}

export interface FindWitsmlConnectionInfoRequest {
    id: string;
}

export interface GetConnectionWithWellboresRequest {
    connectionId: string;
}

export interface SaveRequest {
    witsmlConnectionDto: WitsmlConnectionDto;
}

export interface Save1Request {
    asbWellMapDto: AsbWellMapDto;
}

export interface StopConnectionRequest {
    connectionId: string;
}

export interface UpdateWellboresForWitsmlConnectionRequest {
    connectionId: string;
    witsmlWellboreMappingDto: Array<WitsmlWellboreMappingDto>;
}

/**
 * 
 */
export class MonitoringConnectionApi extends runtime.BaseAPI {

    /**
     * Этот эндпоинт удаляет выбранное подключение
     * Удалить информацию о соединении
     */
    async deleteConnectionRaw(requestParameters: DeleteConnectionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling deleteConnection().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['id'] != null) {
            queryParameters['id'] = requestParameters['id'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer Authentication", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/monitoring/connection`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Этот эндпоинт удаляет выбранное подключение
     * Удалить информацию о соединении
     */
    async deleteConnection(requestParameters: DeleteConnectionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteConnectionRaw(requestParameters, initOverrides);
    }

    /**
     * Этот эндпоинт возвращает существующие подключения для скажины
     * Получить список текущих соединений
     */
    async findActiveConnectionRaw(requestParameters: FindActiveConnectionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<MonitoringConnectionResponse>>> {
        if (requestParameters['wellId'] == null) {
            throw new runtime.RequiredError(
                'wellId',
                'Required parameter "wellId" was null or undefined when calling findActiveConnection().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['wellId'] != null) {
            queryParameters['wellId'] = requestParameters['wellId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer Authentication", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/monitoring/connection`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MonitoringConnectionResponseFromJSON));
    }

    /**
     * Этот эндпоинт возвращает существующие подключения для скажины
     * Получить список текущих соединений
     */
    async findActiveConnection(requestParameters: FindActiveConnectionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<MonitoringConnectionResponse>> {
        const response = await this.findActiveConnectionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Этот эндпоинт возвращает существующие подключения для скажины
     * Получить информацию по текущему соединению
     */
    async findAkbConnectionInfoRaw(requestParameters: FindAkbConnectionInfoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AsbConnectionResponse>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling findAkbConnectionInfo().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer Authentication", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/monitoring/connection/akb/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AsbConnectionResponseFromJSON(jsonValue));
    }

    /**
     * Этот эндпоинт возвращает существующие подключения для скажины
     * Получить информацию по текущему соединению
     */
    async findAkbConnectionInfo(requestParameters: FindAkbConnectionInfoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AsbConnectionResponse> {
        const response = await this.findAkbConnectionInfoRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * This endpoint retrieves list of wellbores grouped by well name
     * Get wellbores
     */
    async findWellBoresForAsbConnectionDataRaw(requestParameters: FindWellBoresForAsbConnectionDataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<AsbWellboreDTO>>> {
        if (requestParameters['asbWellMapDto'] == null) {
            throw new runtime.RequiredError(
                'asbWellMapDto',
                'Required parameter "asbWellMapDto" was null or undefined when calling findWellBoresForAsbConnectionData().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer Authentication", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/monitoring/connection/akb/wellbores`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AsbWellMapDtoToJSON(requestParameters['asbWellMapDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AsbWellboreDTOFromJSON));
    }

    /**
     * This endpoint retrieves list of wellbores grouped by well name
     * Get wellbores
     */
    async findWellBoresForAsbConnectionData(requestParameters: FindWellBoresForAsbConnectionDataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<AsbWellboreDTO>> {
        const response = await this.findWellBoresForAsbConnectionDataRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Возвращает актуальные стволы на сервере WITSML для существующего подключения
     * Get wellbores
     */
    async findWellBoresForExistingConnectionRaw(requestParameters: FindWellBoresForExistingConnectionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<WitsmlWellboreDto>>> {
        if (requestParameters['connectionId'] == null) {
            throw new runtime.RequiredError(
                'connectionId',
                'Required parameter "connectionId" was null or undefined when calling findWellBoresForExistingConnection().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['connectionId'] != null) {
            queryParameters['connectionId'] = requestParameters['connectionId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer Authentication", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/monitoring/connection/witsml/wellbores`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(WitsmlWellboreDtoFromJSON));
    }

    /**
     * Возвращает актуальные стволы на сервере WITSML для существующего подключения
     * Get wellbores
     */
    async findWellBoresForExistingConnection(requestParameters: FindWellBoresForExistingConnectionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<WitsmlWellboreDto>> {
        const response = await this.findWellBoresForExistingConnectionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * This endpoint retrieves list of a source wellbores
     * Get wellbores
     */
    async findWellBoresForWitsmlConnectionDataRaw(requestParameters: FindWellBoresForWitsmlConnectionDataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<WitsmlWellboreDto>>> {
        if (requestParameters['witsmlServerSettings'] == null) {
            throw new runtime.RequiredError(
                'witsmlServerSettings',
                'Required parameter "witsmlServerSettings" was null or undefined when calling findWellBoresForWitsmlConnectionData().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer Authentication", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/monitoring/connection/witsml/wellbores`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WitsmlServerSettingsToJSON(requestParameters['witsmlServerSettings']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(WitsmlWellboreDtoFromJSON));
    }

    /**
     * This endpoint retrieves list of a source wellbores
     * Get wellbores
     */
    async findWellBoresForWitsmlConnectionData(requestParameters: FindWellBoresForWitsmlConnectionDataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<WitsmlWellboreDto>> {
        const response = await this.findWellBoresForWitsmlConnectionDataRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Этот эндпоинт возвращает существующие подключения для скажины
     * Получить информацию по текущему соединению
     */
    async findWitsmlConnectionInfoRaw(requestParameters: FindWitsmlConnectionInfoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WitsmlConnectionResponse>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling findWitsmlConnectionInfo().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer Authentication", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/monitoring/connection/witsml/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WitsmlConnectionResponseFromJSON(jsonValue));
    }

    /**
     * Этот эндпоинт возвращает существующие подключения для скажины
     * Получить информацию по текущему соединению
     */
    async findWitsmlConnectionInfo(requestParameters: FindWitsmlConnectionInfoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WitsmlConnectionResponse> {
        const response = await this.findWitsmlConnectionInfoRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Получить соединение с контейнерами
     */
    async getConnectionWithWellboresRaw(requestParameters: GetConnectionWithWellboresRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MonitoringConnectionResponse>> {
        if (requestParameters['connectionId'] == null) {
            throw new runtime.RequiredError(
                'connectionId',
                'Required parameter "connectionId" was null or undefined when calling getConnectionWithWellbores().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer Authentication", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/monitoring/connection/{connectionId}`.replace(`{${"connectionId"}}`, encodeURIComponent(String(requestParameters['connectionId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MonitoringConnectionResponseFromJSON(jsonValue));
    }

    /**
     * Получить соединение с контейнерами
     */
    async getConnectionWithWellbores(requestParameters: GetConnectionWithWellboresRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MonitoringConnectionResponse> {
        const response = await this.getConnectionWithWellboresRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Этот эндпоинт возвращает существующие типы WITSML
     * Получить информацию о типах WITSML
     */
    async getWitsmlTypesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<WitsmlTypeDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer Authentication", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/monitoring/connection/witsml/types`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(WitsmlTypeDtoFromJSON));
    }

    /**
     * Этот эндпоинт возвращает существующие типы WITSML
     * Получить информацию о типах WITSML
     */
    async getWitsmlTypes(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<WitsmlTypeDto>> {
        const response = await this.getWitsmlTypesRaw(initOverrides);
        return await response.value();
    }

    /**
     * Create witsml connection
     */
    async saveRaw(requestParameters: SaveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['witsmlConnectionDto'] == null) {
            throw new runtime.RequiredError(
                'witsmlConnectionDto',
                'Required parameter "witsmlConnectionDto" was null or undefined when calling save().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer Authentication", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/monitoring/connection/witsml/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WitsmlConnectionDtoToJSON(requestParameters['witsmlConnectionDto']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Create witsml connection
     */
    async save(requestParameters: SaveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.saveRaw(requestParameters, initOverrides);
    }

    /**
     * Create asb connection
     */
    async save1Raw(requestParameters: Save1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['asbWellMapDto'] == null) {
            throw new runtime.RequiredError(
                'asbWellMapDto',
                'Required parameter "asbWellMapDto" was null or undefined when calling save1().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer Authentication", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/monitoring/connection/akb/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AsbWellMapDtoToJSON(requestParameters['asbWellMapDto']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Create asb connection
     */
    async save1(requestParameters: Save1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.save1Raw(requestParameters, initOverrides);
    }

    /**
     * Остановить загрузку данных для подключения
     */
    async stopConnectionRaw(requestParameters: StopConnectionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['connectionId'] == null) {
            throw new runtime.RequiredError(
                'connectionId',
                'Required parameter "connectionId" was null or undefined when calling stopConnection().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['connectionId'] != null) {
            queryParameters['connectionId'] = requestParameters['connectionId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer Authentication", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/monitoring/connection/{connectionId}/stop`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Остановить загрузку данных для подключения
     */
    async stopConnection(requestParameters: StopConnectionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.stopConnectionRaw(requestParameters, initOverrides);
    }

    /**
     * Обновить подключение новым состоянием стволов
     */
    async updateWellboresForWitsmlConnectionRaw(requestParameters: UpdateWellboresForWitsmlConnectionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['connectionId'] == null) {
            throw new runtime.RequiredError(
                'connectionId',
                'Required parameter "connectionId" was null or undefined when calling updateWellboresForWitsmlConnection().'
            );
        }

        if (requestParameters['witsmlWellboreMappingDto'] == null) {
            throw new runtime.RequiredError(
                'witsmlWellboreMappingDto',
                'Required parameter "witsmlWellboreMappingDto" was null or undefined when calling updateWellboresForWitsmlConnection().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer Authentication", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/monitoring/connection/witsml/{connectionId}/wellbores`.replace(`{${"connectionId"}}`, encodeURIComponent(String(requestParameters['connectionId']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['witsmlWellboreMappingDto']!.map(WitsmlWellboreMappingDtoToJSON),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Обновить подключение новым состоянием стволов
     */
    async updateWellboresForWitsmlConnection(requestParameters: UpdateWellboresForWitsmlConnectionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateWellboresForWitsmlConnectionRaw(requestParameters, initOverrides);
    }

}
