/* tslint:disable */
/* eslint-disable */
/**
 * ReportPro API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import { DrillingRigContentFromJSONTyped } from './DrillingRigContent';
import { SchedulerDeviceContentFromJSONTyped } from './SchedulerDeviceContent';
import { SchedulerFieldContentFromJSONTyped } from './SchedulerFieldContent';
import { SchedulerPadContentFromJSONTyped } from './SchedulerPadContent';
import { SchedulerSimpleDateContentFromJSONTyped } from './SchedulerSimpleDateContent';
import { SchedulerTechnicalMaintenanceContentFromJSONTyped } from './SchedulerTechnicalMaintenanceContent';
import { SchedulerWellContentFromJSONTyped } from './SchedulerWellContent';
/**
 * 
 * @export
 * @interface SchedulerItemContent
 */
export interface SchedulerItemContent {
    /**
     * 
     * @type {string}
     * @memberof SchedulerItemContent
     */
    type: string;
}

/**
 * Check if a given object implements the SchedulerItemContent interface.
 */
export function instanceOfSchedulerItemContent(value: object): value is SchedulerItemContent {
    if (!('type' in value) || value['type'] === undefined) return false;
    return true;
}

export function SchedulerItemContentFromJSON(json: any): SchedulerItemContent {
    return SchedulerItemContentFromJSONTyped(json, false);
}

export function SchedulerItemContentFromJSONTyped(json: any, ignoreDiscriminator: boolean): SchedulerItemContent {
    if (json == null) {
        return json;
    }
    if (!ignoreDiscriminator) {
        if (json['type'] === 'DrillingRigContent') {
            return DrillingRigContentFromJSONTyped(json, true);
        }
        if (json['type'] === 'SchedulerDeviceContent') {
            return SchedulerDeviceContentFromJSONTyped(json, true);
        }
        if (json['type'] === 'SchedulerFieldContent') {
            return SchedulerFieldContentFromJSONTyped(json, true);
        }
        if (json['type'] === 'SchedulerPadContent') {
            return SchedulerPadContentFromJSONTyped(json, true);
        }
        if (json['type'] === 'SchedulerSimpleDateContent') {
            return SchedulerSimpleDateContentFromJSONTyped(json, true);
        }
        if (json['type'] === 'SchedulerTechnicalMaintenanceContent') {
            return SchedulerTechnicalMaintenanceContentFromJSONTyped(json, true);
        }
        if (json['type'] === 'SchedulerWellContent') {
            return SchedulerWellContentFromJSONTyped(json, true);
        }
    }
    return {
        
        'type': json['type'],
    };
}

export function SchedulerItemContentToJSON(value?: SchedulerItemContent | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'type': value['type'],
    };
}

