import {useCallback, useEffect, useMemo, useState} from 'react';
import {FilterConfig, SortConfig, TableConfig} from '@ui/NewTable/types';
import {
    DEFAULT_ROWS_PER_PAGE,
    getSortConfig,
    getTableName,
    PageDataStore,
    USER_SETTINGS_TABLE_KEY,
    useUserSettings,
} from 'utils';
import {prepareNewTableData, prepareNewTableScheme} from 'utils/functions/table';
import {useElementSize} from 'utils/hooks/useElementSize';
import usePageSchema, {PageSchema} from 'utils/hooks/usePageSchema';
import {TablePaginationConfig} from 'utils/hooks/useNewPageData';
import {TableDataService} from '@services/Table/TableDataService';
import {useLocation} from 'react-router-dom';

export const useTablePage = ({
    pageId,
    pageStore,
    service,
    onUpdated,
    updatePageData,
    schemaGetter,
    initialRowsPerPage = DEFAULT_ROWS_PER_PAGE,
}: {
    pageId: string;
    pageStore: PageDataStore<any> | undefined;
    service?: TableDataService | any;
    onUpdated?: () => void;
    updatePageData: (
        pagination: TablePaginationConfig,
        sort: SortConfig,
        filters: FilterConfig,
    ) => void;
    schemaGetter?: () => Promise<PageSchema>;
    initialRowsPerPage?: number;
}) => {
    const {pathname} = useLocation();
    const tableName = useMemo(() => getTableName(pageId, pathname), [pageId, pathname]);
    const {settings} = useUserSettings(USER_SETTINGS_TABLE_KEY);
    const tableSettings = useMemo(() => tableName && settings?.[tableName], [settings, tableName]);

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(initialRowsPerPage);
    const [sortConfig, setSortConfig] = useState<SortConfig>({});
    const [filterConfig, setFilterConfig] = useState<FilterConfig>({});
    const [updateDictionaries, setUpdateDictionaries] = useState(0);
    const [ref] = useElementSize<HTMLDivElement>();

    const schema = usePageSchema({id: pageId, schemaGetter, updateDictionaries});

    const rows = useMemo(() => {
        return pageStore?.data && schema ? prepareNewTableData(pageStore.data) : [];
    }, [schema, pageStore]);

    const columns = useMemo(() => (schema ? prepareNewTableScheme(schema) : {}), [schema]);

    const tableConfig = useMemo(
        () => (schema && schema?.config ? schema.config : ({} as TableConfig)),
        [schema],
    );

    const requiredConfig = useMemo(
        () => (schema && schema?.required ? schema.required : []),
        [schema],
    );

    useEffect(() => {
        if (!tableName) return;
        setSortConfig({});
        setFilterConfig({});
        setRowsPerPage(DEFAULT_ROWS_PER_PAGE);
    }, [tableName]);

    useEffect(() => {
        if (!tableSettings) return;
        const sortConfig = getSortConfig(tableSettings?.sortSettings);
        sortConfig && Object.values(sortConfig).length > 0 && setSortConfig(sortConfig);
        tableSettings.pageSize && setRowsPerPage(tableSettings.pageSize);
    }, [tableSettings]);

    const deleteEntityHandle = useCallback(
        async (ids: number[]) => {
            if (pageId) {
                if (ids.length === 1) {
                    await service.deleteData(pageId, Number(ids[0]));
                } else {
                    await service.bulkDeleteData(pageId, ids);
                }
                onUpdated?.();
            }
        },
        [pageId, onUpdated, service],
    );

    const handleUpdate = useCallback(() => {
        const paginationConfig = {
            page,
            pageSize: rowsPerPage,
        };
        const defaultSort = schema?.config?.sort.defaultSort || {};
        const finalSortConfig = !!Object.values(sortConfig).length ? sortConfig : defaultSort;
        updatePageData(paginationConfig, finalSortConfig, filterConfig);
    }, [
        page,
        rowsPerPage,
        schema?.config?.sort.defaultSort,
        sortConfig,
        updatePageData,
        filterConfig,
    ]);

    const getInitialRow = () => {
        const result: Record<string, string> & {id: string} = {id: ''};
        const data = Object.values(columns);
        for (const column of data) {
            if (column?.default) {
                result[column.id] = column.default;
            }
        }
        return result;
    };

    return {
        page,
        rowsPerPage,
        sortConfig,
        schema,
        columns,
        tableConfig,
        requiredConfig,
        rows,
        ref,
        handleUpdate,
        deleteEntityHandle,
        getInitialRow,
        setPage,
        setRowsPerPage,
        setSortConfig,
        setUpdateDictionaries,
        setFilterConfig,
        filterConfig,
    };
};
