/* tslint:disable */
/* eslint-disable */
/**
 * ReportPro API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AsbWellboreDTO
 */
export interface AsbWellboreDTO {
    /**
     * 
     * @type {number}
     * @memberof AsbWellboreDTO
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof AsbWellboreDTO
     */
    name?: string;
}

/**
 * Check if a given object implements the AsbWellboreDTO interface.
 */
export function instanceOfAsbWellboreDTO(value: object): value is AsbWellboreDTO {
    return true;
}

export function AsbWellboreDTOFromJSON(json: any): AsbWellboreDTO {
    return AsbWellboreDTOFromJSONTyped(json, false);
}

export function AsbWellboreDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): AsbWellboreDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'name': json['name'] == null ? undefined : json['name'],
    };
}

export function AsbWellboreDTOToJSON(value?: AsbWellboreDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'name': value['name'],
    };
}

