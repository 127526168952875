/* tslint:disable */
/* eslint-disable */
/**
 * ReportPro API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CurveRequest } from './CurveRequest';
import {
    CurveRequestFromJSON,
    CurveRequestFromJSONTyped,
    CurveRequestToJSON,
} from './CurveRequest';

/**
 * 
 * @export
 * @interface TrackRequest
 */
export interface TrackRequest {
    /**
     * 
     * @type {string}
     * @memberof TrackRequest
     */
    id?: string;
    /**
     * 
     * @type {Array<CurveRequest>}
     * @memberof TrackRequest
     */
    curves?: Array<CurveRequest>;
}

/**
 * Check if a given object implements the TrackRequest interface.
 */
export function instanceOfTrackRequest(value: object): value is TrackRequest {
    return true;
}

export function TrackRequestFromJSON(json: any): TrackRequest {
    return TrackRequestFromJSONTyped(json, false);
}

export function TrackRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): TrackRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'curves': json['curves'] == null ? undefined : ((json['curves'] as Array<any>).map(CurveRequestFromJSON)),
    };
}

export function TrackRequestToJSON(value?: TrackRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'curves': value['curves'] == null ? undefined : ((value['curves'] as Array<any>).map(CurveRequestToJSON)),
    };
}

