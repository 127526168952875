/* tslint:disable */
/* eslint-disable */
/**
 * ReportPro API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  DrillingRigEquipment,
  ErrorResponse,
  SchedulerItem,
  ValidationErrorResponse,
} from '../models/index';
import {
    DrillingRigEquipmentFromJSON,
    DrillingRigEquipmentToJSON,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    SchedulerItemFromJSON,
    SchedulerItemToJSON,
    ValidationErrorResponseFromJSON,
    ValidationErrorResponseToJSON,
} from '../models/index';

export interface Create2Request {
    rigId: number;
    drillingRigEquipment: DrillingRigEquipment;
}

export interface Delete2Request {
    id: number;
}

export interface FindAll1Request {
    rigId: number;
}

export interface GetById1Request {
    id: number;
}

export interface GetCalendarView1Request {
    rigId: number;
}

export interface Update2Request {
    id: number;
    drillingRigEquipment: DrillingRigEquipment;
}

/**
 * 
 */
export class DrillingRigEquipmentControllerApi extends runtime.BaseAPI {

    /**
     */
    async create2Raw(requestParameters: Create2Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DrillingRigEquipment>> {
        if (requestParameters['rigId'] == null) {
            throw new runtime.RequiredError(
                'rigId',
                'Required parameter "rigId" was null or undefined when calling create2().'
            );
        }

        if (requestParameters['drillingRigEquipment'] == null) {
            throw new runtime.RequiredError(
                'drillingRigEquipment',
                'Required parameter "drillingRigEquipment" was null or undefined when calling create2().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['rigId'] != null) {
            queryParameters['rigId'] = requestParameters['rigId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer Authentication", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/drilling-rig-equipment`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DrillingRigEquipmentToJSON(requestParameters['drillingRigEquipment']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DrillingRigEquipmentFromJSON(jsonValue));
    }

    /**
     */
    async create2(requestParameters: Create2Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DrillingRigEquipment> {
        const response = await this.create2Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async delete2Raw(requestParameters: Delete2Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling delete2().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer Authentication", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/drilling-rig-equipment/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async delete2(requestParameters: Delete2Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.delete2Raw(requestParameters, initOverrides);
    }

    /**
     */
    async findAll1Raw(requestParameters: FindAll1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<DrillingRigEquipment>>> {
        if (requestParameters['rigId'] == null) {
            throw new runtime.RequiredError(
                'rigId',
                'Required parameter "rigId" was null or undefined when calling findAll1().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['rigId'] != null) {
            queryParameters['rigId'] = requestParameters['rigId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer Authentication", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/drilling-rig-equipment`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DrillingRigEquipmentFromJSON));
    }

    /**
     */
    async findAll1(requestParameters: FindAll1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<DrillingRigEquipment>> {
        const response = await this.findAll1Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getById1Raw(requestParameters: GetById1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DrillingRigEquipment>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling getById1().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer Authentication", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/drilling-rig-equipment/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DrillingRigEquipmentFromJSON(jsonValue));
    }

    /**
     */
    async getById1(requestParameters: GetById1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DrillingRigEquipment> {
        const response = await this.getById1Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getCalendarView1Raw(requestParameters: GetCalendarView1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SchedulerItem>>> {
        if (requestParameters['rigId'] == null) {
            throw new runtime.RequiredError(
                'rigId',
                'Required parameter "rigId" was null or undefined when calling getCalendarView1().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['rigId'] != null) {
            queryParameters['rigId'] = requestParameters['rigId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer Authentication", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/drilling-rig-equipment/calendar-view`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SchedulerItemFromJSON));
    }

    /**
     */
    async getCalendarView1(requestParameters: GetCalendarView1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SchedulerItem>> {
        const response = await this.getCalendarView1Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async update2Raw(requestParameters: Update2Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DrillingRigEquipment>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling update2().'
            );
        }

        if (requestParameters['drillingRigEquipment'] == null) {
            throw new runtime.RequiredError(
                'drillingRigEquipment',
                'Required parameter "drillingRigEquipment" was null or undefined when calling update2().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer Authentication", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/drilling-rig-equipment/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: DrillingRigEquipmentToJSON(requestParameters['drillingRigEquipment']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DrillingRigEquipmentFromJSON(jsonValue));
    }

    /**
     */
    async update2(requestParameters: Update2Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DrillingRigEquipment> {
        const response = await this.update2Raw(requestParameters, initOverrides);
        return await response.value();
    }

}
