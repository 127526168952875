/* tslint:disable */
/* eslint-disable */
/**
 * ReportPro API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Param } from './Param';
import {
    ParamFromJSON,
    ParamFromJSONTyped,
    ParamToJSON,
} from './Param';

/**
 * 
 * @export
 * @interface AutoDrillingFunctionParamsSchema
 */
export interface AutoDrillingFunctionParamsSchema {
    /**
     * 
     * @type {string}
     * @memberof AutoDrillingFunctionParamsSchema
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof AutoDrillingFunctionParamsSchema
     */
    operationType: AutoDrillingFunctionParamsSchemaOperationTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof AutoDrillingFunctionParamsSchema
     */
    functionType: AutoDrillingFunctionParamsSchemaFunctionTypeEnum;
    /**
     * 
     * @type {Array<Param>}
     * @memberof AutoDrillingFunctionParamsSchema
     */
    params: Array<Param>;
}


/**
 * @export
 */
export const AutoDrillingFunctionParamsSchemaOperationTypeEnum = {
    PumpStart: 'PUMP_START',
    SwitchingToTheMode: 'SWITCHING_TO_THE_MODE',
    OscillationAngles: 'OSCILLATION_ANGLES',
    RotorRpmAcceleration: 'ROTOR_RPM_ACCELERATION',
    RotorLoweringBit: 'ROTOR_LOWERING_BIT',
    Rotor: 'ROTOR',
    LoadCapacity: 'LOAD_CAPACITY',
    ReamingRotor: 'REAMING_ROTOR',
    PositioningOffTheBottom: 'POSITIONING_OFF_THE_BOTTOM',
    TfOrientation: 'TF_ORIENTATION',
    SlideLoweringBit: 'SLIDE_LOWERING_BIT',
    Slide: 'SLIDE',
    ReamingSlide: 'REAMING_SLIDE',
    RecordingStaticMeasurement: 'RECORDING_STATIC_MEASUREMENT',
    SwitchingOffThePump: 'SWITCHING_OFF_THE_PUMP'
} as const;
export type AutoDrillingFunctionParamsSchemaOperationTypeEnum = typeof AutoDrillingFunctionParamsSchemaOperationTypeEnum[keyof typeof AutoDrillingFunctionParamsSchemaOperationTypeEnum];

/**
 * @export
 */
export const AutoDrillingFunctionParamsSchemaFunctionTypeEnum = {
    AntiCrashRotation: 'ANTI_CRASH_ROTATION',
    UpdatingNoloadParameters: 'UPDATING_NOLOAD_PARAMETERS',
    AdaptiveDrilling: 'ADAPTIVE_DRILLING',
    Oscillation: 'OSCILLATION',
    AutoHoldTf: 'AUTO_HOLD_TF',
    StaticMeasurementOutput: 'STATIC_MEASUREMENT_OUTPUT',
    DrillTest: 'DRILL_TEST',
    ShockTest: 'SHOCK_TEST',
    Weighing: 'WEIGHING',
    Damper: 'DAMPER'
} as const;
export type AutoDrillingFunctionParamsSchemaFunctionTypeEnum = typeof AutoDrillingFunctionParamsSchemaFunctionTypeEnum[keyof typeof AutoDrillingFunctionParamsSchemaFunctionTypeEnum];


/**
 * Check if a given object implements the AutoDrillingFunctionParamsSchema interface.
 */
export function instanceOfAutoDrillingFunctionParamsSchema(value: object): value is AutoDrillingFunctionParamsSchema {
    if (!('title' in value) || value['title'] === undefined) return false;
    if (!('operationType' in value) || value['operationType'] === undefined) return false;
    if (!('functionType' in value) || value['functionType'] === undefined) return false;
    if (!('params' in value) || value['params'] === undefined) return false;
    return true;
}

export function AutoDrillingFunctionParamsSchemaFromJSON(json: any): AutoDrillingFunctionParamsSchema {
    return AutoDrillingFunctionParamsSchemaFromJSONTyped(json, false);
}

export function AutoDrillingFunctionParamsSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): AutoDrillingFunctionParamsSchema {
    if (json == null) {
        return json;
    }
    return {
        
        'title': json['title'],
        'operationType': json['operationType'],
        'functionType': json['functionType'],
        'params': ((json['params'] as Array<any>).map(ParamFromJSON)),
    };
}

export function AutoDrillingFunctionParamsSchemaToJSON(value?: AutoDrillingFunctionParamsSchema | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'title': value['title'],
        'operationType': value['operationType'],
        'functionType': value['functionType'],
        'params': ((value['params'] as Array<any>).map(ParamToJSON)),
    };
}

