/* tslint:disable */
/* eslint-disable */
/**
 * ReportPro API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { SchedulerEventData } from './SchedulerEventData';
import {
    SchedulerEventDataFromJSON,
    SchedulerEventDataFromJSONTyped,
    SchedulerEventDataToJSON,
} from './SchedulerEventData';

/**
 * 
 * @export
 * @interface SchedulerEvent
 */
export interface SchedulerEvent {
    /**
     * 
     * @type {string}
     * @memberof SchedulerEvent
     */
    id: string;
    /**
     * 
     * @type {Date}
     * @memberof SchedulerEvent
     */
    dateFrom: Date;
    /**
     * 
     * @type {Date}
     * @memberof SchedulerEvent
     */
    dateTo: Date;
    /**
     * 
     * @type {string}
     * @memberof SchedulerEvent
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof SchedulerEvent
     */
    type: SchedulerEventTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof SchedulerEvent
     */
    parentId: string;
    /**
     * 
     * @type {SchedulerEventData}
     * @memberof SchedulerEvent
     */
    data?: SchedulerEventData;
}


/**
 * @export
 */
export const SchedulerEventTypeEnum = {
    Work: 'WORK',
    Waiting: 'WAITING',
    Incident: 'INCIDENT',
    FinalWorks: 'FINAL_WORKS',
    WellPlan: 'WELL_PLAN',
    Vmr: 'VMR',
    Drilling: 'DRILLING',
    WellCompletion: 'WELL_COMPLETION',
    Conservation: 'CONSERVATION',
    Pause: 'PAUSE',
    Shifting: 'SHIFTING',
    Simple: 'SIMPLE',
    DrillingPad: 'DRILLING_PAD',
    DrillingWell: 'DRILLING_WELL',
    PadKgsBuilding: 'PAD_KGS_BUILDING',
    KgsArrangement: 'KGS_ARRANGEMENT',
    PadDrillingRigAssembling: 'PAD_DRILLING_RIG_ASSEMBLING',
    PadDrillingRigDisassembling: 'PAD_DRILLING_RIG_DISASSEMBLING',
    TechnicalWork: 'TECHNICAL_WORK',
    TechnicalWorkByDay: 'TECHNICAL_WORK_BY_DAY'
} as const;
export type SchedulerEventTypeEnum = typeof SchedulerEventTypeEnum[keyof typeof SchedulerEventTypeEnum];


/**
 * Check if a given object implements the SchedulerEvent interface.
 */
export function instanceOfSchedulerEvent(value: object): value is SchedulerEvent {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('dateFrom' in value) || value['dateFrom'] === undefined) return false;
    if (!('dateTo' in value) || value['dateTo'] === undefined) return false;
    if (!('type' in value) || value['type'] === undefined) return false;
    if (!('parentId' in value) || value['parentId'] === undefined) return false;
    return true;
}

export function SchedulerEventFromJSON(json: any): SchedulerEvent {
    return SchedulerEventFromJSONTyped(json, false);
}

export function SchedulerEventFromJSONTyped(json: any, ignoreDiscriminator: boolean): SchedulerEvent {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'dateFrom': (new Date(json['dateFrom'])),
        'dateTo': (new Date(json['dateTo'])),
        'title': json['title'] == null ? undefined : json['title'],
        'type': json['type'],
        'parentId': json['parentId'],
        'data': json['data'] == null ? undefined : SchedulerEventDataFromJSON(json['data']),
    };
}

export function SchedulerEventToJSON(value?: SchedulerEvent | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'dateFrom': ((value['dateFrom']).toISOString()),
        'dateTo': ((value['dateTo']).toISOString()),
        'title': value['title'],
        'type': value['type'],
        'parentId': value['parentId'],
        'data': SchedulerEventDataToJSON(value['data']),
    };
}

