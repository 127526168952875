/* tslint:disable */
/* eslint-disable */
/**
 * ReportPro API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { OperationTimeDuration } from './OperationTimeDuration';
import {
    OperationTimeDurationFromJSON,
    OperationTimeDurationFromJSONTyped,
    OperationTimeDurationToJSON,
} from './OperationTimeDuration';

/**
 * 
 * @export
 * @interface OperationTimeDurationByShiftResponse
 */
export interface OperationTimeDurationByShiftResponse {
    /**
     * 
     * @type {OperationTimeDuration}
     * @memberof OperationTimeDurationByShiftResponse
     */
    overall?: OperationTimeDuration;
    /**
     * 
     * @type {OperationTimeDuration}
     * @memberof OperationTimeDurationByShiftResponse
     */
    dayShift?: OperationTimeDuration;
    /**
     * 
     * @type {OperationTimeDuration}
     * @memberof OperationTimeDurationByShiftResponse
     */
    nightShift?: OperationTimeDuration;
}

/**
 * Check if a given object implements the OperationTimeDurationByShiftResponse interface.
 */
export function instanceOfOperationTimeDurationByShiftResponse(value: object): value is OperationTimeDurationByShiftResponse {
    return true;
}

export function OperationTimeDurationByShiftResponseFromJSON(json: any): OperationTimeDurationByShiftResponse {
    return OperationTimeDurationByShiftResponseFromJSONTyped(json, false);
}

export function OperationTimeDurationByShiftResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): OperationTimeDurationByShiftResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'overall': json['overall'] == null ? undefined : OperationTimeDurationFromJSON(json['overall']),
        'dayShift': json['dayShift'] == null ? undefined : OperationTimeDurationFromJSON(json['dayShift']),
        'nightShift': json['nightShift'] == null ? undefined : OperationTimeDurationFromJSON(json['nightShift']),
    };
}

export function OperationTimeDurationByShiftResponseToJSON(value?: OperationTimeDurationByShiftResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'overall': OperationTimeDurationToJSON(value['overall']),
        'dayShift': OperationTimeDurationToJSON(value['dayShift']),
        'nightShift': OperationTimeDurationToJSON(value['nightShift']),
    };
}

