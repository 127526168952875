/* tslint:disable */
/* eslint-disable */
/**
 * ReportPro API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WitsmlWellboreDto
 */
export interface WitsmlWellboreDto {
    /**
     * 
     * @type {string}
     * @memberof WitsmlWellboreDto
     */
    wellboreName?: string;
    /**
     * 
     * @type {string}
     * @memberof WitsmlWellboreDto
     */
    wellboreUid?: string;
    /**
     * 
     * @type {string}
     * @memberof WitsmlWellboreDto
     */
    wellName?: string;
    /**
     * 
     * @type {string}
     * @memberof WitsmlWellboreDto
     */
    wellUid?: string;
    /**
     * 
     * @type {Date}
     * @memberof WitsmlWellboreDto
     */
    dateFrom?: Date;
    /**
     * 
     * @type {Date}
     * @memberof WitsmlWellboreDto
     */
    dateTo?: Date;
    /**
     * 
     * @type {number}
     * @memberof WitsmlWellboreDto
     */
    depthFrom?: number;
    /**
     * 
     * @type {number}
     * @memberof WitsmlWellboreDto
     */
    depthTo?: number;
    /**
     * 
     * @type {boolean}
     * @memberof WitsmlWellboreDto
     */
    active?: boolean;
}

/**
 * Check if a given object implements the WitsmlWellboreDto interface.
 */
export function instanceOfWitsmlWellboreDto(value: object): value is WitsmlWellboreDto {
    return true;
}

export function WitsmlWellboreDtoFromJSON(json: any): WitsmlWellboreDto {
    return WitsmlWellboreDtoFromJSONTyped(json, false);
}

export function WitsmlWellboreDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): WitsmlWellboreDto {
    if (json == null) {
        return json;
    }
    return {
        
        'wellboreName': json['wellboreName'] == null ? undefined : json['wellboreName'],
        'wellboreUid': json['wellboreUid'] == null ? undefined : json['wellboreUid'],
        'wellName': json['wellName'] == null ? undefined : json['wellName'],
        'wellUid': json['wellUid'] == null ? undefined : json['wellUid'],
        'dateFrom': json['dateFrom'] == null ? undefined : (new Date(json['dateFrom'])),
        'dateTo': json['dateTo'] == null ? undefined : (new Date(json['dateTo'])),
        'depthFrom': json['depthFrom'] == null ? undefined : json['depthFrom'],
        'depthTo': json['depthTo'] == null ? undefined : json['depthTo'],
        'active': json['active'] == null ? undefined : json['active'],
    };
}

export function WitsmlWellboreDtoToJSON(value?: WitsmlWellboreDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'wellboreName': value['wellboreName'],
        'wellboreUid': value['wellboreUid'],
        'wellName': value['wellName'],
        'wellUid': value['wellUid'],
        'dateFrom': value['dateFrom'] == null ? undefined : ((value['dateFrom']).toISOString()),
        'dateTo': value['dateTo'] == null ? undefined : ((value['dateTo']).toISOString()),
        'depthFrom': value['depthFrom'],
        'depthTo': value['depthTo'],
        'active': value['active'],
    };
}

