/* tslint:disable */
/* eslint-disable */
/**
 * ReportPro API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DrillingRigKnbkInfoResponse
 */
export interface DrillingRigKnbkInfoResponse {
    /**
     * 
     * @type {number}
     * @memberof DrillingRigKnbkInfoResponse
     */
    number?: number;
    /**
     * 
     * @type {number}
     * @memberof DrillingRigKnbkInfoResponse
     */
    knbkFactId?: number;
    /**
     * 
     * @type {number}
     * @memberof DrillingRigKnbkInfoResponse
     */
    run?: number;
    /**
     * 
     * @type {string}
     * @memberof DrillingRigKnbkInfoResponse
     */
    purpose?: string;
    /**
     * 
     * @type {number}
     * @memberof DrillingRigKnbkInfoResponse
     */
    chiselDiameter?: number;
}

/**
 * Check if a given object implements the DrillingRigKnbkInfoResponse interface.
 */
export function instanceOfDrillingRigKnbkInfoResponse(value: object): value is DrillingRigKnbkInfoResponse {
    return true;
}

export function DrillingRigKnbkInfoResponseFromJSON(json: any): DrillingRigKnbkInfoResponse {
    return DrillingRigKnbkInfoResponseFromJSONTyped(json, false);
}

export function DrillingRigKnbkInfoResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): DrillingRigKnbkInfoResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'number': json['number'] == null ? undefined : json['number'],
        'knbkFactId': json['knbkFactId'] == null ? undefined : json['knbkFactId'],
        'run': json['run'] == null ? undefined : json['run'],
        'purpose': json['purpose'] == null ? undefined : json['purpose'],
        'chiselDiameter': json['chiselDiameter'] == null ? undefined : json['chiselDiameter'],
    };
}

export function DrillingRigKnbkInfoResponseToJSON(value?: DrillingRigKnbkInfoResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'number': value['number'],
        'knbkFactId': value['knbkFactId'],
        'run': value['run'],
        'purpose': value['purpose'],
        'chiselDiameter': value['chiselDiameter'],
    };
}

