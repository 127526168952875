/* tslint:disable */
/* eslint-disable */
/**
 * ReportPro API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DrmOperationDto
 */
export interface DrmOperationDto {
    /**
     * 
     * @type {string}
     * @memberof DrmOperationDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof DrmOperationDto
     */
    type?: DrmOperationDtoTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof DrmOperationDto
     */
    warningMessage?: string;
}


/**
 * @export
 */
export const DrmOperationDtoTypeEnum = {
    RotorDrilling: 'ROTOR_DRILLING',
    SlideDrilling: 'SLIDE_DRILLING',
    WorkingOutDown: 'WORKING_OUT_DOWN',
    WorkingOutUp: 'WORKING_OUT_UP',
    Spo: 'SPO',
    Restriction: 'RESTRICTION',
    SwitchingOffThePump: 'SWITCHING_OFF_THE_PUMP',
    PositioningOffTheBottom: 'POSITIONING_OFF_THE_BOTTOM',
    RecordingStaticMeasurement: 'RECORDING_STATIC_MEASUREMENT',
    LoadCapacity: 'LOAD_CAPACITY',
    TfOrientation: 'TF_ORIENTATION',
    SwitchingToTheMode: 'SWITCHING_TO_THE_MODE',
    AntiCrashRotation: 'ANTI_CRASH_ROTATION',
    ReamingRotor: 'REAMING_ROTOR',
    ReamingSlide: 'REAMING_SLIDE',
    StaticMeasurementOutput: 'STATIC_MEASUREMENT_OUTPUT',
    Oscillation: 'OSCILLATION'
} as const;
export type DrmOperationDtoTypeEnum = typeof DrmOperationDtoTypeEnum[keyof typeof DrmOperationDtoTypeEnum];


/**
 * Check if a given object implements the DrmOperationDto interface.
 */
export function instanceOfDrmOperationDto(value: object): value is DrmOperationDto {
    return true;
}

export function DrmOperationDtoFromJSON(json: any): DrmOperationDto {
    return DrmOperationDtoFromJSONTyped(json, false);
}

export function DrmOperationDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): DrmOperationDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'type': json['type'] == null ? undefined : json['type'],
        'warningMessage': json['warningMessage'] == null ? undefined : json['warningMessage'],
    };
}

export function DrmOperationDtoToJSON(value?: DrmOperationDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'type': value['type'],
        'warningMessage': value['warningMessage'],
    };
}

