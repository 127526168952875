/* tslint:disable */
/* eslint-disable */
/**
 * ReportPro API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { DrmSectionDto } from './DrmSectionDto';
import {
    DrmSectionDtoFromJSON,
    DrmSectionDtoFromJSONTyped,
    DrmSectionDtoToJSON,
} from './DrmSectionDto';

/**
 * 
 * @export
 * @interface DrmDto
 */
export interface DrmDto {
    /**
     * 
     * @type {string}
     * @memberof DrmDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof DrmDto
     */
    revisionId?: string;
    /**
     * 
     * @type {string}
     * @memberof DrmDto
     */
    type?: DrmDtoTypeEnum;
    /**
     * 
     * @type {Array<DrmSectionDto>}
     * @memberof DrmDto
     */
    sections?: Array<DrmSectionDto>;
}


/**
 * @export
 */
export const DrmDtoTypeEnum = {
    Drm: 'DRM',
    AkbMode: 'AKB_MODE'
} as const;
export type DrmDtoTypeEnum = typeof DrmDtoTypeEnum[keyof typeof DrmDtoTypeEnum];


/**
 * Check if a given object implements the DrmDto interface.
 */
export function instanceOfDrmDto(value: object): value is DrmDto {
    return true;
}

export function DrmDtoFromJSON(json: any): DrmDto {
    return DrmDtoFromJSONTyped(json, false);
}

export function DrmDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): DrmDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'revisionId': json['revisionId'] == null ? undefined : json['revisionId'],
        'type': json['type'] == null ? undefined : json['type'],
        'sections': json['sections'] == null ? undefined : ((json['sections'] as Array<any>).map(DrmSectionDtoFromJSON)),
    };
}

export function DrmDtoToJSON(value?: DrmDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'revisionId': value['revisionId'],
        'type': value['type'],
        'sections': value['sections'] == null ? undefined : ((value['sections'] as Array<any>).map(DrmSectionDtoToJSON)),
    };
}

