/* tslint:disable */
/* eslint-disable */
/**
 * ReportPro API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { DrmOperationInterval } from './DrmOperationInterval';
import {
    DrmOperationIntervalFromJSON,
    DrmOperationIntervalFromJSONTyped,
    DrmOperationIntervalToJSON,
} from './DrmOperationInterval';

/**
 * 
 * @export
 * @interface PositioningOffTheBottomDrmOperationInterval
 */
export interface PositioningOffTheBottomDrmOperationInterval extends DrmOperationInterval {
    /**
     * 
     * @type {number}
     * @memberof PositioningOffTheBottomDrmOperationInterval
     */
    stopOffTheBottom: number;
    /**
     * 
     * @type {number}
     * @memberof PositioningOffTheBottomDrmOperationInterval
     */
    pressureMax: number;
    /**
     * 
     * @type {number}
     * @memberof PositioningOffTheBottomDrmOperationInterval
     */
    differentialPressure: number;
    /**
     * 
     * @type {number}
     * @memberof PositioningOffTheBottomDrmOperationInterval
     */
    tight: number;
    /**
     * 
     * @type {number}
     * @memberof PositioningOffTheBottomDrmOperationInterval
     */
    slackingOff: number;
    /**
     * 
     * @type {number}
     * @memberof PositioningOffTheBottomDrmOperationInterval
     */
    torqueMax: number;
    /**
     * 
     * @type {number}
     * @memberof PositioningOffTheBottomDrmOperationInterval
     */
    ropUp: number;
    /**
     * 
     * @type {number}
     * @memberof PositioningOffTheBottomDrmOperationInterval
     */
    ropDown: number;
    /**
     * 
     * @type {number}
     * @memberof PositioningOffTheBottomDrmOperationInterval
     */
    rpmUp: number;
    /**
     * 
     * @type {number}
     * @memberof PositioningOffTheBottomDrmOperationInterval
     */
    rpmDown: number;
    /**
     * 
     * @type {number}
     * @memberof PositioningOffTheBottomDrmOperationInterval
     */
    flowRateUp: number;
    /**
     * 
     * @type {number}
     * @memberof PositioningOffTheBottomDrmOperationInterval
     */
    flowRateDown: number;
}

/**
 * Check if a given object implements the PositioningOffTheBottomDrmOperationInterval interface.
 */
export function instanceOfPositioningOffTheBottomDrmOperationInterval(value: object): value is PositioningOffTheBottomDrmOperationInterval {
    if (!('stopOffTheBottom' in value) || value['stopOffTheBottom'] === undefined) return false;
    if (!('pressureMax' in value) || value['pressureMax'] === undefined) return false;
    if (!('differentialPressure' in value) || value['differentialPressure'] === undefined) return false;
    if (!('tight' in value) || value['tight'] === undefined) return false;
    if (!('slackingOff' in value) || value['slackingOff'] === undefined) return false;
    if (!('torqueMax' in value) || value['torqueMax'] === undefined) return false;
    if (!('ropUp' in value) || value['ropUp'] === undefined) return false;
    if (!('ropDown' in value) || value['ropDown'] === undefined) return false;
    if (!('rpmUp' in value) || value['rpmUp'] === undefined) return false;
    if (!('rpmDown' in value) || value['rpmDown'] === undefined) return false;
    if (!('flowRateUp' in value) || value['flowRateUp'] === undefined) return false;
    if (!('flowRateDown' in value) || value['flowRateDown'] === undefined) return false;
    return true;
}

export function PositioningOffTheBottomDrmOperationIntervalFromJSON(json: any): PositioningOffTheBottomDrmOperationInterval {
    return PositioningOffTheBottomDrmOperationIntervalFromJSONTyped(json, false);
}

export function PositioningOffTheBottomDrmOperationIntervalFromJSONTyped(json: any, ignoreDiscriminator: boolean): PositioningOffTheBottomDrmOperationInterval {
    if (json == null) {
        return json;
    }
    return {
        ...DrmOperationIntervalFromJSONTyped(json, ignoreDiscriminator),
        'stopOffTheBottom': json['stopOffTheBottom'],
        'pressureMax': json['pressureMax'],
        'differentialPressure': json['differentialPressure'],
        'tight': json['tight'],
        'slackingOff': json['slackingOff'],
        'torqueMax': json['torqueMax'],
        'ropUp': json['ropUp'],
        'ropDown': json['ropDown'],
        'rpmUp': json['rpmUp'],
        'rpmDown': json['rpmDown'],
        'flowRateUp': json['flowRateUp'],
        'flowRateDown': json['flowRateDown'],
    };
}

export function PositioningOffTheBottomDrmOperationIntervalToJSON(value?: PositioningOffTheBottomDrmOperationInterval | null): any {
    if (value == null) {
        return value;
    }
    return {
        ...DrmOperationIntervalToJSON(value),
        'stopOffTheBottom': value['stopOffTheBottom'],
        'pressureMax': value['pressureMax'],
        'differentialPressure': value['differentialPressure'],
        'tight': value['tight'],
        'slackingOff': value['slackingOff'],
        'torqueMax': value['torqueMax'],
        'ropUp': value['ropUp'],
        'ropDown': value['ropDown'],
        'rpmUp': value['rpmUp'],
        'rpmDown': value['rpmDown'],
        'flowRateUp': value['flowRateUp'],
        'flowRateDown': value['flowRateDown'],
    };
}

