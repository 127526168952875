/* tslint:disable */
/* eslint-disable */
/**
 * ReportPro API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DrillingMachinesEfficiencyRequest
 */
export interface DrillingMachinesEfficiencyRequest {
    /**
     * 
     * @type {string}
     * @memberof DrillingMachinesEfficiencyRequest
     */
    dateStart: string;
    /**
     * 
     * @type {string}
     * @memberof DrillingMachinesEfficiencyRequest
     */
    dateEnd: string;
    /**
     * 
     * @type {string}
     * @memberof DrillingMachinesEfficiencyRequest
     */
    operationCode: string;
    /**
     * 
     * @type {number}
     * @memberof DrillingMachinesEfficiencyRequest
     */
    wellId?: number;
    /**
     * 
     * @type {string}
     * @memberof DrillingMachinesEfficiencyRequest
     */
    wellboreId: string;
}

/**
 * Check if a given object implements the DrillingMachinesEfficiencyRequest interface.
 */
export function instanceOfDrillingMachinesEfficiencyRequest(value: object): value is DrillingMachinesEfficiencyRequest {
    if (!('dateStart' in value) || value['dateStart'] === undefined) return false;
    if (!('dateEnd' in value) || value['dateEnd'] === undefined) return false;
    if (!('operationCode' in value) || value['operationCode'] === undefined) return false;
    if (!('wellboreId' in value) || value['wellboreId'] === undefined) return false;
    return true;
}

export function DrillingMachinesEfficiencyRequestFromJSON(json: any): DrillingMachinesEfficiencyRequest {
    return DrillingMachinesEfficiencyRequestFromJSONTyped(json, false);
}

export function DrillingMachinesEfficiencyRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): DrillingMachinesEfficiencyRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'dateStart': json['dateStart'],
        'dateEnd': json['dateEnd'],
        'operationCode': json['operationCode'],
        'wellId': json['wellId'] == null ? undefined : json['wellId'],
        'wellboreId': json['wellboreId'],
    };
}

export function DrillingMachinesEfficiencyRequestToJSON(value?: DrillingMachinesEfficiencyRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'dateStart': value['dateStart'],
        'dateEnd': value['dateEnd'],
        'operationCode': value['operationCode'],
        'wellId': value['wellId'],
        'wellboreId': value['wellboreId'],
    };
}

