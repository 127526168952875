/* tslint:disable */
/* eslint-disable */
/**
 * ReportPro API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { SchedulerItemContent } from './SchedulerItemContent';
import {
    SchedulerItemContentFromJSON,
    SchedulerItemContentFromJSONTyped,
    SchedulerItemContentToJSON,
} from './SchedulerItemContent';

/**
 * 
 * @export
 * @interface SchedulerFieldContent
 */
export interface SchedulerFieldContent extends SchedulerItemContent {
    /**
     * 
     * @type {number}
     * @memberof SchedulerFieldContent
     */
    fieldId: number;
}

/**
 * Check if a given object implements the SchedulerFieldContent interface.
 */
export function instanceOfSchedulerFieldContent(value: object): value is SchedulerFieldContent {
    if (!('fieldId' in value) || value['fieldId'] === undefined) return false;
    return true;
}

export function SchedulerFieldContentFromJSON(json: any): SchedulerFieldContent {
    return SchedulerFieldContentFromJSONTyped(json, false);
}

export function SchedulerFieldContentFromJSONTyped(json: any, ignoreDiscriminator: boolean): SchedulerFieldContent {
    if (json == null) {
        return json;
    }
    return {
        ...SchedulerItemContentFromJSONTyped(json, ignoreDiscriminator),
        'fieldId': json['fieldId'],
    };
}

export function SchedulerFieldContentToJSON(value?: SchedulerFieldContent | null): any {
    if (value == null) {
        return value;
    }
    return {
        ...SchedulerItemContentToJSON(value),
        'fieldId': value['fieldId'],
    };
}

