/* tslint:disable */
/* eslint-disable */
/**
 * ReportPro API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BasicDictEntity
 */
export interface BasicDictEntity {
    /**
     * 
     * @type {object}
     * @memberof BasicDictEntity
     */
    id?: object;
    /**
     * 
     * @type {object}
     * @memberof BasicDictEntity
     */
    value?: object;
}

/**
 * Check if a given object implements the BasicDictEntity interface.
 */
export function instanceOfBasicDictEntity(value: object): value is BasicDictEntity {
    return true;
}

export function BasicDictEntityFromJSON(json: any): BasicDictEntity {
    return BasicDictEntityFromJSONTyped(json, false);
}

export function BasicDictEntityFromJSONTyped(json: any, ignoreDiscriminator: boolean): BasicDictEntity {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'value': json['value'] == null ? undefined : json['value'],
    };
}

export function BasicDictEntityToJSON(value?: BasicDictEntity | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'value': value['value'],
    };
}

