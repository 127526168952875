/* tslint:disable */
/* eslint-disable */
/**
 * ReportPro API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { DrmOperationInterval } from './DrmOperationInterval';
import {
    DrmOperationIntervalFromJSON,
    DrmOperationIntervalFromJSONTyped,
    DrmOperationIntervalToJSON,
} from './DrmOperationInterval';

/**
 * 
 * @export
 * @interface RestrictionDrmOperationInterval
 */
export interface RestrictionDrmOperationInterval extends DrmOperationInterval {
    /**
     * 
     * @type {number}
     * @memberof RestrictionDrmOperationInterval
     */
    differentialPressureMax: number;
    /**
     * 
     * @type {number}
     * @memberof RestrictionDrmOperationInterval
     */
    weightOnBitMax: number;
    /**
     * 
     * @type {number}
     * @memberof RestrictionDrmOperationInterval
     */
    rpmMax: number;
    /**
     * 
     * @type {number}
     * @memberof RestrictionDrmOperationInterval
     */
    flowRateMax: number;
    /**
     * 
     * @type {number}
     * @memberof RestrictionDrmOperationInterval
     */
    pressureMax: number;
    /**
     * 
     * @type {number}
     * @memberof RestrictionDrmOperationInterval
     */
    tight: number;
    /**
     * 
     * @type {number}
     * @memberof RestrictionDrmOperationInterval
     */
    slackingOff: number;
    /**
     * 
     * @type {number}
     * @memberof RestrictionDrmOperationInterval
     */
    topDriveTorqueMax: number;
}

/**
 * Check if a given object implements the RestrictionDrmOperationInterval interface.
 */
export function instanceOfRestrictionDrmOperationInterval(value: object): value is RestrictionDrmOperationInterval {
    if (!('differentialPressureMax' in value) || value['differentialPressureMax'] === undefined) return false;
    if (!('weightOnBitMax' in value) || value['weightOnBitMax'] === undefined) return false;
    if (!('rpmMax' in value) || value['rpmMax'] === undefined) return false;
    if (!('flowRateMax' in value) || value['flowRateMax'] === undefined) return false;
    if (!('pressureMax' in value) || value['pressureMax'] === undefined) return false;
    if (!('tight' in value) || value['tight'] === undefined) return false;
    if (!('slackingOff' in value) || value['slackingOff'] === undefined) return false;
    if (!('topDriveTorqueMax' in value) || value['topDriveTorqueMax'] === undefined) return false;
    return true;
}

export function RestrictionDrmOperationIntervalFromJSON(json: any): RestrictionDrmOperationInterval {
    return RestrictionDrmOperationIntervalFromJSONTyped(json, false);
}

export function RestrictionDrmOperationIntervalFromJSONTyped(json: any, ignoreDiscriminator: boolean): RestrictionDrmOperationInterval {
    if (json == null) {
        return json;
    }
    return {
        ...DrmOperationIntervalFromJSONTyped(json, ignoreDiscriminator),
        'differentialPressureMax': json['differentialPressureMax'],
        'weightOnBitMax': json['weightOnBitMax'],
        'rpmMax': json['rpmMax'],
        'flowRateMax': json['flowRateMax'],
        'pressureMax': json['pressureMax'],
        'tight': json['tight'],
        'slackingOff': json['slackingOff'],
        'topDriveTorqueMax': json['topDriveTorqueMax'],
    };
}

export function RestrictionDrmOperationIntervalToJSON(value?: RestrictionDrmOperationInterval | null): any {
    if (value == null) {
        return value;
    }
    return {
        ...DrmOperationIntervalToJSON(value),
        'differentialPressureMax': value['differentialPressureMax'],
        'weightOnBitMax': value['weightOnBitMax'],
        'rpmMax': value['rpmMax'],
        'flowRateMax': value['flowRateMax'],
        'pressureMax': value['pressureMax'],
        'tight': value['tight'],
        'slackingOff': value['slackingOff'],
        'topDriveTorqueMax': value['topDriveTorqueMax'],
    };
}

