/* tslint:disable */
/* eslint-disable */
/**
 * ReportPro API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { DrmOperationInterval } from './DrmOperationInterval';
import {
    DrmOperationIntervalFromJSON,
    DrmOperationIntervalFromJSONTyped,
    DrmOperationIntervalToJSON,
} from './DrmOperationInterval';

/**
 * 
 * @export
 * @interface AntiCrashRotationDrmOperationInterval
 */
export interface AntiCrashRotationDrmOperationInterval extends DrmOperationInterval {
    /**
     * 
     * @type {number}
     * @memberof AntiCrashRotationDrmOperationInterval
     */
    topDriveRpmMin: number;
    /**
     * 
     * @type {number}
     * @memberof AntiCrashRotationDrmOperationInterval
     */
    topDriveStartMinFlowRate: number;
    /**
     * 
     * @type {number}
     * @memberof AntiCrashRotationDrmOperationInterval
     */
    topDriveTorqueMax: number;
}

/**
 * Check if a given object implements the AntiCrashRotationDrmOperationInterval interface.
 */
export function instanceOfAntiCrashRotationDrmOperationInterval(value: object): value is AntiCrashRotationDrmOperationInterval {
    if (!('topDriveRpmMin' in value) || value['topDriveRpmMin'] === undefined) return false;
    if (!('topDriveStartMinFlowRate' in value) || value['topDriveStartMinFlowRate'] === undefined) return false;
    if (!('topDriveTorqueMax' in value) || value['topDriveTorqueMax'] === undefined) return false;
    return true;
}

export function AntiCrashRotationDrmOperationIntervalFromJSON(json: any): AntiCrashRotationDrmOperationInterval {
    return AntiCrashRotationDrmOperationIntervalFromJSONTyped(json, false);
}

export function AntiCrashRotationDrmOperationIntervalFromJSONTyped(json: any, ignoreDiscriminator: boolean): AntiCrashRotationDrmOperationInterval {
    if (json == null) {
        return json;
    }
    return {
        ...DrmOperationIntervalFromJSONTyped(json, ignoreDiscriminator),
        'topDriveRpmMin': json['topDriveRpmMin'],
        'topDriveStartMinFlowRate': json['topDriveStartMinFlowRate'],
        'topDriveTorqueMax': json['topDriveTorqueMax'],
    };
}

export function AntiCrashRotationDrmOperationIntervalToJSON(value?: AntiCrashRotationDrmOperationInterval | null): any {
    if (value == null) {
        return value;
    }
    return {
        ...DrmOperationIntervalToJSON(value),
        'topDriveRpmMin': value['topDriveRpmMin'],
        'topDriveStartMinFlowRate': value['topDriveStartMinFlowRate'],
        'topDriveTorqueMax': value['topDriveTorqueMax'],
    };
}

