/* tslint:disable */
/* eslint-disable */
/**
 * ReportPro API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ErrorResponse,
  ValidationErrorResponse,
} from '../models/index';
import {
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    ValidationErrorResponseFromJSON,
    ValidationErrorResponseToJSON,
} from '../models/index';

export interface ExportLogDataRequest {
    wellboreId: string;
    exportFormat: ExportLogDataExportFormatEnum;
    exportType: ExportLogDataExportTypeEnum;
    dateFrom?: string;
    dateTo?: string;
    depthFrom?: number;
    depthTo?: number;
}

export interface ExportLogDataPdfRequest {
    wellboreId: string;
    templateId: string;
    axis: ExportLogDataPdfAxisEnum;
    dateFrom?: string;
    dateTo?: string;
    depthFrom?: number;
    depthTo?: number;
    frame?: number;
}

/**
 * 
 */
export class LogDataExportControllerApi extends runtime.BaseAPI {

    /**
     */
    async exportLogDataRaw(requestParameters: ExportLogDataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters['wellboreId'] == null) {
            throw new runtime.RequiredError(
                'wellboreId',
                'Required parameter "wellboreId" was null or undefined when calling exportLogData().'
            );
        }

        if (requestParameters['exportFormat'] == null) {
            throw new runtime.RequiredError(
                'exportFormat',
                'Required parameter "exportFormat" was null or undefined when calling exportLogData().'
            );
        }

        if (requestParameters['exportType'] == null) {
            throw new runtime.RequiredError(
                'exportType',
                'Required parameter "exportType" was null or undefined when calling exportLogData().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['wellboreId'] != null) {
            queryParameters['wellboreId'] = requestParameters['wellboreId'];
        }

        if (requestParameters['dateFrom'] != null) {
            queryParameters['dateFrom'] = requestParameters['dateFrom'];
        }

        if (requestParameters['dateTo'] != null) {
            queryParameters['dateTo'] = requestParameters['dateTo'];
        }

        if (requestParameters['depthFrom'] != null) {
            queryParameters['depthFrom'] = requestParameters['depthFrom'];
        }

        if (requestParameters['depthTo'] != null) {
            queryParameters['depthTo'] = requestParameters['depthTo'];
        }

        if (requestParameters['exportFormat'] != null) {
            queryParameters['exportFormat'] = requestParameters['exportFormat'];
        }

        if (requestParameters['exportType'] != null) {
            queryParameters['exportType'] = requestParameters['exportType'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer Authentication", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/monitoring/export`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async exportLogData(requestParameters: ExportLogDataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.exportLogDataRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async exportLogDataPdfRaw(requestParameters: ExportLogDataPdfRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters['wellboreId'] == null) {
            throw new runtime.RequiredError(
                'wellboreId',
                'Required parameter "wellboreId" was null or undefined when calling exportLogDataPdf().'
            );
        }

        if (requestParameters['templateId'] == null) {
            throw new runtime.RequiredError(
                'templateId',
                'Required parameter "templateId" was null or undefined when calling exportLogDataPdf().'
            );
        }

        if (requestParameters['axis'] == null) {
            throw new runtime.RequiredError(
                'axis',
                'Required parameter "axis" was null or undefined when calling exportLogDataPdf().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['wellboreId'] != null) {
            queryParameters['wellboreId'] = requestParameters['wellboreId'];
        }

        if (requestParameters['templateId'] != null) {
            queryParameters['templateId'] = requestParameters['templateId'];
        }

        if (requestParameters['dateFrom'] != null) {
            queryParameters['dateFrom'] = requestParameters['dateFrom'];
        }

        if (requestParameters['dateTo'] != null) {
            queryParameters['dateTo'] = requestParameters['dateTo'];
        }

        if (requestParameters['depthFrom'] != null) {
            queryParameters['depthFrom'] = requestParameters['depthFrom'];
        }

        if (requestParameters['depthTo'] != null) {
            queryParameters['depthTo'] = requestParameters['depthTo'];
        }

        if (requestParameters['frame'] != null) {
            queryParameters['frame'] = requestParameters['frame'];
        }

        if (requestParameters['axis'] != null) {
            queryParameters['axis'] = requestParameters['axis'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer Authentication", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/monitoring/export/graph/pdf`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async exportLogDataPdf(requestParameters: ExportLogDataPdfRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.exportLogDataPdfRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
 * @export
 */
export const ExportLogDataExportFormatEnum = {
    Xlsx: 'XLSX',
    Pdf: 'PDF',
    Csv: 'CSV'
} as const;
export type ExportLogDataExportFormatEnum = typeof ExportLogDataExportFormatEnum[keyof typeof ExportLogDataExportFormatEnum];
/**
 * @export
 */
export const ExportLogDataExportTypeEnum = {
    Time: 'TIME',
    Depth: 'DEPTH'
} as const;
export type ExportLogDataExportTypeEnum = typeof ExportLogDataExportTypeEnum[keyof typeof ExportLogDataExportTypeEnum];
/**
 * @export
 */
export const ExportLogDataPdfAxisEnum = {
    Time: 'TIME',
    Depth: 'DEPTH'
} as const;
export type ExportLogDataPdfAxisEnum = typeof ExportLogDataPdfAxisEnum[keyof typeof ExportLogDataPdfAxisEnum];
