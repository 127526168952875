/* tslint:disable */
/* eslint-disable */
/**
 * ReportPro API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Liquor
 */
export interface Liquor {
    /**
     * 
     * @type {number}
     * @memberof Liquor
     */
    entrance: number;
    /**
     * 
     * @type {number}
     * @memberof Liquor
     */
    exit: number;
    /**
     * 
     * @type {number}
     * @memberof Liquor
     */
    depth: number;
}

/**
 * Check if a given object implements the Liquor interface.
 */
export function instanceOfLiquor(value: object): value is Liquor {
    if (!('entrance' in value) || value['entrance'] === undefined) return false;
    if (!('exit' in value) || value['exit'] === undefined) return false;
    if (!('depth' in value) || value['depth'] === undefined) return false;
    return true;
}

export function LiquorFromJSON(json: any): Liquor {
    return LiquorFromJSONTyped(json, false);
}

export function LiquorFromJSONTyped(json: any, ignoreDiscriminator: boolean): Liquor {
    if (json == null) {
        return json;
    }
    return {
        
        'entrance': json['entrance'],
        'exit': json['exit'],
        'depth': json['depth'],
    };
}

export function LiquorToJSON(value?: Liquor | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'entrance': value['entrance'],
        'exit': value['exit'],
        'depth': value['depth'],
    };
}

