/* tslint:disable */
/* eslint-disable */
/**
 * ReportPro API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { MnemonicMapDto } from './MnemonicMapDto';
import {
    MnemonicMapDtoFromJSON,
    MnemonicMapDtoFromJSONTyped,
    MnemonicMapDtoToJSON,
} from './MnemonicMapDto';

/**
 * 
 * @export
 * @interface MnemonicTemplateDto
 */
export interface MnemonicTemplateDto {
    /**
     * 
     * @type {string}
     * @memberof MnemonicTemplateDto
     */
    name?: string;
    /**
     * 
     * @type {Array<MnemonicMapDto>}
     * @memberof MnemonicTemplateDto
     */
    mnemonics?: Array<MnemonicMapDto>;
}

/**
 * Check if a given object implements the MnemonicTemplateDto interface.
 */
export function instanceOfMnemonicTemplateDto(value: object): value is MnemonicTemplateDto {
    return true;
}

export function MnemonicTemplateDtoFromJSON(json: any): MnemonicTemplateDto {
    return MnemonicTemplateDtoFromJSONTyped(json, false);
}

export function MnemonicTemplateDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): MnemonicTemplateDto {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'] == null ? undefined : json['name'],
        'mnemonics': json['mnemonics'] == null ? undefined : ((json['mnemonics'] as Array<any>).map(MnemonicMapDtoFromJSON)),
    };
}

export function MnemonicTemplateDtoToJSON(value?: MnemonicTemplateDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'mnemonics': value['mnemonics'] == null ? undefined : ((value['mnemonics'] as Array<any>).map(MnemonicMapDtoToJSON)),
    };
}

