/* tslint:disable */
/* eslint-disable */
/**
 * ReportPro API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SchedulerWellService
 */
export interface SchedulerWellService {
    /**
     * 
     * @type {string}
     * @memberof SchedulerWellService
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof SchedulerWellService
     */
    title: string;
}

/**
 * Check if a given object implements the SchedulerWellService interface.
 */
export function instanceOfSchedulerWellService(value: object): value is SchedulerWellService {
    if (!('title' in value) || value['title'] === undefined) return false;
    return true;
}

export function SchedulerWellServiceFromJSON(json: any): SchedulerWellService {
    return SchedulerWellServiceFromJSONTyped(json, false);
}

export function SchedulerWellServiceFromJSONTyped(json: any, ignoreDiscriminator: boolean): SchedulerWellService {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'title': json['title'],
    };
}

export function SchedulerWellServiceToJSON(value?: SchedulerWellService | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'title': value['title'],
    };
}

