/* tslint:disable */
/* eslint-disable */
/**
 * ReportPro API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { TechnicalMaintenanceWork } from './TechnicalMaintenanceWork';
import {
    TechnicalMaintenanceWorkFromJSON,
    TechnicalMaintenanceWorkFromJSONTyped,
    TechnicalMaintenanceWorkToJSON,
} from './TechnicalMaintenanceWork';

/**
 * 
 * @export
 * @interface TechnicalMaintenance
 */
export interface TechnicalMaintenance {
    /**
     * 
     * @type {string}
     * @memberof TechnicalMaintenance
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof TechnicalMaintenance
     */
    subsystem: string;
    /**
     * 
     * @type {string}
     * @memberof TechnicalMaintenance
     */
    maintenanceType: string;
    /**
     * 
     * @type {string}
     * @memberof TechnicalMaintenance
     */
    maintenanceDescription: string;
    /**
     * 
     * @type {string}
     * @memberof TechnicalMaintenance
     */
    unit: string;
    /**
     * 
     * @type {number}
     * @memberof TechnicalMaintenance
     */
    frequency?: number;
    /**
     * 
     * @type {Date}
     * @memberof TechnicalMaintenance
     */
    lastTechnicalMaintenanceDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof TechnicalMaintenance
     */
    nextTechnicalMaintenanceDate?: Date;
    /**
     * 
     * @type {number}
     * @memberof TechnicalMaintenance
     */
    processPercent?: number;
    /**
     * 
     * @type {string}
     * @memberof TechnicalMaintenance
     */
    plannedWorkId?: string;
    /**
     * 
     * @type {number}
     * @memberof TechnicalMaintenance
     */
    planDurationHours?: number;
    /**
     * 
     * @type {boolean}
     * @memberof TechnicalMaintenance
     */
    isNotBeenMonitored?: boolean;
    /**
     * 
     * @type {Array<TechnicalMaintenanceWork>}
     * @memberof TechnicalMaintenance
     */
    works?: Array<TechnicalMaintenanceWork>;
    /**
     * 
     * @type {number}
     * @memberof TechnicalMaintenance
     */
    equipmentId?: number;
}

/**
 * Check if a given object implements the TechnicalMaintenance interface.
 */
export function instanceOfTechnicalMaintenance(value: object): value is TechnicalMaintenance {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('subsystem' in value) || value['subsystem'] === undefined) return false;
    if (!('maintenanceType' in value) || value['maintenanceType'] === undefined) return false;
    if (!('maintenanceDescription' in value) || value['maintenanceDescription'] === undefined) return false;
    if (!('unit' in value) || value['unit'] === undefined) return false;
    return true;
}

export function TechnicalMaintenanceFromJSON(json: any): TechnicalMaintenance {
    return TechnicalMaintenanceFromJSONTyped(json, false);
}

export function TechnicalMaintenanceFromJSONTyped(json: any, ignoreDiscriminator: boolean): TechnicalMaintenance {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'subsystem': json['subsystem'],
        'maintenanceType': json['maintenanceType'],
        'maintenanceDescription': json['maintenanceDescription'],
        'unit': json['unit'],
        'frequency': json['frequency'] == null ? undefined : json['frequency'],
        'lastTechnicalMaintenanceDate': json['lastTechnicalMaintenanceDate'] == null ? undefined : (new Date(json['lastTechnicalMaintenanceDate'])),
        'nextTechnicalMaintenanceDate': json['nextTechnicalMaintenanceDate'] == null ? undefined : (new Date(json['nextTechnicalMaintenanceDate'])),
        'processPercent': json['processPercent'] == null ? undefined : json['processPercent'],
        'plannedWorkId': json['plannedWorkId'] == null ? undefined : json['plannedWorkId'],
        'planDurationHours': json['planDurationHours'] == null ? undefined : json['planDurationHours'],
        'isNotBeenMonitored': json['isNotBeenMonitored'] == null ? undefined : json['isNotBeenMonitored'],
        'works': json['works'] == null ? undefined : ((json['works'] as Array<any>).map(TechnicalMaintenanceWorkFromJSON)),
        'equipmentId': json['equipmentId'] == null ? undefined : json['equipmentId'],
    };
}

export function TechnicalMaintenanceToJSON(value?: TechnicalMaintenance | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'subsystem': value['subsystem'],
        'maintenanceType': value['maintenanceType'],
        'maintenanceDescription': value['maintenanceDescription'],
        'unit': value['unit'],
        'frequency': value['frequency'],
        'lastTechnicalMaintenanceDate': value['lastTechnicalMaintenanceDate'] == null ? undefined : ((value['lastTechnicalMaintenanceDate']).toISOString()),
        'nextTechnicalMaintenanceDate': value['nextTechnicalMaintenanceDate'] == null ? undefined : ((value['nextTechnicalMaintenanceDate']).toISOString()),
        'processPercent': value['processPercent'],
        'plannedWorkId': value['plannedWorkId'],
        'planDurationHours': value['planDurationHours'],
        'isNotBeenMonitored': value['isNotBeenMonitored'],
        'works': value['works'] == null ? undefined : ((value['works'] as Array<any>).map(TechnicalMaintenanceWorkToJSON)),
        'equipmentId': value['equipmentId'],
    };
}

