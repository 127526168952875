/* tslint:disable */
/* eslint-disable */
/**
 * ReportPro API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { GraphMomentsAndWeightsRequest } from './GraphMomentsAndWeightsRequest';
import {
    GraphMomentsAndWeightsRequestFromJSON,
    GraphMomentsAndWeightsRequestFromJSONTyped,
    GraphMomentsAndWeightsRequestToJSON,
} from './GraphMomentsAndWeightsRequest';

/**
 * 
 * @export
 * @interface DrillingParamsResponse
 */
export interface DrillingParamsResponse {
    /**
     * 
     * @type {number}
     * @memberof DrillingParamsResponse
     */
    wellId?: number;
    /**
     * 
     * @type {string}
     * @memberof DrillingParamsResponse
     */
    calculationId?: string;
    /**
     * 
     * @type {GraphMomentsAndWeightsRequest}
     * @memberof DrillingParamsResponse
     */
    params?: GraphMomentsAndWeightsRequest;
}

/**
 * Check if a given object implements the DrillingParamsResponse interface.
 */
export function instanceOfDrillingParamsResponse(value: object): value is DrillingParamsResponse {
    return true;
}

export function DrillingParamsResponseFromJSON(json: any): DrillingParamsResponse {
    return DrillingParamsResponseFromJSONTyped(json, false);
}

export function DrillingParamsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): DrillingParamsResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'wellId': json['wellId'] == null ? undefined : json['wellId'],
        'calculationId': json['calculationId'] == null ? undefined : json['calculationId'],
        'params': json['params'] == null ? undefined : GraphMomentsAndWeightsRequestFromJSON(json['params']),
    };
}

export function DrillingParamsResponseToJSON(value?: DrillingParamsResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'wellId': value['wellId'],
        'calculationId': value['calculationId'],
        'params': GraphMomentsAndWeightsRequestToJSON(value['params']),
    };
}

