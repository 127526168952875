/* tslint:disable */
/* eslint-disable */
/**
 * ReportPro API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import { DrillingPadEventContentFromJSONTyped } from './DrillingPadEventContent';
import { DrillingWellEventContentFromJSONTyped } from './DrillingWellEventContent';
import { EventIncidentContentFromJSONTyped } from './EventIncidentContent';
import { KgsArrangementEventContentFromJSONTyped } from './KgsArrangementEventContent';
import { PadDrillingRigAssemblingEventContentFromJSONTyped } from './PadDrillingRigAssemblingEventContent';
import { PadDrillingRigDisassemblingEventContentFromJSONTyped } from './PadDrillingRigDisassemblingEventContent';
import { PadKgsBuildingEventContentFromJSONTyped } from './PadKgsBuildingEventContent';
import { SchedulerEquipmentTechnicalWorkByDayContentFromJSONTyped } from './SchedulerEquipmentTechnicalWorkByDayContent';
import { SchedulerEquipmentTechnicalWorkContentFromJSONTyped } from './SchedulerEquipmentTechnicalWorkContent';
import { WellCompletionEventContentFromJSONTyped } from './WellCompletionEventContent';
/**
 * 
 * @export
 * @interface SchedulerEventContent
 */
export interface SchedulerEventContent {
    /**
     * 
     * @type {string}
     * @memberof SchedulerEventContent
     */
    type: string;
}

/**
 * Check if a given object implements the SchedulerEventContent interface.
 */
export function instanceOfSchedulerEventContent(value: object): value is SchedulerEventContent {
    if (!('type' in value) || value['type'] === undefined) return false;
    return true;
}

export function SchedulerEventContentFromJSON(json: any): SchedulerEventContent {
    return SchedulerEventContentFromJSONTyped(json, false);
}

export function SchedulerEventContentFromJSONTyped(json: any, ignoreDiscriminator: boolean): SchedulerEventContent {
    if (json == null) {
        return json;
    }
    if (!ignoreDiscriminator) {
        if (json['type'] === 'DrillingPadEventContent') {
            return DrillingPadEventContentFromJSONTyped(json, true);
        }
        if (json['type'] === 'DrillingWellEventContent') {
            return DrillingWellEventContentFromJSONTyped(json, true);
        }
        if (json['type'] === 'EventIncidentContent') {
            return EventIncidentContentFromJSONTyped(json, true);
        }
        if (json['type'] === 'KgsArrangementEventContent') {
            return KgsArrangementEventContentFromJSONTyped(json, true);
        }
        if (json['type'] === 'PadDrillingRigAssemblingEventContent') {
            return PadDrillingRigAssemblingEventContentFromJSONTyped(json, true);
        }
        if (json['type'] === 'PadDrillingRigDisassemblingEventContent') {
            return PadDrillingRigDisassemblingEventContentFromJSONTyped(json, true);
        }
        if (json['type'] === 'PadKgsBuildingEventContent') {
            return PadKgsBuildingEventContentFromJSONTyped(json, true);
        }
        if (json['type'] === 'SchedulerEquipmentTechnicalWorkByDayContent') {
            return SchedulerEquipmentTechnicalWorkByDayContentFromJSONTyped(json, true);
        }
        if (json['type'] === 'SchedulerEquipmentTechnicalWorkContent') {
            return SchedulerEquipmentTechnicalWorkContentFromJSONTyped(json, true);
        }
        if (json['type'] === 'WellCompletionEventContent') {
            return WellCompletionEventContentFromJSONTyped(json, true);
        }
    }
    return {
        
        'type': json['type'],
    };
}

export function SchedulerEventContentToJSON(value?: SchedulerEventContent | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'type': value['type'],
    };
}

