/* tslint:disable */
/* eslint-disable */
/**
 * ReportPro API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CurveResponse } from './CurveResponse';
import {
    CurveResponseFromJSON,
    CurveResponseFromJSONTyped,
    CurveResponseToJSON,
} from './CurveResponse';

/**
 * 
 * @export
 * @interface TrackResponse
 */
export interface TrackResponse {
    /**
     * 
     * @type {string}
     * @memberof TrackResponse
     */
    id?: string;
    /**
     * 
     * @type {Array<CurveResponse>}
     * @memberof TrackResponse
     */
    curves?: Array<CurveResponse>;
}

/**
 * Check if a given object implements the TrackResponse interface.
 */
export function instanceOfTrackResponse(value: object): value is TrackResponse {
    return true;
}

export function TrackResponseFromJSON(json: any): TrackResponse {
    return TrackResponseFromJSONTyped(json, false);
}

export function TrackResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): TrackResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'curves': json['curves'] == null ? undefined : ((json['curves'] as Array<any>).map(CurveResponseFromJSON)),
    };
}

export function TrackResponseToJSON(value?: TrackResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'curves': value['curves'] == null ? undefined : ((value['curves'] as Array<any>).map(CurveResponseToJSON)),
    };
}

