/* tslint:disable */
/* eslint-disable */
/**
 * ReportPro API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateCurveRequest
 */
export interface CreateCurveRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateCurveRequest
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CreateCurveRequest
     */
    mnemonicId: string;
    /**
     * 
     * @type {string}
     * @memberof CreateCurveRequest
     */
    unitId: string;
    /**
     * 
     * @type {string}
     * @memberof CreateCurveRequest
     */
    lineTypeId: string;
    /**
     * 
     * @type {string}
     * @memberof CreateCurveRequest
     */
    lineColor: string;
    /**
     * 
     * @type {number}
     * @memberof CreateCurveRequest
     */
    lineThickness?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateCurveRequest
     */
    limitMin?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateCurveRequest
     */
    limitMax?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CreateCurveRequest
     */
    limitMinAuto?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateCurveRequest
     */
    limitMaxAuto?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateCurveRequest
     */
    extLimit?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CreateCurveRequest
     */
    curveLimitMin?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateCurveRequest
     */
    curveLimitMax?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateCurveRequest
     */
    curveLimitColor?: string;
}

/**
 * Check if a given object implements the CreateCurveRequest interface.
 */
export function instanceOfCreateCurveRequest(value: object): value is CreateCurveRequest {
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('mnemonicId' in value) || value['mnemonicId'] === undefined) return false;
    if (!('unitId' in value) || value['unitId'] === undefined) return false;
    if (!('lineTypeId' in value) || value['lineTypeId'] === undefined) return false;
    if (!('lineColor' in value) || value['lineColor'] === undefined) return false;
    return true;
}

export function CreateCurveRequestFromJSON(json: any): CreateCurveRequest {
    return CreateCurveRequestFromJSONTyped(json, false);
}

export function CreateCurveRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateCurveRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'],
        'mnemonicId': json['mnemonicId'],
        'unitId': json['unitId'],
        'lineTypeId': json['lineTypeId'],
        'lineColor': json['lineColor'],
        'lineThickness': json['lineThickness'] == null ? undefined : json['lineThickness'],
        'limitMin': json['limitMin'] == null ? undefined : json['limitMin'],
        'limitMax': json['limitMax'] == null ? undefined : json['limitMax'],
        'limitMinAuto': json['limitMinAuto'] == null ? undefined : json['limitMinAuto'],
        'limitMaxAuto': json['limitMaxAuto'] == null ? undefined : json['limitMaxAuto'],
        'extLimit': json['extLimit'] == null ? undefined : json['extLimit'],
        'curveLimitMin': json['curveLimitMin'] == null ? undefined : json['curveLimitMin'],
        'curveLimitMax': json['curveLimitMax'] == null ? undefined : json['curveLimitMax'],
        'curveLimitColor': json['curveLimitColor'] == null ? undefined : json['curveLimitColor'],
    };
}

export function CreateCurveRequestToJSON(value?: CreateCurveRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'mnemonicId': value['mnemonicId'],
        'unitId': value['unitId'],
        'lineTypeId': value['lineTypeId'],
        'lineColor': value['lineColor'],
        'lineThickness': value['lineThickness'],
        'limitMin': value['limitMin'],
        'limitMax': value['limitMax'],
        'limitMinAuto': value['limitMinAuto'],
        'limitMaxAuto': value['limitMaxAuto'],
        'extLimit': value['extLimit'],
        'curveLimitMin': value['curveLimitMin'],
        'curveLimitMax': value['curveLimitMax'],
        'curveLimitColor': value['curveLimitColor'],
    };
}

