/* tslint:disable */
/* eslint-disable */
/**
 * ReportPro API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AutoDrillingProgramOperationToCreate
 */
export interface AutoDrillingProgramOperationToCreate {
    /**
     * 
     * @type {string}
     * @memberof AutoDrillingProgramOperationToCreate
     */
    type: AutoDrillingProgramOperationToCreateTypeEnum;
}


/**
 * @export
 */
export const AutoDrillingProgramOperationToCreateTypeEnum = {
    PumpStart: 'PUMP_START',
    SwitchingToTheMode: 'SWITCHING_TO_THE_MODE',
    OscillationAngles: 'OSCILLATION_ANGLES',
    RotorRpmAcceleration: 'ROTOR_RPM_ACCELERATION',
    RotorLoweringBit: 'ROTOR_LOWERING_BIT',
    Rotor: 'ROTOR',
    LoadCapacity: 'LOAD_CAPACITY',
    ReamingRotor: 'REAMING_ROTOR',
    PositioningOffTheBottom: 'POSITIONING_OFF_THE_BOTTOM',
    TfOrientation: 'TF_ORIENTATION',
    SlideLoweringBit: 'SLIDE_LOWERING_BIT',
    Slide: 'SLIDE',
    ReamingSlide: 'REAMING_SLIDE',
    RecordingStaticMeasurement: 'RECORDING_STATIC_MEASUREMENT',
    SwitchingOffThePump: 'SWITCHING_OFF_THE_PUMP'
} as const;
export type AutoDrillingProgramOperationToCreateTypeEnum = typeof AutoDrillingProgramOperationToCreateTypeEnum[keyof typeof AutoDrillingProgramOperationToCreateTypeEnum];


/**
 * Check if a given object implements the AutoDrillingProgramOperationToCreate interface.
 */
export function instanceOfAutoDrillingProgramOperationToCreate(value: object): value is AutoDrillingProgramOperationToCreate {
    if (!('type' in value) || value['type'] === undefined) return false;
    return true;
}

export function AutoDrillingProgramOperationToCreateFromJSON(json: any): AutoDrillingProgramOperationToCreate {
    return AutoDrillingProgramOperationToCreateFromJSONTyped(json, false);
}

export function AutoDrillingProgramOperationToCreateFromJSONTyped(json: any, ignoreDiscriminator: boolean): AutoDrillingProgramOperationToCreate {
    if (json == null) {
        return json;
    }
    return {
        
        'type': json['type'],
    };
}

export function AutoDrillingProgramOperationToCreateToJSON(value?: AutoDrillingProgramOperationToCreate | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'type': value['type'],
    };
}

