/* tslint:disable */
/* eslint-disable */
/**
 * ReportPro API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { DrmRevisionSectionDto } from './DrmRevisionSectionDto';
import {
    DrmRevisionSectionDtoFromJSON,
    DrmRevisionSectionDtoFromJSONTyped,
    DrmRevisionSectionDtoToJSON,
} from './DrmRevisionSectionDto';

/**
 * 
 * @export
 * @interface DrmRevisionDto
 */
export interface DrmRevisionDto {
    /**
     * 
     * @type {string}
     * @memberof DrmRevisionDto
     */
    id?: string;
    /**
     * 
     * @type {number}
     * @memberof DrmRevisionDto
     */
    wellId?: number;
    /**
     * 
     * @type {Date}
     * @memberof DrmRevisionDto
     */
    actualFor?: Date;
    /**
     * 
     * @type {string}
     * @memberof DrmRevisionDto
     */
    status?: DrmRevisionDtoStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof DrmRevisionDto
     */
    wellbore?: string;
    /**
     * 
     * @type {number}
     * @memberof DrmRevisionDto
     */
    intervalStep?: number;
    /**
     * 
     * @type {number}
     * @memberof DrmRevisionDto
     */
    startDepth?: number;
    /**
     * 
     * @type {number}
     * @memberof DrmRevisionDto
     */
    endDepth?: number;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof DrmRevisionDto
     */
    drms?: { [key: string]: string; };
    /**
     * 
     * @type {Array<DrmRevisionSectionDto>}
     * @memberof DrmRevisionDto
     */
    revisionSections?: Array<DrmRevisionSectionDto>;
}


/**
 * @export
 */
export const DrmRevisionDtoStatusEnum = {
    Agreed: 'AGREED',
    Draft: 'DRAFT'
} as const;
export type DrmRevisionDtoStatusEnum = typeof DrmRevisionDtoStatusEnum[keyof typeof DrmRevisionDtoStatusEnum];

/**
 * @export
 */
export const DrmRevisionDtoDrmsEnum = {
    Drm: 'DRM',
    AkbMode: 'AKB_MODE'
} as const;
export type DrmRevisionDtoDrmsEnum = typeof DrmRevisionDtoDrmsEnum[keyof typeof DrmRevisionDtoDrmsEnum];


/**
 * Check if a given object implements the DrmRevisionDto interface.
 */
export function instanceOfDrmRevisionDto(value: object): value is DrmRevisionDto {
    return true;
}

export function DrmRevisionDtoFromJSON(json: any): DrmRevisionDto {
    return DrmRevisionDtoFromJSONTyped(json, false);
}

export function DrmRevisionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): DrmRevisionDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'wellId': json['wellId'] == null ? undefined : json['wellId'],
        'actualFor': json['actualFor'] == null ? undefined : (new Date(json['actualFor'])),
        'status': json['status'] == null ? undefined : json['status'],
        'wellbore': json['wellbore'] == null ? undefined : json['wellbore'],
        'intervalStep': json['intervalStep'] == null ? undefined : json['intervalStep'],
        'startDepth': json['startDepth'] == null ? undefined : json['startDepth'],
        'endDepth': json['endDepth'] == null ? undefined : json['endDepth'],
        'drms': json['drms'] == null ? undefined : json['drms'],
        'revisionSections': json['revisionSections'] == null ? undefined : ((json['revisionSections'] as Array<any>).map(DrmRevisionSectionDtoFromJSON)),
    };
}

export function DrmRevisionDtoToJSON(value?: DrmRevisionDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'wellId': value['wellId'],
        'actualFor': value['actualFor'] == null ? undefined : ((value['actualFor']).toISOString().substring(0,10)),
        'status': value['status'],
        'wellbore': value['wellbore'],
        'intervalStep': value['intervalStep'],
        'startDepth': value['startDepth'],
        'endDepth': value['endDepth'],
        'drms': value['drms'],
        'revisionSections': value['revisionSections'] == null ? undefined : ((value['revisionSections'] as Array<any>).map(DrmRevisionSectionDtoToJSON)),
    };
}

