/* tslint:disable */
/* eslint-disable */
/**
 * ReportPro API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WellAndTimePeriodInfoRequest
 */
export interface WellAndTimePeriodInfoRequest {
    /**
     * 
     * @type {number}
     * @memberof WellAndTimePeriodInfoRequest
     */
    wellId?: number;
    /**
     * 
     * @type {string}
     * @memberof WellAndTimePeriodInfoRequest
     */
    wellboreId: string;
    /**
     * 
     * @type {string}
     * @memberof WellAndTimePeriodInfoRequest
     */
    logFormatId: string;
    /**
     * 
     * @type {string}
     * @memberof WellAndTimePeriodInfoRequest
     */
    startIndex?: string;
    /**
     * 
     * @type {string}
     * @memberof WellAndTimePeriodInfoRequest
     */
    endIndex?: string;
    /**
     * 
     * @type {Set<string>}
     * @memberof WellAndTimePeriodInfoRequest
     */
    mnemonics: Set<string>;
}

/**
 * Check if a given object implements the WellAndTimePeriodInfoRequest interface.
 */
export function instanceOfWellAndTimePeriodInfoRequest(value: object): value is WellAndTimePeriodInfoRequest {
    if (!('wellboreId' in value) || value['wellboreId'] === undefined) return false;
    if (!('logFormatId' in value) || value['logFormatId'] === undefined) return false;
    if (!('mnemonics' in value) || value['mnemonics'] === undefined) return false;
    return true;
}

export function WellAndTimePeriodInfoRequestFromJSON(json: any): WellAndTimePeriodInfoRequest {
    return WellAndTimePeriodInfoRequestFromJSONTyped(json, false);
}

export function WellAndTimePeriodInfoRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): WellAndTimePeriodInfoRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'wellId': json['wellId'] == null ? undefined : json['wellId'],
        'wellboreId': json['wellboreId'],
        'logFormatId': json['logFormatId'],
        'startIndex': json['startIndex'] == null ? undefined : json['startIndex'],
        'endIndex': json['endIndex'] == null ? undefined : json['endIndex'],
        'mnemonics': json['mnemonics'],
    };
}

export function WellAndTimePeriodInfoRequestToJSON(value?: WellAndTimePeriodInfoRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'wellId': value['wellId'],
        'wellboreId': value['wellboreId'],
        'logFormatId': value['logFormatId'],
        'startIndex': value['startIndex'],
        'endIndex': value['endIndex'],
        'mnemonics': Array.from(value['mnemonics'] as Set<any>),
    };
}

