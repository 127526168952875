/* tslint:disable */
/* eslint-disable */
/**
 * ReportPro API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  DataBorderRequest,
  DataBorderResponse,
  ErrorResponse,
  LasFileDTO,
  MnemonicDataResponse,
  ValidationErrorResponse,
  WellAndTimePeriodInfoRequest,
} from '../models/index';
import {
    DataBorderRequestFromJSON,
    DataBorderRequestToJSON,
    DataBorderResponseFromJSON,
    DataBorderResponseToJSON,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    LasFileDTOFromJSON,
    LasFileDTOToJSON,
    MnemonicDataResponseFromJSON,
    MnemonicDataResponseToJSON,
    ValidationErrorResponseFromJSON,
    ValidationErrorResponseToJSON,
    WellAndTimePeriodInfoRequestFromJSON,
    WellAndTimePeriodInfoRequestToJSON,
} from '../models/index';

export interface FindAllLasFilesRequest {
    wellId: number;
}

export interface FindLasDataBordersRequest {
    lasFileId: string;
    dataBorderRequest: DataBorderRequest;
}

export interface FindLasMnemonicDataRequest {
    lasFileId: string;
    wellAndTimePeriodInfoRequest: WellAndTimePeriodInfoRequest;
}

export interface UploadLasFileRequest {
    wellId: number;
    file: Blob;
}

/**
 * 
 */
export class APILASApi extends runtime.BaseAPI {

    /**
     */
    async findAllLasFilesRaw(requestParameters: FindAllLasFilesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<LasFileDTO>>> {
        if (requestParameters['wellId'] == null) {
            throw new runtime.RequiredError(
                'wellId',
                'Required parameter "wellId" was null or undefined when calling findAllLasFiles().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer Authentication", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/monitoring/las/{wellId}`.replace(`{${"wellId"}}`, encodeURIComponent(String(requestParameters['wellId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(LasFileDTOFromJSON));
    }

    /**
     */
    async findAllLasFiles(requestParameters: FindAllLasFilesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<LasFileDTO>> {
        const response = await this.findAllLasFilesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async findLasDataBordersRaw(requestParameters: FindLasDataBordersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DataBorderResponse>> {
        if (requestParameters['lasFileId'] == null) {
            throw new runtime.RequiredError(
                'lasFileId',
                'Required parameter "lasFileId" was null or undefined when calling findLasDataBorders().'
            );
        }

        if (requestParameters['dataBorderRequest'] == null) {
            throw new runtime.RequiredError(
                'dataBorderRequest',
                'Required parameter "dataBorderRequest" was null or undefined when calling findLasDataBorders().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer Authentication", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/monitoring/las/data/{lasFileId}/border/find`.replace(`{${"lasFileId"}}`, encodeURIComponent(String(requestParameters['lasFileId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DataBorderRequestToJSON(requestParameters['dataBorderRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DataBorderResponseFromJSON(jsonValue));
    }

    /**
     */
    async findLasDataBorders(requestParameters: FindLasDataBordersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DataBorderResponse> {
        const response = await this.findLasDataBordersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async findLasMnemonicDataRaw(requestParameters: FindLasMnemonicDataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MnemonicDataResponse>> {
        if (requestParameters['lasFileId'] == null) {
            throw new runtime.RequiredError(
                'lasFileId',
                'Required parameter "lasFileId" was null or undefined when calling findLasMnemonicData().'
            );
        }

        if (requestParameters['wellAndTimePeriodInfoRequest'] == null) {
            throw new runtime.RequiredError(
                'wellAndTimePeriodInfoRequest',
                'Required parameter "wellAndTimePeriodInfoRequest" was null or undefined when calling findLasMnemonicData().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer Authentication", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/monitoring/las/data/{lasFileId}`.replace(`{${"lasFileId"}}`, encodeURIComponent(String(requestParameters['lasFileId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WellAndTimePeriodInfoRequestToJSON(requestParameters['wellAndTimePeriodInfoRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MnemonicDataResponseFromJSON(jsonValue));
    }

    /**
     */
    async findLasMnemonicData(requestParameters: FindLasMnemonicDataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MnemonicDataResponse> {
        const response = await this.findLasMnemonicDataRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async uploadLasFileRaw(requestParameters: UploadLasFileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LasFileDTO>> {
        if (requestParameters['wellId'] == null) {
            throw new runtime.RequiredError(
                'wellId',
                'Required parameter "wellId" was null or undefined when calling uploadLasFile().'
            );
        }

        if (requestParameters['file'] == null) {
            throw new runtime.RequiredError(
                'file',
                'Required parameter "file" was null or undefined when calling uploadLasFile().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['wellId'] != null) {
            queryParameters['wellId'] = requestParameters['wellId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer Authentication", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters['file'] != null) {
            formParams.append('file', requestParameters['file'] as any);
        }

        const response = await this.request({
            path: `/api/monitoring/las/upload`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LasFileDTOFromJSON(jsonValue));
    }

    /**
     */
    async uploadLasFile(requestParameters: UploadLasFileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LasFileDTO> {
        const response = await this.uploadLasFileRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
