/* tslint:disable */
/* eslint-disable */
/**
 * ReportPro API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DrillingRigV2WithWellInfoResponse
 */
export interface DrillingRigV2WithWellInfoResponse {
    /**
     * 
     * @type {number}
     * @memberof DrillingRigV2WithWellInfoResponse
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof DrillingRigV2WithWellInfoResponse
     */
    rigName?: string;
    /**
     * 
     * @type {string}
     * @memberof DrillingRigV2WithWellInfoResponse
     */
    drillingCompany?: string;
    /**
     * 
     * @type {string}
     * @memberof DrillingRigV2WithWellInfoResponse
     */
    manufacturer?: string;
    /**
     * 
     * @type {string}
     * @memberof DrillingRigV2WithWellInfoResponse
     */
    rigPurpose?: string;
    /**
     * 
     * @type {string}
     * @memberof DrillingRigV2WithWellInfoResponse
     */
    rigType?: string;
    /**
     * 
     * @type {string}
     * @memberof DrillingRigV2WithWellInfoResponse
     */
    rigModel?: string;
    /**
     * 
     * @type {string}
     * @memberof DrillingRigV2WithWellInfoResponse
     */
    rigYear?: string;
    /**
     * 
     * @type {number}
     * @memberof DrillingRigV2WithWellInfoResponse
     */
    rigLiftingCapacity?: number;
    /**
     * 
     * @type {number}
     * @memberof DrillingRigV2WithWellInfoResponse
     */
    wellId?: number;
    /**
     * 
     * @type {string}
     * @memberof DrillingRigV2WithWellInfoResponse
     */
    wellName?: string;
    /**
     * 
     * @type {string}
     * @memberof DrillingRigV2WithWellInfoResponse
     */
    projectName?: string;
    /**
     * 
     * @type {Date}
     * @memberof DrillingRigV2WithWellInfoResponse
     */
    lastExpertise?: Date;
    /**
     * 
     * @type {Date}
     * @memberof DrillingRigV2WithWellInfoResponse
     */
    nextExpertise?: Date;
    /**
     * 
     * @type {string}
     * @memberof DrillingRigV2WithWellInfoResponse
     */
    fuelType?: string;
    /**
     * 
     * @type {number}
     * @memberof DrillingRigV2WithWellInfoResponse
     */
    fuelConsumption?: number;
}

/**
 * Check if a given object implements the DrillingRigV2WithWellInfoResponse interface.
 */
export function instanceOfDrillingRigV2WithWellInfoResponse(value: object): value is DrillingRigV2WithWellInfoResponse {
    return true;
}

export function DrillingRigV2WithWellInfoResponseFromJSON(json: any): DrillingRigV2WithWellInfoResponse {
    return DrillingRigV2WithWellInfoResponseFromJSONTyped(json, false);
}

export function DrillingRigV2WithWellInfoResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): DrillingRigV2WithWellInfoResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'rigName': json['rigName'] == null ? undefined : json['rigName'],
        'drillingCompany': json['drillingCompany'] == null ? undefined : json['drillingCompany'],
        'manufacturer': json['manufacturer'] == null ? undefined : json['manufacturer'],
        'rigPurpose': json['rigPurpose'] == null ? undefined : json['rigPurpose'],
        'rigType': json['rigType'] == null ? undefined : json['rigType'],
        'rigModel': json['rigModel'] == null ? undefined : json['rigModel'],
        'rigYear': json['rigYear'] == null ? undefined : json['rigYear'],
        'rigLiftingCapacity': json['rigLiftingCapacity'] == null ? undefined : json['rigLiftingCapacity'],
        'wellId': json['wellId'] == null ? undefined : json['wellId'],
        'wellName': json['wellName'] == null ? undefined : json['wellName'],
        'projectName': json['projectName'] == null ? undefined : json['projectName'],
        'lastExpertise': json['lastExpertise'] == null ? undefined : (new Date(json['lastExpertise'])),
        'nextExpertise': json['nextExpertise'] == null ? undefined : (new Date(json['nextExpertise'])),
        'fuelType': json['fuelType'] == null ? undefined : json['fuelType'],
        'fuelConsumption': json['fuelConsumption'] == null ? undefined : json['fuelConsumption'],
    };
}

export function DrillingRigV2WithWellInfoResponseToJSON(value?: DrillingRigV2WithWellInfoResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'rigName': value['rigName'],
        'drillingCompany': value['drillingCompany'],
        'manufacturer': value['manufacturer'],
        'rigPurpose': value['rigPurpose'],
        'rigType': value['rigType'],
        'rigModel': value['rigModel'],
        'rigYear': value['rigYear'],
        'rigLiftingCapacity': value['rigLiftingCapacity'],
        'wellId': value['wellId'],
        'wellName': value['wellName'],
        'projectName': value['projectName'],
        'lastExpertise': value['lastExpertise'] == null ? undefined : ((value['lastExpertise']).toISOString().substring(0,10)),
        'nextExpertise': value['nextExpertise'] == null ? undefined : ((value['nextExpertise']).toISOString().substring(0,10)),
        'fuelType': value['fuelType'],
        'fuelConsumption': value['fuelConsumption'],
    };
}

