/* tslint:disable */
/* eslint-disable */
/**
 * ReportPro API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Param
 */
export interface Param {
    /**
     * 
     * @type {string}
     * @memberof Param
     */
    key: string;
    /**
     * 
     * @type {string}
     * @memberof Param
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof Param
     */
    type: ParamTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof Param
     */
    isRequired?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Param
     */
    min?: number;
    /**
     * 
     * @type {number}
     * @memberof Param
     */
    max?: number;
}


/**
 * @export
 */
export const ParamTypeEnum = {
    Number: 'NUMBER'
} as const;
export type ParamTypeEnum = typeof ParamTypeEnum[keyof typeof ParamTypeEnum];


/**
 * Check if a given object implements the Param interface.
 */
export function instanceOfParam(value: object): value is Param {
    if (!('key' in value) || value['key'] === undefined) return false;
    if (!('title' in value) || value['title'] === undefined) return false;
    if (!('type' in value) || value['type'] === undefined) return false;
    return true;
}

export function ParamFromJSON(json: any): Param {
    return ParamFromJSONTyped(json, false);
}

export function ParamFromJSONTyped(json: any, ignoreDiscriminator: boolean): Param {
    if (json == null) {
        return json;
    }
    return {
        
        'key': json['key'],
        'title': json['title'],
        'type': json['type'],
        'isRequired': json['isRequired'] == null ? undefined : json['isRequired'],
        'min': json['min'] == null ? undefined : json['min'],
        'max': json['max'] == null ? undefined : json['max'],
    };
}

export function ParamToJSON(value?: Param | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'key': value['key'],
        'title': value['title'],
        'type': value['type'],
        'isRequired': value['isRequired'],
        'min': value['min'],
        'max': value['max'],
    };
}

