/* tslint:disable */
/* eslint-disable */
/**
 * ReportPro API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ErrorResponse,
  SchedulerItem,
  TechnicalMaintenance,
  TechnicalMaintenanceWork,
  TechnicalMaintenanceWorkRequest,
  ValidationErrorResponse,
} from '../models/index';
import {
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    SchedulerItemFromJSON,
    SchedulerItemToJSON,
    TechnicalMaintenanceFromJSON,
    TechnicalMaintenanceToJSON,
    TechnicalMaintenanceWorkFromJSON,
    TechnicalMaintenanceWorkToJSON,
    TechnicalMaintenanceWorkRequestFromJSON,
    TechnicalMaintenanceWorkRequestToJSON,
    ValidationErrorResponseFromJSON,
    ValidationErrorResponseToJSON,
} from '../models/index';

export interface AddWorkRequest {
    timeNormativeId: string;
    technicalMaintenanceWorkRequest: TechnicalMaintenanceWorkRequest;
}

export interface DeleteWorkRequest {
    workId: string;
}

export interface FindAllByDrillingRigEquipmentIdRequest {
    drillingRigEquipmentId: number;
}

export interface FindById1Request {
    timeNormativeId: string;
}

export interface GetCalendarViewRequest {
    drillingRigEquipmentId: number;
}

export interface SetIsNotBeenMonitoredRequest {
    timeNormativeId: string;
    value: boolean;
}

export interface UpdateWorkRequest {
    workId: string;
    technicalMaintenanceWorkRequest: TechnicalMaintenanceWorkRequest;
}

/**
 * 
 */
export class DrillingRigTechnicalMaintenanceWorkControllerApi extends runtime.BaseAPI {

    /**
     */
    async addWorkRaw(requestParameters: AddWorkRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TechnicalMaintenanceWork>> {
        if (requestParameters['timeNormativeId'] == null) {
            throw new runtime.RequiredError(
                'timeNormativeId',
                'Required parameter "timeNormativeId" was null or undefined when calling addWork().'
            );
        }

        if (requestParameters['technicalMaintenanceWorkRequest'] == null) {
            throw new runtime.RequiredError(
                'technicalMaintenanceWorkRequest',
                'Required parameter "technicalMaintenanceWorkRequest" was null or undefined when calling addWork().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer Authentication", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/technical-maintenance/{timeNormativeId}/works`.replace(`{${"timeNormativeId"}}`, encodeURIComponent(String(requestParameters['timeNormativeId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TechnicalMaintenanceWorkRequestToJSON(requestParameters['technicalMaintenanceWorkRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TechnicalMaintenanceWorkFromJSON(jsonValue));
    }

    /**
     */
    async addWork(requestParameters: AddWorkRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TechnicalMaintenanceWork> {
        const response = await this.addWorkRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteWorkRaw(requestParameters: DeleteWorkRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['workId'] == null) {
            throw new runtime.RequiredError(
                'workId',
                'Required parameter "workId" was null or undefined when calling deleteWork().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer Authentication", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/technical-maintenance/works/{workId}`.replace(`{${"workId"}}`, encodeURIComponent(String(requestParameters['workId']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteWork(requestParameters: DeleteWorkRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteWorkRaw(requestParameters, initOverrides);
    }

    /**
     */
    async findAllByDrillingRigEquipmentIdRaw(requestParameters: FindAllByDrillingRigEquipmentIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<TechnicalMaintenance>>> {
        if (requestParameters['drillingRigEquipmentId'] == null) {
            throw new runtime.RequiredError(
                'drillingRigEquipmentId',
                'Required parameter "drillingRigEquipmentId" was null or undefined when calling findAllByDrillingRigEquipmentId().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['drillingRigEquipmentId'] != null) {
            queryParameters['drillingRigEquipmentId'] = requestParameters['drillingRigEquipmentId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer Authentication", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/technical-maintenance/findAllByDrillingRigEquipmentId`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TechnicalMaintenanceFromJSON));
    }

    /**
     */
    async findAllByDrillingRigEquipmentId(requestParameters: FindAllByDrillingRigEquipmentIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<TechnicalMaintenance>> {
        const response = await this.findAllByDrillingRigEquipmentIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async findById1Raw(requestParameters: FindById1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TechnicalMaintenance>> {
        if (requestParameters['timeNormativeId'] == null) {
            throw new runtime.RequiredError(
                'timeNormativeId',
                'Required parameter "timeNormativeId" was null or undefined when calling findById1().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer Authentication", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/technical-maintenance/{timeNormativeId}`.replace(`{${"timeNormativeId"}}`, encodeURIComponent(String(requestParameters['timeNormativeId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TechnicalMaintenanceFromJSON(jsonValue));
    }

    /**
     */
    async findById1(requestParameters: FindById1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TechnicalMaintenance> {
        const response = await this.findById1Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getCalendarViewRaw(requestParameters: GetCalendarViewRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SchedulerItem>>> {
        if (requestParameters['drillingRigEquipmentId'] == null) {
            throw new runtime.RequiredError(
                'drillingRigEquipmentId',
                'Required parameter "drillingRigEquipmentId" was null or undefined when calling getCalendarView().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['drillingRigEquipmentId'] != null) {
            queryParameters['drillingRigEquipmentId'] = requestParameters['drillingRigEquipmentId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer Authentication", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/technical-maintenance/calendar-view`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SchedulerItemFromJSON));
    }

    /**
     */
    async getCalendarView(requestParameters: GetCalendarViewRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SchedulerItem>> {
        const response = await this.getCalendarViewRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async setIsNotBeenMonitoredRaw(requestParameters: SetIsNotBeenMonitoredRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TechnicalMaintenance>> {
        if (requestParameters['timeNormativeId'] == null) {
            throw new runtime.RequiredError(
                'timeNormativeId',
                'Required parameter "timeNormativeId" was null or undefined when calling setIsNotBeenMonitored().'
            );
        }

        if (requestParameters['value'] == null) {
            throw new runtime.RequiredError(
                'value',
                'Required parameter "value" was null or undefined when calling setIsNotBeenMonitored().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['value'] != null) {
            queryParameters['value'] = requestParameters['value'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer Authentication", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/technical-maintenance/{timeNormativeId}/set-is-not-been-monitored`.replace(`{${"timeNormativeId"}}`, encodeURIComponent(String(requestParameters['timeNormativeId']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TechnicalMaintenanceFromJSON(jsonValue));
    }

    /**
     */
    async setIsNotBeenMonitored(requestParameters: SetIsNotBeenMonitoredRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TechnicalMaintenance> {
        const response = await this.setIsNotBeenMonitoredRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateWorkRaw(requestParameters: UpdateWorkRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TechnicalMaintenanceWork>> {
        if (requestParameters['workId'] == null) {
            throw new runtime.RequiredError(
                'workId',
                'Required parameter "workId" was null or undefined when calling updateWork().'
            );
        }

        if (requestParameters['technicalMaintenanceWorkRequest'] == null) {
            throw new runtime.RequiredError(
                'technicalMaintenanceWorkRequest',
                'Required parameter "technicalMaintenanceWorkRequest" was null or undefined when calling updateWork().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer Authentication", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/technical-maintenance/works/{workId}`.replace(`{${"workId"}}`, encodeURIComponent(String(requestParameters['workId']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: TechnicalMaintenanceWorkRequestToJSON(requestParameters['technicalMaintenanceWorkRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TechnicalMaintenanceWorkFromJSON(jsonValue));
    }

    /**
     */
    async updateWork(requestParameters: UpdateWorkRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TechnicalMaintenanceWork> {
        const response = await this.updateWorkRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
