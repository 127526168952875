/* tslint:disable */
/* eslint-disable */
/**
 * ReportPro API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SummaryTableTittleDto
 */
export interface SummaryTableTittleDto {
    /**
     * 
     * @type {string}
     * @memberof SummaryTableTittleDto
     */
    titleName?: string;
    /**
     * 
     * @type {string}
     * @memberof SummaryTableTittleDto
     */
    reference?: string;
    /**
     * 
     * @type {Array<SummaryTableTittleDto>}
     * @memberof SummaryTableTittleDto
     */
    subTittles?: Array<SummaryTableTittleDto>;
}

/**
 * Check if a given object implements the SummaryTableTittleDto interface.
 */
export function instanceOfSummaryTableTittleDto(value: object): value is SummaryTableTittleDto {
    return true;
}

export function SummaryTableTittleDtoFromJSON(json: any): SummaryTableTittleDto {
    return SummaryTableTittleDtoFromJSONTyped(json, false);
}

export function SummaryTableTittleDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SummaryTableTittleDto {
    if (json == null) {
        return json;
    }
    return {
        
        'titleName': json['titleName'] == null ? undefined : json['titleName'],
        'reference': json['reference'] == null ? undefined : json['reference'],
        'subTittles': json['subTittles'] == null ? undefined : ((json['subTittles'] as Array<any>).map(SummaryTableTittleDtoFromJSON)),
    };
}

export function SummaryTableTittleDtoToJSON(value?: SummaryTableTittleDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'titleName': value['titleName'],
        'reference': value['reference'],
        'subTittles': value['subTittles'] == null ? undefined : ((value['subTittles'] as Array<any>).map(SummaryTableTittleDtoToJSON)),
    };
}

