/* tslint:disable */
/* eslint-disable */
/**
 * ReportPro API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { SchedulerEventContent } from './SchedulerEventContent';
import {
    SchedulerEventContentFromJSON,
    SchedulerEventContentFromJSONTyped,
    SchedulerEventContentToJSON,
} from './SchedulerEventContent';

/**
 * 
 * @export
 * @interface PadKgsBuildingEventContent
 */
export interface PadKgsBuildingEventContent extends SchedulerEventContent {
    /**
     * 
     * @type {number}
     * @memberof PadKgsBuildingEventContent
     */
    padId: number;
    /**
     * 
     * @type {string}
     * @memberof PadKgsBuildingEventContent
     */
    padTitle: string;
    /**
     * 
     * @type {number}
     * @memberof PadKgsBuildingEventContent
     */
    fieldId: number;
    /**
     * 
     * @type {string}
     * @memberof PadKgsBuildingEventContent
     */
    fieldTitle: string;
    /**
     * 
     * @type {Date}
     * @memberof PadKgsBuildingEventContent
     */
    dateFrom: Date;
    /**
     * 
     * @type {Date}
     * @memberof PadKgsBuildingEventContent
     */
    dateTo: Date;
}

/**
 * Check if a given object implements the PadKgsBuildingEventContent interface.
 */
export function instanceOfPadKgsBuildingEventContent(value: object): value is PadKgsBuildingEventContent {
    if (!('padId' in value) || value['padId'] === undefined) return false;
    if (!('padTitle' in value) || value['padTitle'] === undefined) return false;
    if (!('fieldId' in value) || value['fieldId'] === undefined) return false;
    if (!('fieldTitle' in value) || value['fieldTitle'] === undefined) return false;
    if (!('dateFrom' in value) || value['dateFrom'] === undefined) return false;
    if (!('dateTo' in value) || value['dateTo'] === undefined) return false;
    return true;
}

export function PadKgsBuildingEventContentFromJSON(json: any): PadKgsBuildingEventContent {
    return PadKgsBuildingEventContentFromJSONTyped(json, false);
}

export function PadKgsBuildingEventContentFromJSONTyped(json: any, ignoreDiscriminator: boolean): PadKgsBuildingEventContent {
    if (json == null) {
        return json;
    }
    return {
        ...SchedulerEventContentFromJSONTyped(json, ignoreDiscriminator),
        'padId': json['padId'],
        'padTitle': json['padTitle'],
        'fieldId': json['fieldId'],
        'fieldTitle': json['fieldTitle'],
        'dateFrom': (new Date(json['dateFrom'])),
        'dateTo': (new Date(json['dateTo'])),
    };
}

export function PadKgsBuildingEventContentToJSON(value?: PadKgsBuildingEventContent | null): any {
    if (value == null) {
        return value;
    }
    return {
        ...SchedulerEventContentToJSON(value),
        'padId': value['padId'],
        'padTitle': value['padTitle'],
        'fieldId': value['fieldId'],
        'fieldTitle': value['fieldTitle'],
        'dateFrom': ((value['dateFrom']).toISOString()),
        'dateTo': ((value['dateTo']).toISOString()),
    };
}

