/* tslint:disable */
/* eslint-disable */
/**
 * ReportPro API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { SchedulerItemContent } from './SchedulerItemContent';
import {
    SchedulerItemContentFromJSON,
    SchedulerItemContentFromJSONTyped,
    SchedulerItemContentToJSON,
} from './SchedulerItemContent';

/**
 * 
 * @export
 * @interface DrillingRigContent
 */
export interface DrillingRigContent extends SchedulerItemContent {
    /**
     * 
     * @type {number}
     * @memberof DrillingRigContent
     */
    id: number;
}

/**
 * Check if a given object implements the DrillingRigContent interface.
 */
export function instanceOfDrillingRigContent(value: object): value is DrillingRigContent {
    if (!('id' in value) || value['id'] === undefined) return false;
    return true;
}

export function DrillingRigContentFromJSON(json: any): DrillingRigContent {
    return DrillingRigContentFromJSONTyped(json, false);
}

export function DrillingRigContentFromJSONTyped(json: any, ignoreDiscriminator: boolean): DrillingRigContent {
    if (json == null) {
        return json;
    }
    return {
        ...SchedulerItemContentFromJSONTyped(json, ignoreDiscriminator),
        'id': json['id'],
    };
}

export function DrillingRigContentToJSON(value?: DrillingRigContent | null): any {
    if (value == null) {
        return value;
    }
    return {
        ...SchedulerItemContentToJSON(value),
        'id': value['id'],
    };
}

