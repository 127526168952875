/* tslint:disable */
/* eslint-disable */
/**
 * ReportPro API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { TrackResponse } from './TrackResponse';
import {
    TrackResponseFromJSON,
    TrackResponseFromJSONTyped,
    TrackResponseToJSON,
} from './TrackResponse';
import type { TemplateScaleEntity } from './TemplateScaleEntity';
import {
    TemplateScaleEntityFromJSON,
    TemplateScaleEntityFromJSONTyped,
    TemplateScaleEntityToJSON,
} from './TemplateScaleEntity';

/**
 * 
 * @export
 * @interface TemplateResponse
 */
export interface TemplateResponse {
    /**
     * 
     * @type {string}
     * @memberof TemplateResponse
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof TemplateResponse
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof TemplateResponse
     */
    description?: string;
    /**
     * 
     * @type {TemplateScaleEntity}
     * @memberof TemplateResponse
     */
    templateScale?: TemplateScaleEntity;
    /**
     * 
     * @type {string}
     * @memberof TemplateResponse
     */
    userOwner?: string;
    /**
     * 
     * @type {Array<TrackResponse>}
     * @memberof TemplateResponse
     */
    tracks?: Array<TrackResponse>;
    /**
     * 
     * @type {boolean}
     * @memberof TemplateResponse
     */
    basic?: boolean;
}

/**
 * Check if a given object implements the TemplateResponse interface.
 */
export function instanceOfTemplateResponse(value: object): value is TemplateResponse {
    return true;
}

export function TemplateResponseFromJSON(json: any): TemplateResponse {
    return TemplateResponseFromJSONTyped(json, false);
}

export function TemplateResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): TemplateResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'name': json['name'] == null ? undefined : json['name'],
        'description': json['description'] == null ? undefined : json['description'],
        'templateScale': json['templateScale'] == null ? undefined : TemplateScaleEntityFromJSON(json['templateScale']),
        'userOwner': json['userOwner'] == null ? undefined : json['userOwner'],
        'tracks': json['tracks'] == null ? undefined : ((json['tracks'] as Array<any>).map(TrackResponseFromJSON)),
        'basic': json['basic'] == null ? undefined : json['basic'],
    };
}

export function TemplateResponseToJSON(value?: TemplateResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'name': value['name'],
        'description': value['description'],
        'templateScale': TemplateScaleEntityToJSON(value['templateScale']),
        'userOwner': value['userOwner'],
        'tracks': value['tracks'] == null ? undefined : ((value['tracks'] as Array<any>).map(TrackResponseToJSON)),
        'basic': value['basic'],
    };
}

