/* tslint:disable */
/* eslint-disable */
/**
 * ReportPro API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AutoDrillingFunctionView,
  AutoDrillingOperationView,
  AutoDrillingStageView,
  AutoDrillingTemplate,
  ErrorResponse,
  ValidationErrorResponse,
} from '../models/index';
import {
    AutoDrillingFunctionViewFromJSON,
    AutoDrillingFunctionViewToJSON,
    AutoDrillingOperationViewFromJSON,
    AutoDrillingOperationViewToJSON,
    AutoDrillingStageViewFromJSON,
    AutoDrillingStageViewToJSON,
    AutoDrillingTemplateFromJSON,
    AutoDrillingTemplateToJSON,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    ValidationErrorResponseFromJSON,
    ValidationErrorResponseToJSON,
} from '../models/index';

export interface CreateTemplate1Request {
    autoDrillingTemplate: AutoDrillingTemplate;
}

export interface DeleteTemplate1Request {
    id: string;
}

export interface FindAllFunctionsByStageRequest {
    operation: FindAllFunctionsByStageOperationEnum;
}

export interface FindAllOperationsByStageRequest {
    stage: FindAllOperationsByStageStageEnum;
}

export interface UpdateTemplateRequest {
    id: string;
    autoDrillingTemplate: AutoDrillingTemplate;
}

/**
 * 
 */
export class AutoDrillingControllerApi extends runtime.BaseAPI {

    /**
     */
    async createTemplate1Raw(requestParameters: CreateTemplate1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AutoDrillingTemplate>> {
        if (requestParameters['autoDrillingTemplate'] == null) {
            throw new runtime.RequiredError(
                'autoDrillingTemplate',
                'Required parameter "autoDrillingTemplate" was null or undefined when calling createTemplate1().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer Authentication", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/auto-drilling/templates`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AutoDrillingTemplateToJSON(requestParameters['autoDrillingTemplate']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AutoDrillingTemplateFromJSON(jsonValue));
    }

    /**
     */
    async createTemplate1(requestParameters: CreateTemplate1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AutoDrillingTemplate> {
        const response = await this.createTemplate1Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteTemplate1Raw(requestParameters: DeleteTemplate1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling deleteTemplate1().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer Authentication", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/auto-drilling/templates/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteTemplate1(requestParameters: DeleteTemplate1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteTemplate1Raw(requestParameters, initOverrides);
    }

    /**
     */
    async findAllFunctionsByStageRaw(requestParameters: FindAllFunctionsByStageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<AutoDrillingFunctionView>>> {
        if (requestParameters['operation'] == null) {
            throw new runtime.RequiredError(
                'operation',
                'Required parameter "operation" was null or undefined when calling findAllFunctionsByStage().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['operation'] != null) {
            queryParameters['operation'] = requestParameters['operation'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer Authentication", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/auto-drilling/stages/operations/functions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AutoDrillingFunctionViewFromJSON));
    }

    /**
     */
    async findAllFunctionsByStage(requestParameters: FindAllFunctionsByStageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<AutoDrillingFunctionView>> {
        const response = await this.findAllFunctionsByStageRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async findAllOperationsByStageRaw(requestParameters: FindAllOperationsByStageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<AutoDrillingOperationView>>> {
        if (requestParameters['stage'] == null) {
            throw new runtime.RequiredError(
                'stage',
                'Required parameter "stage" was null or undefined when calling findAllOperationsByStage().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['stage'] != null) {
            queryParameters['stage'] = requestParameters['stage'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer Authentication", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/auto-drilling/stages/operations`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AutoDrillingOperationViewFromJSON));
    }

    /**
     */
    async findAllOperationsByStage(requestParameters: FindAllOperationsByStageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<AutoDrillingOperationView>> {
        const response = await this.findAllOperationsByStageRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async findAllStagesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<AutoDrillingStageView>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer Authentication", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/auto-drilling/stages`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AutoDrillingStageViewFromJSON));
    }

    /**
     */
    async findAllStages(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<AutoDrillingStageView>> {
        const response = await this.findAllStagesRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async findAllTemplates2Raw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<AutoDrillingTemplate>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer Authentication", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/auto-drilling/templates`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AutoDrillingTemplateFromJSON));
    }

    /**
     */
    async findAllTemplates2(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<AutoDrillingTemplate>> {
        const response = await this.findAllTemplates2Raw(initOverrides);
        return await response.value();
    }

    /**
     */
    async updateTemplateRaw(requestParameters: UpdateTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AutoDrillingTemplate>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling updateTemplate().'
            );
        }

        if (requestParameters['autoDrillingTemplate'] == null) {
            throw new runtime.RequiredError(
                'autoDrillingTemplate',
                'Required parameter "autoDrillingTemplate" was null or undefined when calling updateTemplate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer Authentication", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/auto-drilling/templates/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AutoDrillingTemplateToJSON(requestParameters['autoDrillingTemplate']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AutoDrillingTemplateFromJSON(jsonValue));
    }

    /**
     */
    async updateTemplate(requestParameters: UpdateTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AutoDrillingTemplate> {
        const response = await this.updateTemplateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
 * @export
 */
export const FindAllFunctionsByStageOperationEnum = {
    PumpStart: 'PUMP_START',
    SwitchingToTheMode: 'SWITCHING_TO_THE_MODE',
    OscillationAngles: 'OSCILLATION_ANGLES',
    RotorRpmAcceleration: 'ROTOR_RPM_ACCELERATION',
    RotorLoweringBit: 'ROTOR_LOWERING_BIT',
    Rotor: 'ROTOR',
    LoadCapacity: 'LOAD_CAPACITY',
    ReamingRotor: 'REAMING_ROTOR',
    PositioningOffTheBottom: 'POSITIONING_OFF_THE_BOTTOM',
    TfOrientation: 'TF_ORIENTATION',
    SlideLoweringBit: 'SLIDE_LOWERING_BIT',
    Slide: 'SLIDE',
    ReamingSlide: 'REAMING_SLIDE',
    RecordingStaticMeasurement: 'RECORDING_STATIC_MEASUREMENT',
    SwitchingOffThePump: 'SWITCHING_OFF_THE_PUMP'
} as const;
export type FindAllFunctionsByStageOperationEnum = typeof FindAllFunctionsByStageOperationEnum[keyof typeof FindAllFunctionsByStageOperationEnum];
/**
 * @export
 */
export const FindAllOperationsByStageStageEnum = {
    PumpStart: 'PUMP_START',
    OscillationAngles: 'OSCILLATION_ANGLES',
    Rotor: 'ROTOR',
    ReamingRotor: 'REAMING_ROTOR',
    PositioningOffTheBottom: 'POSITIONING_OFF_THE_BOTTOM',
    Slide: 'SLIDE',
    ReamingSlide: 'REAMING_SLIDE',
    RecordingStaticMeasurement: 'RECORDING_STATIC_MEASUREMENT',
    SwitchingOffThePump: 'SWITCHING_OFF_THE_PUMP'
} as const;
export type FindAllOperationsByStageStageEnum = typeof FindAllOperationsByStageStageEnum[keyof typeof FindAllOperationsByStageStageEnum];
