/* tslint:disable */
/* eslint-disable */
/**
 * ReportPro API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CurveMnemonicUnitResponse
 */
export interface CurveMnemonicUnitResponse {
    /**
     * 
     * @type {string}
     * @memberof CurveMnemonicUnitResponse
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof CurveMnemonicUnitResponse
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof CurveMnemonicUnitResponse
     */
    symbol?: string;
    /**
     * 
     * @type {number}
     * @memberof CurveMnemonicUnitResponse
     */
    coefficientA?: number;
    /**
     * 
     * @type {number}
     * @memberof CurveMnemonicUnitResponse
     */
    coefficientB?: number;
    /**
     * 
     * @type {number}
     * @memberof CurveMnemonicUnitResponse
     */
    coefficientC?: number;
    /**
     * 
     * @type {number}
     * @memberof CurveMnemonicUnitResponse
     */
    coefficientD?: number;
}

/**
 * Check if a given object implements the CurveMnemonicUnitResponse interface.
 */
export function instanceOfCurveMnemonicUnitResponse(value: object): value is CurveMnemonicUnitResponse {
    return true;
}

export function CurveMnemonicUnitResponseFromJSON(json: any): CurveMnemonicUnitResponse {
    return CurveMnemonicUnitResponseFromJSONTyped(json, false);
}

export function CurveMnemonicUnitResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CurveMnemonicUnitResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'name': json['name'] == null ? undefined : json['name'],
        'symbol': json['symbol'] == null ? undefined : json['symbol'],
        'coefficientA': json['coefficientA'] == null ? undefined : json['coefficientA'],
        'coefficientB': json['coefficientB'] == null ? undefined : json['coefficientB'],
        'coefficientC': json['coefficientC'] == null ? undefined : json['coefficientC'],
        'coefficientD': json['coefficientD'] == null ? undefined : json['coefficientD'],
    };
}

export function CurveMnemonicUnitResponseToJSON(value?: CurveMnemonicUnitResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'name': value['name'],
        'symbol': value['symbol'],
        'coefficientA': value['coefficientA'],
        'coefficientB': value['coefficientB'],
        'coefficientC': value['coefficientC'],
        'coefficientD': value['coefficientD'],
    };
}

