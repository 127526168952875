/* tslint:disable */
/* eslint-disable */
/**
 * ReportPro API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WitsmlWellboreMappingDto
 */
export interface WitsmlWellboreMappingDto {
    /**
     * 
     * @type {number}
     * @memberof WitsmlWellboreMappingDto
     */
    priority?: number;
    /**
     * 
     * @type {string}
     * @memberof WitsmlWellboreMappingDto
     */
    sourceWellboreUid?: string;
    /**
     * 
     * @type {string}
     * @memberof WitsmlWellboreMappingDto
     */
    sourceWellUid?: string;
    /**
     * 
     * @type {string}
     * @memberof WitsmlWellboreMappingDto
     */
    monitoringWellboreName?: string;
}

/**
 * Check if a given object implements the WitsmlWellboreMappingDto interface.
 */
export function instanceOfWitsmlWellboreMappingDto(value: object): value is WitsmlWellboreMappingDto {
    return true;
}

export function WitsmlWellboreMappingDtoFromJSON(json: any): WitsmlWellboreMappingDto {
    return WitsmlWellboreMappingDtoFromJSONTyped(json, false);
}

export function WitsmlWellboreMappingDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): WitsmlWellboreMappingDto {
    if (json == null) {
        return json;
    }
    return {
        
        'priority': json['priority'] == null ? undefined : json['priority'],
        'sourceWellboreUid': json['sourceWellboreUid'] == null ? undefined : json['sourceWellboreUid'],
        'sourceWellUid': json['sourceWellUid'] == null ? undefined : json['sourceWellUid'],
        'monitoringWellboreName': json['monitoringWellboreName'] == null ? undefined : json['monitoringWellboreName'],
    };
}

export function WitsmlWellboreMappingDtoToJSON(value?: WitsmlWellboreMappingDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'priority': value['priority'],
        'sourceWellboreUid': value['sourceWellboreUid'],
        'sourceWellUid': value['sourceWellUid'],
        'monitoringWellboreName': value['monitoringWellboreName'],
    };
}

