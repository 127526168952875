/* tslint:disable */
/* eslint-disable */
/**
 * ReportPro API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { TemplateFormatResponse } from './TemplateFormatResponse';
import {
    TemplateFormatResponseFromJSON,
    TemplateFormatResponseFromJSONTyped,
    TemplateFormatResponseToJSON,
} from './TemplateFormatResponse';

/**
 * 
 * @export
 * @interface TemplateScaleResponse
 */
export interface TemplateScaleResponse {
    /**
     * 
     * @type {string}
     * @memberof TemplateScaleResponse
     */
    id?: string;
    /**
     * 
     * @type {TemplateFormatResponse}
     * @memberof TemplateScaleResponse
     */
    templateFormat?: TemplateFormatResponse;
    /**
     * 
     * @type {string}
     * @memberof TemplateScaleResponse
     */
    type?: string;
    /**
     * 
     * @type {number}
     * @memberof TemplateScaleResponse
     */
    interval?: number;
    /**
     * 
     * @type {number}
     * @memberof TemplateScaleResponse
     */
    step?: number;
    /**
     * 
     * @type {number}
     * @memberof TemplateScaleResponse
     */
    partsNumber?: number;
}

/**
 * Check if a given object implements the TemplateScaleResponse interface.
 */
export function instanceOfTemplateScaleResponse(value: object): value is TemplateScaleResponse {
    return true;
}

export function TemplateScaleResponseFromJSON(json: any): TemplateScaleResponse {
    return TemplateScaleResponseFromJSONTyped(json, false);
}

export function TemplateScaleResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): TemplateScaleResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'templateFormat': json['templateFormat'] == null ? undefined : TemplateFormatResponseFromJSON(json['templateFormat']),
        'type': json['type'] == null ? undefined : json['type'],
        'interval': json['interval'] == null ? undefined : json['interval'],
        'step': json['step'] == null ? undefined : json['step'],
        'partsNumber': json['partsNumber'] == null ? undefined : json['partsNumber'],
    };
}

export function TemplateScaleResponseToJSON(value?: TemplateScaleResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'templateFormat': TemplateFormatResponseToJSON(value['templateFormat']),
        'type': value['type'],
        'interval': value['interval'],
        'step': value['step'],
        'partsNumber': value['partsNumber'],
    };
}

