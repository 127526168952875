/* tslint:disable */
/* eslint-disable */
/**
 * ReportPro API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Drilling
 */
export interface Drilling {
    /**
     * 
     * @type {number}
     * @memberof Drilling
     */
    rpm: number;
    /**
     * 
     * @type {number}
     * @memberof Drilling
     */
    load: number;
    /**
     * 
     * @type {number}
     * @memberof Drilling
     */
    valueT: number;
    /**
     * 
     * @type {number}
     * @memberof Drilling
     */
    msp: number;
}

/**
 * Check if a given object implements the Drilling interface.
 */
export function instanceOfDrilling(value: object): value is Drilling {
    if (!('rpm' in value) || value['rpm'] === undefined) return false;
    if (!('load' in value) || value['load'] === undefined) return false;
    if (!('valueT' in value) || value['valueT'] === undefined) return false;
    if (!('msp' in value) || value['msp'] === undefined) return false;
    return true;
}

export function DrillingFromJSON(json: any): Drilling {
    return DrillingFromJSONTyped(json, false);
}

export function DrillingFromJSONTyped(json: any, ignoreDiscriminator: boolean): Drilling {
    if (json == null) {
        return json;
    }
    return {
        
        'rpm': json['rpm'],
        'load': json['load'],
        'valueT': json['valueT'],
        'msp': json['msp'],
    };
}

export function DrillingToJSON(value?: Drilling | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'rpm': value['rpm'],
        'load': value['load'],
        'valueT': value['valueT'],
        'msp': value['msp'],
    };
}

