/* tslint:disable */
/* eslint-disable */
/**
 * ReportPro API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CreateTrackRequest } from './CreateTrackRequest';
import {
    CreateTrackRequestFromJSON,
    CreateTrackRequestFromJSONTyped,
    CreateTrackRequestToJSON,
} from './CreateTrackRequest';

/**
 * 
 * @export
 * @interface CreateTemplateRequest
 */
export interface CreateTemplateRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateTemplateRequest
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CreateTemplateRequest
     */
    description?: string;
    /**
     * 
     * @type {Array<CreateTrackRequest>}
     * @memberof CreateTemplateRequest
     */
    tracks: Array<CreateTrackRequest>;
}

/**
 * Check if a given object implements the CreateTemplateRequest interface.
 */
export function instanceOfCreateTemplateRequest(value: object): value is CreateTemplateRequest {
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('tracks' in value) || value['tracks'] === undefined) return false;
    return true;
}

export function CreateTemplateRequestFromJSON(json: any): CreateTemplateRequest {
    return CreateTemplateRequestFromJSONTyped(json, false);
}

export function CreateTemplateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateTemplateRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'],
        'description': json['description'] == null ? undefined : json['description'],
        'tracks': ((json['tracks'] as Array<any>).map(CreateTrackRequestFromJSON)),
    };
}

export function CreateTemplateRequestToJSON(value?: CreateTemplateRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'description': value['description'],
        'tracks': ((value['tracks'] as Array<any>).map(CreateTrackRequestToJSON)),
    };
}

